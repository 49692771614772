<template>

  <v-row wrap>
    <v-col sm="12">
      <v-card flat>
        <v-card-text class="d-block px-0">
          <v-data-table-server
            v-if="rentalSuppliersList"
            :headers="headers"
            :items="rentalSuppliersList"
            :items-length="rentalSuppliersListTotalCount"
            :loading="rentalSuppliersListLoading"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            @update:options="getRentalSuppliers"
            @update:sort-by="sortEvent"
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span class="cursor-pointer">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item }: {item : any}">
              <tr>
                <td>
                  <router-link class="custom-a" :to="{ name: 'RentalSuppliersEdit', params: { id: item.id }}">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  {{ item.location.location }}
                </td>
                <td>
                  {{ item.phone }}
                </td>
                <td style="width: 100px">
                  <v-btn variant="text" :to="{ name: 'RentalSuppliersEdit', params: { id: item.id }}" icon flat>
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  <!-- <v-btn variant="text" v-on:click="deleteRentalSupplier(item.id)" icon flat >
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.rentalSupplierFilterLabel {
  padding-top: 10px;
  width: 100px;
}
.rentalSupplierFilterLabel.twoRows {
  padding-top: 0;
}
.pageButtons {
  padding: 5px 10px 0 0;
}
.printOnlyPossibleForGroupedView {
  color: #999;
  font-size: 12px;
  padding: 3px 5px;
}
</style>

<script setup lang="ts">
import { datatableOptions } from '@/mixins/datatableOptions'
import { ref, computed, onBeforeMount } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'
import { capitalcase } from '@/utils/filters'
import emitArray from "@/utils/emit";
import { computedAsync } from '@vueuse/core'
import Say from '@/utils/Say'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const emit = defineEmits(emitArray.value)

const {t} = useI18n()

const headers: any = ref([
  { title: t('name', 1), key: 'name', sortable: true },
  { title: t('location', 1), key: 'location', sortable: true },
  { title: t('phone', 1), key: 'phone', sortable: true },
  { title: '', key: '', sortable: false },
])
const rentalSuppliersList = computed(() => store.getters.rentalSuppliersList)
const rentalSuppliersListLoading = computed(() => store.getters.rentalSupplierListLoading)
const rentalSuppliersListTotalCount = computed(() => store.getters.rentalSuppliersListTotalCount)

const { rowsPerPageItems, rowsPerPageText, pagination } = datatableOptions(false)

onBeforeMount(() => {
  store.commit('app.setPageTitle', t('rentalSupplier', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('rentalSupplier', 2) }
  ])
})

const getRentalSuppliers =  computedAsync(async () => {
  return await store.dispatch('rentalSuppliers.fetch', Object.assign(
    pagination.value
  ))
})
const deleteRentalSupplier = (id: any) => {
  let confirmAction = confirm(t('rentalSupplierDeleteConfirm'))
  if (confirmAction) {
    store.dispatch('rentalSuppliers.delete', id).then(() => {
      Say('success', t('rentalSupplierDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}

const sortEvent = (value: any) => {
  if(value.length === 0) {
    return
  }
  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false
}
</script>

<template>
  <div>
    <equipmentItem-form
      :equipmentItem="equipmentItem"
      :submit="submit"
      :title="t('equipmentItemCreate')"
      :buttonText="t('create')"
      :onInput="() => {}"
      :loading="loading"
    />
  </div>
</template>

<script setup lang="ts">
import EquipmentItemForm from './Form.vue'
import Say from '@/utils/Say'
import { defineComponent, computed, ref, onBeforeMount } from 'vue';
import store from '@/store';
import { equipmentItemStore } from '@/store/equipmentItems'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import sayErrorResponse from '@/mixins/sayErrorResponse';

import useBranchSelector from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

defineComponent({
  EquipmentItemForm
})

const loading= ref(false)
const router = useRouter()
const { t } = useI18n()

const equipmentItem = computed(() => {
  let item = equipmentItemStore.getters.equipmentItem
  item.quantity = 1
  return item
})

onBeforeMount(() => {
  equipmentItemStore.commit('clearEquipmentItem')

  store.commit('app.setPageTitle', t('equipmentItemCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('inventory', 2), routeName: 'EquipmentItemsList' },
    { text: t('equipmentItemCreate') }
  ])
})

const submit = async () => {
  if (loading.value) {
    return
  }
  loading.value = true
  equipmentItem.value.branch_id = branchSelected.value

  let data = {
    ...equipmentItem.value
  }

  if(data.toLocationType === 'vaLocation') {
    data.location_id = data.va_location_id
  } else if(data.toLocationType === 'rentalSuppliersLocation') {
    data.location_id = data.rental_suppliers_location_id
  }
  delete data.va_location_id
  delete data.rental_suppliers_location_id
  delete data.toLocationType

  try {
    await equipmentItemStore.dispatch('createEquipmentItem', data)
    Say('success', t('equipmentItemCreateSuccess'))
    router.push({ name: 'EquipmentItemsList' })
  } catch (error) {
    sayErrorResponse(error)
  } finally {
    loading.value = false
  }
}

</script>

<template>
    <v-dialog
      v-model="isVisible"
      :content-class="contentClass"
      :max-width="maxWidth"
      :scrollable="scrollable"
      class="open elevation-6"
      min-height="90%"
      min-width="90%"
    >
      <template v-slot:default>
        <v-card>
          <v-card-title class="pa-0">
            <v-text-field
                v-model="items"
                placeholder="Zoeken"
                prepend-icon="mdi-magnify"
                :clearable="true"
                variant="plain"
                ref="searchInput"
                class="bg-blue-darken-1 px-2 pb-2"
                hide-details
                @keyup.enter="enterItems()"
                >
                  <template v-slot:prepend-inner>
                    <div v-for="(chip , index) in search" :key="chip">
                      <v-chip
                        closable
                        @click:close="removeItems(index)"
                      >
                        {{chip}}
                      </v-chip>
                    </div>
                  </template>

                  <template v-slot:label>
                    <div v-if="items" class="py-2">
                      druk op enter om een zoekterm toe te voegen
                    </div>
                  </template>
            </v-text-field>
            <v-row class="justify-space-between align-center">
                <v-col>
                    <v-tabs
                        v-model="activeTab"
                        density="compact"
                        height="30"
                        selected-class="text-blue-darken-1"
                    >
                        <!-- Tab headers -->
                        <v-tab class="px-2 custom-tab" :value="`jobResult`">{{ $t('jobResult', 2) }}</v-tab>
                        <v-tab class="px-2 custom-tab" :value="`all`">{{ $t('all') }}</v-tab>
                        <v-tab class="px-2 custom-tab" :value="`client`">{{ $t('client', 2) }}</v-tab>
                        <v-tab class="px-2 custom-tab" :value="`job`">{{ $t('job', 2) }}</v-tab>
                        <v-tab class="px-2 custom-tab" :value="`workOrder`">{{ $t('workOrder', 2) }}</v-tab>
                        <v-tab class="px-2 custom-tab" :value="`location`">{{ $t('location', 2) }}</v-tab>
                    </v-tabs>
                </v-col>
                <v-col class="text-right pr-8">
                    <div v-if="Object.keys(searchResults).length > 0">
                        Showing {{ totalResults }} results
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-title>
          <v-card-text class="pa-0" :class="loading ? 'd-flex justify-center align-center' : ''">
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-window v-model="activeTab" class="py-4" v-else>
                <v-window-item :value="`all`">
                    <v-row v-for="key in Object.keys(searchResults)" v-if=" Object.keys(searchResults).length > 0" class="flex-column result_container pointer">
                        <h4
                          class="capitalize px-2"
                          @click="activeTab = searchResults[key].length > 0 ? key : 'all'"
                        >
                          {{ $t(key, 2) }} <v-icon size="small">mdi-chevron-right</v-icon>
                        </h4>

                        <div
                          v-for="(item, i) in searchResults[key].slice(0, 3)"
                          class="py-1 px-6 item_container custom_bg"
                          @click="show(key, item, $event)"
                          v-if="searchResults[key].length > 0"
                        >
                          <span class="pointer" v-if="key === 'jobResult'">
                            <a :href="`${routePathList[key]}/${item.id}`" class="custom-a">
                              <span v-if="item.client_name" class="custom-span" :style="fieldWidth('client_name')">{{item.client_name}}</span>
                              <span v-if="item.job_name" class="custom-span" :style="fieldWidth('job_name')"><span class="mr-2">|</span> {{item.job_name}}</span>
                              <span v-if="item.datetime_start" class="custom-span" :style="fieldWidth('datetime_start')"><span class="mr-2">|</span> {{item.datetime_start}}</span>
                              <span v-if="item.location && item.location.location" class="custom-span" :style="fieldWidth('location')"><span class="mr-2">|</span> {{item.location.location}}</span>
                              <span v-if="item.category" class="custom-span" :style="fieldWidth('category')"><span class="mr-2">|</span> {{item.category}}</span>
                            </a>
                          </span>
                          <span class="pointer" v-if="key === 'client'">
                            <a :href="`${routePathList[key]}?clients_name=${item.name}`" class="custom-a">{{ item.name }}</a>
                          </span>
                          <span class="pointer" v-else-if="key === 'job'">
                            <a :href="`${routePathList[key]}/${item.id}`" class="custom-a">
                              <span class="custom-span" :style="fieldWidth('job_name')">{{ item.job_name }}</span>
                              <span class="custom-span" :style="fieldWidth('project_name')" v-if="item.project_name"> <span class="mr-2" v-if="item.project_name">|</span>{{ item.project_name }}</span>
                              <span class="custom-span" :style="fieldWidth('client_name')" v-if="item.client_name"> <span class="mr-2" v-if="item.client_name">|</span>{{ item.client_name }}</span>
                            </a>
                          </span>
                          <span class="pointer" v-else-if="key === 'workOrder'">
                            <a :href="`${routePathList[key]}/${item.id}`" class="custom-a">
                              <span v-if="item.standard_name_prefix" class="custom-span" :style="fieldWidth('standard_name_prefix')">{{item.standard_name_prefix}}</span>
                              <span v-if="item.datetime_start" class="custom-span" :style="fieldWidth('datetime_start')"><span class="mr-2">|</span> {{item.datetime_start}}</span>
                              <span v-if="item.location && item.location.location" class="custom-span" :style="fieldWidth('location')"><span class="mr-2">|</span> {{item.location.location}}</span>
                              <span v-if="item.category" class="custom-span" :style="fieldWidth('category')"><span class="mr-2">|</span> {{item.category}}</span>
                              <span v-if="item.instructions" class="custom-span" :style="fieldWidth('instructions')"><span class="mr-2">|</span> {{item.instructions}}</span>
                              <span v-if="item.client" class="custom-span" :style="fieldWidth('client_name')"><span class="mr-2">|</span> {{item.client.name}}</span>
                            </a>
                          </span>
                          <span class="pointer" v-else-if="key === 'location'">
                            <a :href="`${routePathList[key]}?location=${item.street}`" class="custom-a">
                              {{ item.city }}, {{ item.postcode ? `${item.postcode}, `: '' }} {{ item.street  }}
                            </a>
                          </span>
                        </div>

                        <div class="py-1 pl-4 item_container"  v-else>
                            {{ $t('noItems') }}
                        </div>
                    </v-row>

                    <div class="py-1 pl-4 item_container"  v-else>
                        {{ $t('noItems') }}
                    </div>
                </v-window-item>
                <v-window-item class="px-2" :value="`jobResult`">
                    <v-row  class="flex-column result_container pointer px-0">
                        <v-list
                            v-if="searchResults.jobResult && searchResults.jobResult.length > 0"
                            open-strategy="single"
                            nav
                            v-for="item in searchResults.jobResult"
                            class="py-0 px-0 custom_bg"
                        >
                            <v-list-group
                                :value="item.job_name"
                            >
                                <template v-slot:activator="{isOpen, props}">
                                    <v-list-item v-bind="props" class="font-weight-bold mb-0 py-0 px-4 b-bottom" :class="isOpen ? 'custom-bg-row' : ''" style="min-height: 35px !important;">
                                        <span class="d-inline-block mr-2 w-200">
                                            {{ item.client_name ? item.client_name : '' }}
                                        </span>

                                        <span class="d-inline-block mr-2 w-150">
                                            {{ item.job_name ? item.job_name : '' }}
                                        </span>

                                        <span class="d-inline-block mr-2 w-150">
                                            {{ item.datetime_start ? item.datetime_start : '' }}
                                        </span>

                                        <span class="d-inline-block mr-2 w-200">
                                            {{ item.category ? item.category : '' }}
                                        </span>
                                    </v-list-item>
                                </template>

                                <v-list-item
                                    v-for="(workOrder, i) in item.workorders"
                                    :key="i"
                                    density="compact"
                                    class="px-0 custom-bg-subrow hover mb-0 py-0 b-bottom"
                                    style="min-height: 35px !important;"
                                >
                                    <a class="custom-a" :href="`/work-orders/view/${workOrder.id}`">
                                        <span class="d-inline-block mr-2 w-200">
                                            {{ workOrder.standard_name_prefix ? workOrder.standard_name_prefix : '' }}
                                        </span>
                                    </a>
                                    <a class="custom-a" :href="`/work-orders/view/${workOrder.id}`">
                                        <span  class="d-inline-block mr-2 w-150">
                                            {{ workOrder.datetime_start ? workOrder.datetime_start : '' }}
                                        </span>
                                    </a>
                                    <a class="custom-a" :href="`/work-orders/view/${workOrder.id}`">
                                        <span  class="d-inline-block mr-2 w-250">
                                            {{ workOrder.category ? workOrder.category : '' }}
                                        </span>
                                    </a>
                                    <a class="custom-a" :href="`/work-orders/view/${workOrder.id}`">
                                        <span class="d-inline-block mr-2 w-400">
                                            {{ workOrder.instructions != 'undefined' ? workOrder.instructions : '' }}
                                        </span>
                                    </a>
                                    <a class="custom-a" :href="`/work-orders/view/${workOrder.id}`">
                                        <span class="d-inline-block mr-2 w-250">
                                            {{ workOrder.location && workOrder.location.location ? workOrder.location.location : '' }}
                                        </span>
                                    </a>
                                    <a class="custom-a" :href="`/work-orders/view/${workOrder.id}`">
                                        <span v-if="workOrder.client_responsible_1" class="d-inline-block mr-2 w-200">
                                            {{ workOrder.client_responsible_1 ?? workOrder.client_responsible_1.name }}
                                        </span>
                                    </a>
                                </v-list-item>
                            </v-list-group>
                        </v-list>

                        <div class="py-1 pl-4 item_container" v-else>
                            {{ $t('noItems') }}
                        </div>
                    </v-row>
                </v-window-item>
                <v-window-item :value="`client`">
                  <v-row  class="flex-column result_container pointer">
                    <div
                      v-if="searchResults.client && searchResults.client.length > 0"
                      v-for="item in searchResults.client"
                      class="py-1 px-4 item_container custom_bg"
                      @click="show('client', item, $event)"
                    >
                      <span class="pointer">
                        <a :href="`${routePathList['client']}?clients_name=${item.name}`" class="custom-a">{{ item.name }}</a>
                      </span>
                    </div>

                    <div class="py-1 pl-4 item_container"  v-else>
                      {{ $t('noItems') }}
                    </div>
                  </v-row>
                </v-window-item>
                <v-window-item class="px-2" :value="`job`">
                  <v-row class="flex-column result_container pointer px-0">
                    <div
                      v-if="searchResults && searchResults.job && searchResults.job.length > 0"
                      v-for="item in searchResults.job"
                      class="py-1 px-4 item_container custom_bg"
                      @click="show('job', item, $event)"
                    >
                      <span class="pointer">
                        <a :href="`${routePathList['job']}/${item.id}`" class="custom-a">
                          <span class="d-inline-block mr-2 w-150">
                            {{ item.job_name ? item.job_name : '' }}
                          </span>

                          <span class="d-inline-block mr-2 w-250">
                            {{ item.project_name ? item.project_name : '' }}
                          </span>

                          <span class="d-inline-block mr-2 w-150">
                            {{ item.datetime_start ? item.datetime_start : '' }}
                          </span>

                          <span class="d-inline-block mr-2 w-200">
                            {{ item.category ? item.category : ''  }}
                          </span>

                          <span v-if="item.client_name" class="d-inline-block mr-2 w-200">
                            {{ item.client_name  }}
                          </span>
                        </a>
                      </span>
                    </div>

                    <div class="py-1 pl-4 item_container"  v-else>
                      {{ $t('noItems') }}
                    </div>
                  </v-row>
                </v-window-item>
                <v-window-item class="px-2" :value="`workOrder`">
                    <v-row class="flex-column result_container pointer px-0">
                        <div
                          v-if="searchResults && searchResults.workOrder && searchResults.workOrder.length > 0"
                          v-for="item in searchResults.workOrder"
                          class="py-1 px-4 item_container custom_bg"
                          @click="show('workOrder', item, $event)"
                        >
                          <span class="pointer">
                            <a :href="`${routePathList['workOrder']}/${item.id}`" class="custom-a">
                              <span class="d-inline-block mr-2 w-150">
                                {{ item.standard_name_prefix ? item.standard_name_prefix : '' }}
                              </span>
                              <span  class="d-inline-block mr-2 w-150">
                                {{ item.datetime_start ? item.datetime_start : '' }}
                              </span>
                              <span  class="d-inline-block mr-2 w-250">
                                {{ item.location ? item.location.location : '' }}
                              </span>
                              <span  class="d-inline-block mr-2 w-200">
                                {{ item.category ? item.category : '' }}
                              </span>
                              <span v-if="item.instructions != 'undefined'" class="d-inline-block mr-2 w-400">
                                {{ item.instructions != 'undefined' ? item.instructions : '' }}
                              </span>
                              <span v-if="item.client_name" class="d-inline-block mr-2 w-100">
                                {{ item.client_name  }}
                              </span>
                            </a>
                          </span>
                        </div>

                        <div class="py-1 pl-4 item_container"  v-else>
                            {{ $t('noItems') }}
                        </div>
                    </v-row>
                </v-window-item>
                <v-window-item :value="`location`">
                    <v-row  class="flex-column result_container pointer">
                        <div
                            v-if="searchResults && searchResults.location && searchResults.location.length > 0"
                            v-for="item in searchResults.location"
                            class="py-1 px-4 item_container custom_bg"
                            @click="show('location', item, $event)"
                        >
                            <span class="pointer">
                                <a :href="`${routePathList['location']}?location=${item.street}`" class="custom-a">
                                    <span v-if="item.city" class="d-inline-block mr-2 w-150">
                                        {{ item.city }}
                                    </span>
                                    <span class="d-inline-block mr-2 w-100">
                                        {{ item.postcode }}
                                    </span>
                                    <span v-if="item.street" class="d-inline-block mr-2 w-150">
                                        {{ item.street }}
                                    </span>
                                </a>
                            </span>
                        </div>

                        <div class="py-1 pl-4 item_container"  v-else>
                            {{ $t('noItems') }}
                        </div>
                    </v-row>
                </v-window-item>
            </v-window>
        </v-card-text>
        <div class="text-center pb-3 pointer text-blue-darken-1" v-if="showToAllResults()" @click="showAll(activeTab)">
            {{ $t('allResults')  }}
            <v-icon size="small">mdi-chevron-right</v-icon>
        </div>
        </v-card>
      </template>

      <template v-slot:activator="{ props }">
        <slot v-bind="props" name="activator"></slot>
      </template>
    </v-dialog>
</template>

<script setup lang="ts">
import store from '@/store';
import emitArray from '@/utils/emit';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { computed, nextTick, ref, inject, watch } from 'vue';
import { ellipsis } from '@/utils/filters';
import useBranchSelector from '@/composable/useBranchSelector';

const { branchSelected } = useBranchSelector()
const emit = defineEmits(emitArray.value)

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    contentClass: {
        type: String,
        required: false,
        default: '',
    },
    scrollable: {
        type: Boolean,
        required: false,
        default: false,
    },
    maxWidth: {
        type: String,
        required: false,
        default: '80%',
    },
})

const router = useRouter()
const route = useRoute()
const search = ref([] as any[])
const searchResults: any = inject('searchResults')
const isFromSidebar: any = inject('isFromSidebar')
const searchInput = ref(null as any)
const activeTab = ref(searchResults.value.jobResult && searchResults.value.jobResult.length == 0 ? 'all' : 'jobResult')
const totalResults = ref(0)
const loading = ref(false)
const items = ref('')

const quickSearch = debounce(function() {
  loading.value = true
  const url = `/search?keyword=${search.value}&quick=true&branchId=${branchSelected.value}`
  if(search.value.length > 0) {
    axios.get(url)
      .then((response) => {
        searchResults.value = response.data
        calculateTotalResults()
        loading.value = false
      })
      .catch((error) => {
        console.error(error)
      })
  } else {
    searchResults.value = {}
    totalResults.value = 0
    loading.value = false
  }
}, 800)

watch(route, async(value: any) => {
  if(value.params?.id) {
    if(value.name === 'ClientsEdit') {
      await store.dispatch('clients.fetchSingleForEdit', route.params.id)
    } else if(value.name === 'WorkOrdersView') {
      await store.dispatch('workOrders.fetchSingleForEdit', route.params.id)
    }
  }
})

watch(() => isFromSidebar, () => {
  if(isFromSidebar.value) {
    search.value = []
    isFromSidebar.value = false
  }
}, {immediate: true, deep: true})

watch(search, () => {
  if(search.value.length === 0) {
    searchResults.value = {}
    totalResults.value = 0
    loading.value = false
  } else {
    loading.value = true
  }
}, {deep: true})

watch(activeTab, (value: string) => {
  if(Object.keys(searchResults.value).length === 0) {
    return
  } else if(value){
    calculateTotalResults()
  }

}, { deep: true })

watch(searchInput, () => {
  if(props.visible && searchInput.value) {
    searchInput.value.focus()
  }
}, {immediate: true, deep: true})

const routeNameList = ref<{[key: string]: string}>({
  "client": "client",
  "job": "JobsVIew",
  "jobResult": "JobsVIew",
  "workOrder": "WorkOrdersView",
  "location": "location",
})

const routePathList = ref<{[key: string]: string}>({
  "client": "/jobs/list",
  "job": "/jobs/view",
  "jobResult": "/jobs/view",
  "workOrder": "/work-orders/view",
  "location": "/work-orders/list",
})

const fieldWidth = (field: string) => {
  switch (field) {
    case 'client_name':
      return {width: '200px'}
    case 'job_name':
      return {width: '100px'}
    case 'job_description':
      return {width: '150px'}
    case 'datetime_start':
      return {width: '150px'}
    case 'category':
      return {width: '230px'}
    case 'location':
      return {width: '230px'}
    case 'standard_name_prefix':
      return {width: '180px'}
    case 'project_name':
      return {width: '180px'}
    case 'instructions':
      return {width: '500px'}
  }
}

const show = (routeName: string, item: Record<string, any>, event: any) => {
  if(event.ctrlKey && event.type === 'click') {
    return
  }

  if(routeNameList.value[routeName] === 'location') {
    router.push({name: 'WorkOrdersList', query: {location: item.street}})
  } else if(routeNameList.value[routeName] === 'client') {
    router.push({name: 'JobsList', query: {clients_name: item.name}})
  } else {
    router.push({name: routeNameList.value[routeName], params: {id: Number(item.id)}})
  }

  closeDialog()
}

const showAll = (typeName: string) => {
  router.push({name: 'searchResults', query: {searchFor: typeName, keyword: search.value.join(',')}})
  closeDialog()
}


const isVisible = computed({
  get() {
    return props.visible
  },
  set(value: any){
    if (!value) {
      closeDialog()
    }
  }
})

const closeDialog = () => {
  nextTick(() => {
    activeTab.value = 'all'
    emit('update:visible', false)
  })
}

const calculateTotalResults = () => {
  let total = 0
  const results = searchResults.value

  switch (activeTab.value) {
    case 'all':
      Object.keys(results).forEach((key) => {
        total += results[key].slice(0, 3).length
      })
      break;
    case 'client':
      total += results.client.length
      break;
    case 'job':
      total += results.job.length
      break;
    case 'jobResult':
      if(results.jobResult.length > 0) {
        results.jobResult.forEach((item: Record<string, any>) => {
          total += item.workorders.length
        })
      }
      break;
    case 'workOrder':
      total += results.workOrder.length
      break;
    case 'location':
      total += results.location.length
      break;
  }

  totalResults.value = total
}

const enterItems = () => {
  if(items.value.length === 0) {
    return
  }
  search.value.push(items.value)
  items.value = ''
  quickSearch()
}

const removeItems = (index: number) => {
  search.value.splice(index, 1)
  quickSearch()
}

const fromattedResultJob = (item: Record<string, any>) => {
  const client = item.client_name ? `${ellipsis(item.client_name, 20)} |` : ''
  const job = item.job_name ? `${ellipsis(item.job_name)} |` : ''
  const start = item.datetime_start ? `${ellipsis(item.datetime_start, 20)} |` : ''
  const location = item.location && item.location.location ? `${ellipsis(item.location.location, 50)} |` : ''
  const category = item.category ? `${ellipsis(item.category, 150)}` : ''

  return `${client} ${job} ${start} ${location} ${category}`
}

const fromattedResultWorkorder = (workorder: Record<string, any>) => {
  const prefix = workorder.standard_name_prefix ? `${ellipsis(workorder.standard_name_prefix, 13)} |` : ''
  const start = workorder.datetime_start ? `${ellipsis(workorder.datetime_start, 11)} |` : ''
  const location = workorder.location && workorder.location.location != undefined ? `${ellipsis(workorder.location.location, 15)} |` : ''
  const category = workorder.category ? `${ellipsis(workorder.category, 12)} |` : ''
  const instructions = workorder.instructions && workorder.instructions != 'undefined' ? `${ellipsis(workorder.instructions)}` : ''
  const responsible = workorder.client_responsible_1 ? ` | ${ellipsis(workorder.client_responsible_1)}` : ''
  const responsible1 = workorder.client_responsible_1 ? ` | ${ellipsis(workorder.client_responsible_1.name)}` : ''

  return `${prefix} ${start} ${location} ${category} ${instructions} ${responsible ?? responsible1}`
}

function showToAllResults(): boolean {
  return activeTab.value !== 'all' && activeTab.value !== 'jobResult' && Object.keys(searchResults.value).length > 0 && searchResults.value[activeTab.value] && searchResults.value[activeTab.value].length > 0
}
</script>

<style scoped lang="scss">
.card__title {
    padding: 0;
}

.custom_bg:nth-child(odd) {
    background: #EAEAEA;
}

.w- {

    &100 {
        width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }



    &150 {
        width: 150px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &200 {
        width: 200px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &250 {
        width: 250px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &400 {
        width: 400px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.result_container {
    padding: 10px;

    .item_container {
        &:hover {
            background-color: var(--custom-bg-row);
            border-radius: 5px;
        }
    }
    .capitalize {
        text-transform: capitalize;
    }

}

.pointer {
    cursor: pointer;
}

.custom-tab {
    font-size: 9px !important;
    min-width: 50px !important;
}

.b-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.custom-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
</style>

<template>
  <div>
    <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
    <client-form
      :client="client"
      :submit="submit"
      :title="t('clientEdit') + ' ' + client.name"
      :buttonText="t('edit')"
      :onInput="onInput"
      :loading="loading"
      v-if="!preLoading"
    />
  </div>
</template>

<script setup lang="ts">
import ClientForm from './Form.vue'
import Say from '@/utils/Say'
import { defineComponent, ref, computed, onBeforeUnmount, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import { useRoute } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { t } = useI18n()
const route = useRoute()

defineComponent({
  ClientForm
})

const preLoading= ref(true)
const loading= ref(false)
const projects= ref([])

const client = computed(() => {
  // UI
  store.commit('app.setPageTitle', 'Edit ' + store.getters.clientsEdit.name)
  store.commit('app.setBreadcrumbs', [
    { text: t('client', 2), routeName: 'Clients' },
    { text: t('clientEdit') + ' ' + store.getters.clientsEdit.name }
  ])
  return store.getters.clientsEdit
})
onBeforeMount(() => {
  store.dispatch('clients.fetchSingleForEdit', route.params.id)
    .then(() => {
      preLoading.value = false
    })
})
onBeforeUnmount(() => {
  store.commit('clients.editClear')
})
const submit = () => {
  loading.value = true
  store.dispatch('clients.edit').then(() => {
    loading.value = false
    store.commit('cache.clients.setDirty')
    Say('success', t('clientEditSuccess'))
  }).catch((err: any) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
const onInput = (name: any, value: any) => {
  store.commit('clients.editUpdateInput', { name: name, value: value })
}
</script>

// import { fetchClients, fetchClient, createClient, updateClient, deleteClient } from '@/api/clients'
import { fetchClients } from '@/api/clients'
import { fetchEmployees } from '@/api/employees'
import { fetchVanAmerongenLocations, fetchRentalSuppliersLocations } from '@/api/locations'
import { Commit } from 'vuex'
import { CacheState } from '../state'

export default {
  state: {
    clients: {
      items: [],
      isDirty: true,
      isFetching: false
    },
    employees: {
      items: [],
      isDirty: true,
      isFetching: false
    },
    vanAmerongenLocations: {
      items: [],
      isDirty: true,
      isFetching: false
    },
    rentalSuppliersLocations: {
      items: [],
      isDirty: true,
      isFetching: false
    }
  },

  getters: {
    'cache.employees': (state: CacheState) => {
      return state.employees.items
    },
    'cache.clients': (state: CacheState) => {
      return state.clients.items
    },
    'cache.vanAmerongenLocations': (state: CacheState) => {
      return state.vanAmerongenLocations.items
    },
    'cache.rentalSuppliersLocations': (state: CacheState) => {
      return state.rentalSuppliersLocations.items
    }
  },

  mutations: {
    'cache.employees.setDirty': (state: CacheState) => { state.employees.isDirty = true },
    'cache.employees.setUnDirty': (state: CacheState) => { state.employees.isDirty = false },
    'cache.employees.set': (state: CacheState, employees: any) => {
      state.employees.items = employees
    },
    'cache.clients.setDirty': (state: CacheState) => { state.clients.isDirty = true },
    'cache.clients.setUnDirty': (state: CacheState) => { state.clients.isDirty = false },
    'cache.clients.set': (state: CacheState, clients: any) => {
      state.clients.items = clients.sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    'cache.clients.add': (state: CacheState, client: any) => {
      state.clients.items.push(client)
      state.clients.items = state.clients.items.sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    'cache.clients.update': (state: CacheState, client: any) => {
      const existingClient = state.clients.items.find((existingClient: any) => existingClient.id === client.id)
      if(existingClient) {
        Object.assign(existingClient, client)
      }
    },
    'cache.vanAmerongenLocations.setDirty': (state: CacheState) => { state.vanAmerongenLocations.isDirty = true },
    'cache.vanAmerongenLocations.setUnDirty': (state: CacheState) => { state.vanAmerongenLocations.isDirty = false },
    'cache.vanAmerongenLocations.set': (state: CacheState, vanAmerongenLocations: any) => {
      state.vanAmerongenLocations.items = vanAmerongenLocations
    },
    'cache.rentalSuppliersLocations.setDirty': (state: CacheState) => { state.rentalSuppliersLocations.isDirty = true },
    'cache.rentalSuppliersLocations.setUnDirty': (state: CacheState) => { state.rentalSuppliersLocations.isDirty = false },
    'cache.rentalSuppliersLocations.set': (state: CacheState, rentalSuppliersLocations: any) => {
      state.rentalSuppliersLocations.items = rentalSuppliersLocations
    }
  },

  actions: {
    'cache.employees.fetch': async ({ state, commit }: {state: CacheState, commit: Commit}) => {
      if (!state.employees.isDirty) return
      if (state.employees.isFetching) return
      state.employees.isFetching = true
      const response = await fetchEmployees()
      commit('cache.employees.set', response.data.data)
      commit('cache.employees.setUnDirty')
      state.employees.isFetching = false
    },
    'cache.clients.fetch': async ({ state, commit }: {state: CacheState, commit: Commit}) => {
      if (!state.clients.isDirty) return
      if (state.clients.isFetching) return
      state.clients.isFetching = true
      const response = await fetchClients()
      commit('cache.clients.set', response.data.data)
      commit('cache.clients.setUnDirty')
      state.clients.isFetching = false
    },
    'cache.vanAmerongenLocations.fetch': async ({ state, commit }: {state: CacheState, commit: Commit}) => {
      if (!state.vanAmerongenLocations.isDirty) return
      if (state.vanAmerongenLocations.isFetching) return
      state.vanAmerongenLocations.isFetching = true
      const response = await fetchVanAmerongenLocations()
      commit('cache.vanAmerongenLocations.set', response.data)
      commit('cache.vanAmerongenLocations.setUnDirty')
      state.vanAmerongenLocations.isFetching = false
    },
    'cache.rentalSuppliersLocations.fetch': async ({ state, commit }: {state: CacheState, commit: Commit}) => {
      if (!state.rentalSuppliersLocations.isDirty) return
      if (state.rentalSuppliersLocations.isFetching) return
      state.rentalSuppliersLocations.isFetching = true
      const response = await fetchRentalSuppliersLocations()
      commit('cache.rentalSuppliersLocations.set', response.data)
      commit('cache.rentalSuppliersLocations.setUnDirty')
      state.rentalSuppliersLocations.isFetching = false
    }
  }
}

<template>
  <div>
    <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
    <EmployeesForm
      :employee="employee"
      :submit="submit"
      :title="t('employeeEdit') + employee.name"
      :onInput="onInput"
      :loading="loading"
      :buttonText="t('save')"
      v-on:update:loading="loading = $event"
      v-if="!preLoading"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, onBeforeUnmount, defineComponent } from 'vue';
import { default as EmployeesForm } from './Form.vue'
import Say from '@/utils/Say'
import { useI18n } from 'vue-i18n';
import store from '@/store';
import { useRoute } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'


defineComponent({
  EmployeesForm
})
const preLoading = ref(true)
const loading = ref(false)
const { t } = useI18n()
const route = useRoute()

const employee = computed(() => {
  return store.getters.employeeEdit
})
onBeforeMount(() => {
  store.dispatch('employees.fetchSingleForEdit', route.params.id).then(function () {
    preLoading.value = false
  }.bind(this))

  // UI
  store.commit('app.setPageTitle', t('employeeEdit') + ' ' + store.getters.employeeEdit.name)
  store.commit('app.setBreadcrumbs', [
    { text: t('employee', 2), routeName: 'Employees' },
    { text: t('employeeEdit') + ' ' + store.getters.employeeEdit.name }
  ])
})
onBeforeUnmount(() => {
  store.commit('employees.editClear')
})

const submit = () => {
  loading.value = true
  store.dispatch('employees.edit').then(()  => {
    loading.value = false
    store.commit('cache.employees.setDirty')
    Say('success', t('employeeEditSuccess'))
  }).catch((err) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
const onInput = (name: string, value: any) => {
  store.commit('employees.editUpdateInput', { name: name, value: value })
}
</script>

export const VATheme = {
    light: {
        background: '#f5f5f5',
    },
    colors: {
        primary: '#1976d2'
    },
    typography: {
        fontSize: 12,
    },
}

export const defaults = {
    VDataTableServer: {
        density: 'compact',
        VBtn: {
            size: 'small',
            VIcon: {
                size: 'large'
            }
        },
        VCheckbox: {
            density: 'compact'
        }
    },
    VTable: {
        density: 'compact',
        VBtn: {
            size: 'small',
            VIcon: {
                size: 'large'
            }
        },
        VCheckbox: {
            density: 'compact'
        }
    },
    VList: {
        density: 'compact'
    }
}

export default {
    VATheme,
    defaults
}
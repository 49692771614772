import { getEquipmentItemLocations, getEquipmentItemLocation, createEquipmentItemLocation, updateEquipmentItemLocation, updateEquipmentItemLocationAmount, deleteEquipmentItemLocation } from "@/api/equipmentItemLocation";
import { fetchEquipment } from "@/api/equipment";
import { getEquipmentItems } from "@/api/equipmentItem";
import { createStore } from "vuex";
import { EquipmentItemLocationState } from "../state";
import { EquipmentItemLocation } from "../types";

export const equipmentItemLocationStore = createStore({
  state: {
    equipmentItemLocations: [],
    equipmentItemLocation: {} as any,
    equipmentItemLocationListTotal: 0,
    equipmentItemLocationListLoading: false,
    equipment: [],
    equipmentItems: [],
  },
  getters: {
    equipmentItemLocations: (state: EquipmentItemLocationState) => state.equipmentItemLocations,
    equipmentItemLocation: (state: EquipmentItemLocationState) => state.equipmentItemLocation,
    equipmentItemLocationListTotal: (state: EquipmentItemLocationState) => state.equipmentItemLocationListTotal,
    equipmentItemLocationListLoading: (state: EquipmentItemLocationState) => state.equipmentItemLocationListLoading,
    equipment: (state: EquipmentItemLocationState) => state.equipment,
    equipmentItems: (state: EquipmentItemLocationState) => state.equipmentItems,
  },
  mutations: {
    setEquipmentItemLocations(state: EquipmentItemLocationState, payload: EquipmentItemLocation[]) {
      state.equipmentItemLocations = payload
    },
    setEquipmentItemLocation(state: EquipmentItemLocationState, payload: EquipmentItemLocation) {
      state.equipmentItemLocation = payload
    },
    clearEquipmentItemLocation(state: EquipmentItemLocationState) {
      state.equipmentItemLocation = {} as any
    },
    deleteEquipmentItemLocationById(state: EquipmentItemLocationState, payload: any) {
      state.equipmentItemLocations = state.equipmentItemLocations.filter((EquipmentItemLocation: any) => {
        return String(EquipmentItemLocation.id) !== String(payload.id)
      })
      state.equipmentItemLocationListTotal = state.equipmentItemLocationListTotal - 1
    },
    setEquipmentItemLocationListTotal(state: EquipmentItemLocationState, payload: number) {
      state.equipmentItemLocationListTotal = payload
    },
    setEquipmentItemLocationListLoading(state: EquipmentItemLocationState, payload: boolean) {
      state.equipmentItemLocationListLoading = payload
    },
    setEquipment(state: EquipmentItemLocationState, payload: []) {
      state.equipment = payload
    },
    setEquipmentItems(state: EquipmentItemLocationState, payload: []) {
      state.equipmentItems = payload
    },
  },
  actions: {
    async fetchEquipmentItemLocations({ commit }, pagination) {
      commit('setEquipmentItemLocationListLoading', true)
      const response = await getEquipmentItemLocations(pagination)
      commit('setEquipmentItemLocations', response.data.data)
      commit('setEquipmentItemLocationListTotal', response.data.total)
      commit('setEquipmentItemLocationListLoading', false)
    },
    async fetchEquipmentItemLocation({ commit }, id) {
      if(this.state.equipmentItemLocationListLoading) return

      commit('setEquipmentItemLocationListLoading', true)
      const response = await getEquipmentItemLocation(id)
      commit('setEquipmentItemLocation', response.data.data)
    },
    async createEquipmentItemLocation({ commit }, data) {
      const response = await createEquipmentItemLocation(data)
      commit('setEquipmentItemLocation', response.data.data)
      return response.data.data.id
    },
    async updateEquipmentItemLocation({ commit }, { id, data }) {
      const response = await updateEquipmentItemLocation(id, data)
      commit('setEquipmentItemLocation', response.data.data)
    },
    async updateEquipmentItemLocationAmount({ commit }, { id, data }) {
      await updateEquipmentItemLocationAmount(id, data)
      commit('setEquipmentItems', data)
    },
    async deleteEquipmentItemLocation({ commit }, id) {
      await deleteEquipmentItemLocation(id)
      commit('deleteEquipmentItemLocationById', { id: id })
    },
    async fetchEquipment({ commit }, data) {
      const response = await fetchEquipment(data)
      commit('setEquipment', response.data.data)
      return response.data.data
    },
    async getEquipmentItems({ commit }, data) {
      const response = await getEquipmentItems(data)
      commit('setEquipmentItems', response.data.data)
      return response.data.data
    }
  }
})

export default equipmentItemLocationStore;

import { fetchEquipment, fetchSingleEquipment, createEquipment, updateEquipment, deleteEquipment, fetchEquipmentCategories, fetchEquipmentBaseItems, fetchEquipmentNonCombiItems } from '@/api/equipment'
import { Commit } from 'vuex'
import { EquipmentState } from '../state'

const emptyEquipmentObject = {
  name: '',
  description: '',
  category_id: null,
  is_bulk: 0,
  id: null,
}

const equipmentStore = {
  state: {
    list: [],
    categories: [],
    baseItems: [],
    nonCombiItems: [],
    listTotal: 0,
    listLoading: false,
    edit: Object.assign({ }, emptyEquipmentObject),
    create: Object.assign({ }, emptyEquipmentObject)
  },
  getters: {
    'equipmentList': (state: EquipmentState) => {
      return state.list
    },
    'equipmentCreate': (state: EquipmentState) => {
      return state.create
    },
    'equipmentEdit': (state: EquipmentState) => {
      return state.edit
    },
    'equipmentListTotalCount': (state: EquipmentState) => {
      return state.listTotal
    },
    'equipmentListLoading': (state: EquipmentState) => {
      return state.listLoading
    },
    'equipmentCategories': (state: EquipmentState) => {
      return state.categories
    },
    'equipmentBaseItems': (state: EquipmentState) => {
      return state.baseItems
    },
    'equipmentNonCombiItems': (state: EquipmentState) => {
      return state.nonCombiItems
    }
  },
  mutations: {
    'equipment.setListTo': (state: EquipmentState, payload: any) => {
      state.list = payload
    },
    'equipment.setListTotalCount': (state: EquipmentState, payload: any) => {
      state.listTotal = payload
    },
    'equipment.setListLoadingTo': (state: EquipmentState, payload: any) => {
      state.listLoading = payload
    },
    'equipment.setEditTo': (state: EquipmentState, payload: any) => {
      state.edit = payload
    },
    'equipment.editUpdateInput': (state: EquipmentState, payload: any) => {
      (state.edit as any)[payload.name] = payload.value
    },
    'equipment.createUpdateInput': (state: EquipmentState, payload: any) => {
      (state.create as any)[payload.name] = payload.value
    },
    'equipment.edit': (state: EquipmentState, payload: any) => {
      state.list = state.list.map((equipment: any) => {
        return equipment.id === payload.id ? payload.equipmentData : equipment
      })
    },
    'equipment.deleteById': (state: EquipmentState, payload: any) => {
      state.list = state.list.filter((equipment: any) => {
        return String(equipment.id) !== String(payload.id)
      })
    },
    'equipment.createClear': (state: EquipmentState) => {
      state.create = Object.assign({}, emptyEquipmentObject)
    },
    'equipment.editClear': (state: EquipmentState) => {
      state.edit = Object.assign({}, emptyEquipmentObject)
    },
    'equipment.addToList': (state: EquipmentState, payload: any) => {
      state.list.push(payload)
    },
    'equipment.setCategoriesTo': (state: EquipmentState, payload: any) => {
      state.categories = payload
    },
    'equipment.setBaseItemsTo': (state: EquipmentState, payload: any) => {
      state.baseItems = payload
    },
    'equipment.setNonCombiItemsTo': (state: EquipmentState, payload: any) => {
      state.nonCombiItems = payload
    }
  },
  actions: {
    'equipment.fetch': ({ commit }: { commit: Commit }, pagination: any) => {
      commit('equipment.setListLoadingTo', true)
      fetchEquipment(pagination).then((res) => {
        let equipment = res.data.data
        commit('equipment.setListTo', equipment)
        commit('equipment.setListTotalCount', res.data.total)
        commit('equipment.setListLoadingTo', false)
      })
    },
    'equipment.fetchSingleForEdit': ({commit}: { commit: Commit }, id: any) => {
      return fetchSingleEquipment(id).then((res) => {
        let equipment = res.data
        commit('equipment.setEditTo', equipment)
      })
    },
    'equipment.create': ({ commit, state }: { commit: Commit, state: EquipmentState}) => {
      return new Promise((resolve, reject) => {
        createEquipment(state.create).then((res) => {
          let equipment = Object.assign(state.create, { id: res.data.id })
          commit('equipment.addToList', equipment)
          commit('equipment.createClear')
          resolve(equipment.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'equipment.edit': ({ commit, state }: { commit: Commit, state: EquipmentState}) => {
      return new Promise(function (resolve, reject) {
        updateEquipment(state.edit.id, state.edit).then((res) => {
          commit('equipment.edit', {
            id: state.edit.id,
            equipmentData: Object.assign(state.edit, {
              newImage: null // Clear the newimage so that subsequent submits won't upload the image again
            })
          })
          // If image was changed, these will arrive
          if (res.data.image && res.data.image_href) {
            commit('equipment.editUpdateInput', { name: 'image', value: res.data.image })
            commit('equipment.editUpdateInput', { name: 'image_href', value: res.data.image_href })
          }
          resolve(state.edit.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'equipment.delete': ({ commit }: { commit: Commit }, id: any) => {
      return new Promise((resolve, reject) => {
        deleteEquipment(id).then((res) => {
          commit('equipment.deleteById', { id: id })
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'equipment.fetchCategories': ({ commit }: { commit: Commit }, id: any) => {
      return fetchEquipmentCategories(id).then((res) => {
        commit('equipment.setCategoriesTo', res.data)
      })
    },
    'equipment.fetchBaseItems': ({ commit }: { commit: Commit }, id: any) => {
      return fetchEquipmentBaseItems(id).then((res) => {
        commit('equipment.setBaseItemsTo', res.data)
      })
    },
    'equipment.fetchNonCombiItems': ({ commit }: { commit: Commit }, id: any) => {
      return fetchEquipmentNonCombiItems().then((res) => {
        commit('equipment.setNonCombiItemsTo', res.data)
      })
    }
  }
}

export default equipmentStore

import axios from 'axios'

export function fetchRentalSuppliers () {
  return axios.get('/rental-suppliers')
}

export function fetchRentalSupplier (id: number|string) {
  return axios.get('/rental-suppliers/' + id)
}

export function createRentalSupplier (data: Record<string, any>) {
  return axios.post('/rental-suppliers', data)
}

export function updateRentalSupplier (id: number|string, data: Record<string, any>) {
  return axios.put('/rental-suppliers/' + id, data)
}

export function deleteRentalSupplier (id: number|string) {
  return axios.delete('/rental-suppliers/' + id)
}

import router from '@/router'
import { AppState } from '../state'

const appStore = {
  state: {
    pageTitle: '',
    breadcrumbs: [] as any[],
    toggleButton: null,
    user: {}
  },
  getters: {
    'appGetPageTitle': (state: AppState)=> {
      return state.pageTitle
    },
    'appGetBreadcrumbs': (state: AppState)=> {
      return state.breadcrumbs
    },
    'toggleButton': (state: AppState)=> {
      return state.toggleButton
    },
    'user': (state: AppState)=> {
      return state.user
    }
  },
  mutations: {
    'app.setPageTitle': (state: AppState, payload: any) => {
      state.pageTitle = payload
    },
    'app.setBreadcrumbs': (state: AppState, payload: any) => {
      // Dashboard will always be the first element
      state.breadcrumbs = [
        { text: 'Dashboard', routeName: 'Dashboard' }
      ]
      .concat(payload)
      .map((item: any) => {
        // We have to transform router names to hrefs for Vuetify's breadcrumb component
        item.href = router.resolve({ name: item.routeName, params: item.routeParams }).href
        return item
      })
    },
    'app.clearBreadcrumbs': (state: AppState) => {
      state.breadcrumbs = []
    },
    'app.setUser': (state: AppState, payload: any) => {
      state.user = payload
    },
    'app.setToggleButton': (state: AppState, payload: any) => {
      state.toggleButton = payload
    },
    'app.clearToggleButton': (state: AppState) => {
      state.toggleButton = null
    }
  },
  actions: { }
}

export default appStore

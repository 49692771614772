// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import App from './App.vue'
import axios from 'axios'
import api from './api/index'
import auth from '@/utils/auth'
import router from './router'

// import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
// import driverAuthBearer from './utils/auth-driver-bearer'
// import driverRouterVueRouter from './utils/auth-vue-router'
import Notifications from '@kyvg/vue3-notification'

import PortalVue from 'portal-vue'
import * as Sentry from "@sentry/vue";
import store from './store'
import './utils/filters'
import './helpers'
import i18n from './i18n'
import './assets/app.scss'

// Composables
import { createApp } from 'vue'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
import { VATheme, defaults } from './theme/CustomTheme';

import bus from './utils/eventBus'

// add axios interceptor to handle unauthenticated error
api.initInterceptors()

const vuetify = createVuetify({
  components,
  directives,
  ...labs,
  theme: {
    defaultTheme: 'VATheme',
    themes: {
      VATheme
    },
  },
  defaults,
})

/**
 * Axios overwrite configs
 */
axios.defaults.baseURL = import.meta.env.VITE_APP_BASE_API_URL
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = import.meta.env.VITE_APP_BASE_API_URL
const JWTtoken = localStorage.getItem('auth_token_default') ? `Bearer ${localStorage.getItem('auth_token_default')?.replaceAll('"', '')}` : '';
axios.defaults.headers.common['Authorization'] = JWTtoken;

// Plugins
import { registerPlugins } from '@/plugins'

const app = createApp(App)

registerPlugins(app)

const debug = import.meta.env.VITE_APP_ENV === 'production'

// Sentry.io
if (debug) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_APP_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  store.subscribe(function (mutation, state) {
    if (mutation.type === 'app.setUser') {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: mutation.payload.id,
          name: mutation.payload.name,
          email: mutation.payload.email
        })
      })
    }
  })
}

// Attach the event bus to the Vue instance
app.config.globalProperties.$bus = bus;

app.use(store)
  .use(Notifications)
  .use(PortalVue)
  .use(vuetify)
  .use(i18n)
  .use(auth)
  .provide('bus', bus)
  .mount('#app')

<template>
  <div>
    <EmployeesForm
      :employee="employee"
      :submit="submit"
      :onInput="onInput"
      :loading="loading"
      :buttonText="t('create')"
      @update:loading="loading = $event"
    />
  </div>
</template>

<script setup lang="ts">
import EmployeesForm from './Form.vue'
import Say from '@/utils/Say'
import { onBeforeMount, defineComponent, ref, computed } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'

defineComponent({
  EmployeesForm
})
const loading = ref(false)
const { t } = useI18n()
const router = useRouter()

const employee = computed(() => {
  return store.getters.employeeCreate
})
onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('employeeCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('employee', 2), routeName: 'Employees' },
    { text: t('employeeCreate') }
  ])
})
const submit = () => {
  loading.value = true
  store.dispatch('employees.create').then((id) => {
    loading.value = false
    store.commit('cache.employees.setDirty')
    Say('success', t('employeeCreateSuccess'))
    router.push({ name: 'EmployeesEdit', params: { id: id } })
  }).catch((err) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
const onInput = (name: string, value: any) => {
  store.commit('employees.createUpdateInput', { name: name, value: value })
}

</script>

<template>
  <div>
    <EquipmentForm
      :equipment="equipment"
      :submit="submit"
      :buttonText="t('create')"
      :onInput="onInput"
      :loading="loading"
      @loading="loading = $event"
    />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, defineComponent, computed, ref } from 'vue';
import { default as EquipmentForm } from './Form.vue'
import Say from '@/utils/Say'
import { useI18n } from 'vue-i18n';
import store from '@/store';
import { useRouter } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { t } = useI18n()
const router = useRouter()

defineComponent({
  EquipmentForm
})

const loading = ref(false)

const equipment = computed(() => {
  return store.getters.equipmentCreate
})
onBeforeMount(() => {
  store.dispatch('equipment.fetchCategories')
  store.dispatch('equipment.fetchBaseItems')
  store.commit('app.setPageTitle', t('equipmentCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('equipment', 2), routeName: 'Equipment' },
    { text: t('equipmentCreate') }
  ])
})

const submit = () => {
  loading.value = true
  return store.dispatch('equipment.create').then((id) => {
    loading.value = false
    Say('success', t('equipmentCreateSuccess'))
    // Redirect to the equipment edit screen
    router.push({ name: 'EquipmentEdit', params: { id: id } })
  }).catch((err) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
const onInput = (name: any, value: any) => {
  store.commit('equipment.createUpdateInput', { name: name, value: value })
}
</script>

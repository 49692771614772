import axios from 'axios'
import  useBranchSelector  from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function uploadJobFiles (filesForm: FormData) {
  return axios.post('/jobs/file', filesForm)
}

export function fetchJobs (data: Record<string, any>) {
  let requestData
  if (typeof data !== 'undefined') {
    requestData = {
      params: data
    }
  }

  if(requestData) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }
  return axios.get('/jobs', requestData)
}

export function fetchActiveJobs (params: Record<string, any>) {
  return axios.get('/jobs/active', { params })
}

export function fetchJob (id: number|string) {
  return axios.get('/jobs/' + id)
}

export function createJob (data: Record<string, any>) {
  return axios.post('/jobs', data)
}

export function updateJob (id: number|string, data: Record<string, any>) {
  return axios.put('/jobs/' + id, data)
}

export function deleteJob (id: number|string) {
  return axios.delete('/jobs/' + id)
}



export function jobFilesIndex (id: number|string) {
  return axios.get(`/job-files/${id}`)
}

export function jobFileDestroy (jobId: number|string, fileId: number|string) {
  return axios.delete(`/job-files/${jobId}/${fileId}`)
}

export function jobFileUpdate (jobId: number|string, fileId: number|string, data: Object) {
  return axios.put(`/job-files/${jobId}/${fileId}`, data)
}

export function jobFileShow (jobId: number|string, fileId: number|string) {
  return axios.get(`/job-files/${jobId}/${fileId}`)
}

export function deleteJobFile (id: number|string) {
  return axios.delete('/jobs/files/' + id)
}

export function updateJobFileComments (id: number|string, comments: any) {
  return axios.post('/jobs/files/' + id + '/comments', {
    comments: comments
  })
}

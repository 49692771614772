<template>
  <v-card>
    <v-card-title>
      <h2>{{ucfirst($t('add')) }} contact</h2>
    </v-card-title>
    <v-card-text>
      <v-text-field
        :label="$t('name')"
        v-model="name"
        required
        ref="nameInput"
      />
      <v-text-field
        :label="$t('phone')"
        v-model="phone"
      />
    </v-card-text>
    <v-card-actions>
      <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
      <div v-if="!loading">
        <v-btn color="orange-darken-2" depressed @click.stop="saveContact()">
          {{ $t('add') }}
        </v-btn>
        <v-btn color="primary" flat @click="$emit('close', false)">
          {{ $t('cancel') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import Say from '@/utils/Say'
import { createClientContact } from '@/api/clients'
import { onMounted, ref } from 'vue';
import { ucfirst } from '@/utils/filters';
import emitArray from '@/utils/emit';
import { defineEmits, defineProps } from 'vue';
import sayErrorResponse from '@/mixins/sayErrorResponse';

import { useEventBus } from '@vueuse/core'
const createdBus = useEventBus('created-employees');

const emit = defineEmits(emitArray.value)

const props = defineProps({
  clientId: {
    type: Number,
    required: true
  }
})

const name= ref(null)
const phone= ref(null)
const loading= ref(false)

onMounted(() => {
  name.value = null
  phone.value = null
  loading.value = false
})
const saveContact = async() => {
  loading.value = true
  try {
    const response = await createClientContact({
      name: name.value,
      phone: phone.value,
      client_id: props.clientId
    })
    loading.value = false
    Say('success', 'Contact aangemaakt')
    emit('created', response.data.contact)
    createdBus.emit({ type: 'executor', data: response.data.contact })
    emit('close')
  } catch (err: any) {
    loading.value = false
    sayErrorResponse(err)
  }
}
</script>

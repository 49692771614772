<template>
  <v-card flat>
    <v-toolbar class="custom-bg" density="compact">
      <v-toolbar-title>Status</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-autocomplete
        :items="statuses"
        :label="$t('status')"
        v-model="status"
        @update:model-value="$nextTick(() => $emit('change', { status: $event }))"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { tm } = useI18n()
const props = defineProps({
  job: {
    type: Object,
    required: true
  }
})

const status= ref(null)
const statuses= ref([] as any[])

onMounted (() => {
  initializeInputs()
})
const initializeInputs = () => {
  status.value = props.job.status ? props.job.status : null
  statuses.value = Object.keys(tm('jobStatuses')).map((key, index) => {
    return {
      value: key,
      title: Object.values(tm('jobStatuses'))[index],
      disabled: false
    }
  }).filter(status => ['DRAFT', 'CLOSED'].indexOf(status.value) === -1)
}

defineExpose({
  initializeInputs
});
</script>

<template>
  <v-row>
    <v-col sm="6">
      <v-form v-on:submit.prevent="submitForm()" v-model="valid" ref="form">
        <v-card flat :border="true">
          <v-card-text>
            <v-radio-group v-model="equipmentItemLocation.toLocationType" row>
              <v-radio
                :label="$t('vaLocation')"
                value="vaLocation"
              ></v-radio>
              <v-radio
                :label="$t('rentalSuppliersLocation')"
                value="rentalSuppliersLocation"
                :disabled="!equipmentItem.rental_supplier_id"
              ></v-radio>
              <!-- <v-radio
                :label="$t('clientLocation')"
                value="clientLocation"
              ></v-radio> -->
            </v-radio-group>

            <v-autocomplete
              v-if="!equipmentItemLocation.id && equipmentItemLocation.toLocationType === 'vaLocation'"
              :items="vaLocations"
              v-model="equipmentItemLocation.va_location_id"
              :label="$t('location')"
              item-value="id"
              item-title="location"
              @input="onInput('va_location_id', $event.target.value)"
              variant="underlined"
              :disabled="equipmentItem.location.job_id"
            ></v-autocomplete>

            <v-autocomplete
              v-if="!equipmentItemLocation.id && equipmentItemLocation.toLocationType === 'rentalSuppliersLocation'"
              :items="rentalSuppliersLocations"
              v-model="equipmentItemLocation.rental_suppliers_location_id"
              :label="$t('location')"
              item-value="id"
              item-title="location"
              @input="onInput('rental_suppliers_location_id', $event.target.value)"
              variant="underlined"
              :disabled="equipmentItem.location.job_id"
            ></v-autocomplete>

            <!-- if it will become possible to move equipment items to a client location,
              also a job_id should be selected. For now it's possible in a Job (equipment tab)
              to move equipment items to locations of that job -->

            <!-- <v-autocomplete
              v-if="!equipmentItemLocation.id && equipmentItemLocation.toLocationType === 'clientLocation'"
              v-model="clientId"
              :label="$t('client')"
              :loading="loadingClient"
              :items="clients"
              :search-input.sync="clientSearch"
              item-value="id"
              item-title="name"
              clearable
              variant="underlined"
              />

            <v-autocomplete
              v-if="!equipmentItemLocation.id && equipmentItemLocation.toLocationType === 'clientLocation'"
              v-model="equipmentItemLocation.location_id"
              :items="clientLocations"
              clearable
              :label="$t('location')"
              item-title="location"
              item-value="id"
              variant="underlined"
              @input="onInput('location_id', $event.target.value)"
            /> -->


            <template v-if="isAtSelectedLocation">
              <v-alert
                type="warning"
              >
                {{t('itemsAlreadyAtLocationSingle')}}
              </v-alert>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading"
              :disabled="isSaveDisabled || equipmentItem.location.job_id">
              {{ buttonText }}
            </v-btn>
            <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import store from '@/store';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router'
import { onBeforeMount, onMounted } from 'vue';
import { fetchClient } from '@/api/clients'
import { cloneDeep } from 'lodash-es'
import { watch } from 'vue';
import { equipmentItemStore } from '@/store/equipmentItems'

const props = defineProps({
  equipmentItemLocation: {
    type: Object,
    required: true
  },
  equipmentItem: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: true
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Save'
  }
})

const valid = ref(false)
const form = ref()
const clientId = ref(null as any)
const client = ref(null as any)
const loadingClient = ref(false)
const clientLocations = ref([] as any[])

const { t } = useI18n()

onBeforeMount(() => {
  store.dispatch('cache.vanAmerongenLocations.fetch')
  store.dispatch('cache.rentalSuppliersLocations.fetch')
  // store.dispatch('cache.clients.fetch')
})

onMounted(async() => {
  await initializeInputs()
  props.equipmentItemLocation.toLocationType = 'vaLocation'
})

const vaLocations = computed(() => {
  return store.getters['cache.vanAmerongenLocations']
})

const rentalSuppliersLocations = computed(() => {
  return store.getters['cache.rentalSuppliersLocations']?.filter((item) => {
    return props.equipmentItem.rental_supplier_id === item.rental_supplier_id
  }) ?? []
})

const selectedLocation = computed(() => {
  return props.equipmentItemLocation.va_location_id ?? props.equipmentItemLocation.rental_suppliers_location_id
})

const isAtSelectedLocation = computed(() => {
  if(!selectedLocation.value || !props.equipmentItem.location?.location?.id) {
    return false
  }
  return selectedLocation.value === props.equipmentItem.location?.location?.id
})
const isSaveDisabled = computed(() => {
  if(!valid.value) {
    return true
  }
  if(isAtSelectedLocation.value) {
    return true
  }
  return false
})

const clients = computed(() => store.getters['cache.clients'])

if (router.currentRoute.value.query.equipment_item_id) {
  props.equipmentItemLocation.equipment_item_id = router.currentRoute.value.query.equipment_item_id
}

const submitForm = () => {
  props.submit()
}

watch(() => clientId.value, () => {
  fetchClientLocal()
})

watch(() => props.equipmentItemLocation, () => {
  if(props.equipmentItemLocation.toLocationType === 'vaLocation') {
    if(props.equipmentItemLocation.rental_suppliers_location_id) {
      delete props.equipmentItemLocation.rental_suppliers_location_id
    }
  } else {
    if(props.equipmentItemLocation.va_location_id) {
      delete props.equipmentItemLocation.va_location_id
    }
  }

}, {deep: true})

const fetchClientLocal = async() => {
  if (!clientId.value) return
  loadingClient.value = true
  const response = await fetchClient(clientId.value)
  const clientData = response.data
  loadingClient.value = false
  client.value = clientData
  client.value.locations = client.value.locations.sort((a: any, b: any) => {
    const locationA = a.location.toLowerCase()
    const locationB = b.location.toLowerCase()
    if (locationA < locationB) return -1
    if (locationA > locationB) return 1
    return 0
  })
  updateLocationToItems()
}

const updateLocationToItems = ()  => {
  if (clientId.value && client.value && client.value.locations) {
    clientLocations.value = cloneDeep(client.value.locations)
  } else {
    clientLocations.value = []
  }
}

const initializeInputs = async() => {
  await fetchClientLocal()
}
</script>

<style scoped lang="scss">
table.table {
  tbody {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
</style>

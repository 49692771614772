import axios from 'axios'
import useBranchSelector from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function fetchEmployees (filters: Record<string, unknown> = null) {
  let requestData = {
    params: {}
  }
  if (filters) {
    requestData.params = filters
  }

  if(requestData) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }

  return axios.get('/employees', requestData)
}

export function fetchEmployee (id: number|string) {
  return axios.get('/employees/' + id)
}

export function createEmployee (data: Record<string, unknown>) {
  return axios.post('/employees', data)
}

export function updateEmployee (id: number|string, data: Record<string, unknown>) {
  return axios.put('/employees/' + id, data)
}

export function deleteEmployee (id: number|string) {
  return axios.delete('/employees/' + id)
}

export function restoreEmployee (id: number|string) {
  return axios.post('/employees/' + id + '/restore')
}

export function fetchWeek (year: number|string|null = null, weekNr: number|string|null = null, employeeId: number|string|null = null) {
  let uri = '/hours/week'
  // Need both of them
  if (year && weekNr) {
    uri += '/' + year + '/' + weekNr
    if (employeeId) {
      uri += '/' + employeeId
    }
  }
  return axios.get(uri)
}

export function saveEmployeeHours (employeeId: number|string, data: Record<string, any>) {
  return axios.put('/hours/employee/' + employeeId, data)
}

export function fetchTimeoff (employeeId: number|string) {
  return axios.get('/employees/' + employeeId + '/timeoff')
}

export function saveTimeoff (employeeId: number|string, timeoff: any) {
  return axios.post('/employees/' + employeeId + '/timeoff', timeoff)
}

export function fetchWeekStatus (year: number|string, weekNr: number|string) {
  return axios.get('/week-status/' + year + '/' + weekNr)
}

export function setWeekStatus (year: number|string, weekNr: number|string, data: Record<string, any>) {
  return axios.post('/week-status/' + year + '/' + weekNr, data)
}

export function generateWeekExport (year: number|string, weekNr: number|string, type: any) {
  return axios.post('/hours/week-export/' + year + '/' + weekNr + '/' + type)
}

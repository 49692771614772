import { fetchWorkOrders, fetchWorkOrderNotes, fetchWorkOrder, createWorkOrder, updateWorkOrder, deleteWorkOrder, deleteWorkOrderFile, duplicateWorkOrder } from '@/api/workOrders'
import { dateAndTimeToDatetime, datetimeSplit } from '@/helpers'
import { cloneDeep } from 'lodash-es'
import { Commit } from 'vuex'
import { WorkOrderState } from '../state'

let emptyWorkOrderObject = {
  project_name: '',
  job_id: null,
  category: null,
  client_id: null,
  is_for_equipment_movement: false,
  client: {},
  project_id: null,
  date_start: null as any,
  time_start: null as any,
  date_end: null as any,
  time_end: null as any,
  employees: [],
  employee_id: null,
  instructions: null,
  location: null,
  location_va: null,
  files: [],
  bulk_workers: [],
  status: null,
  client_responsible_1: {},
  client_responsible_2: {},
  id: null,
}

const workOrderStore = {
  state: {
    list: [],
    listTotal: 0,
    listLoading: false,
    create: cloneDeep(emptyWorkOrderObject),
    edit: cloneDeep(emptyWorkOrderObject)
  },
  getters: {
    'workOrdersCreate': (state: WorkOrderState) => {
      return state.create
    },
    'workOrdersEdit': (state: WorkOrderState) => {
      return state.edit
    },
    'workOrdersList': (state: WorkOrderState) => {
      return state.list
    },
    'workOrdersListTotalCount': (state: WorkOrderState) => {
      return state.listTotal
    },
    'workOrdersListLoading': (state: WorkOrderState) => {
      return state.listLoading
    }
  },
  mutations: {
    'workOrders.setListTo': (state: WorkOrderState, payload: any) => {
      state.list = payload
    },
    'workOrders.setEditTo': (state: WorkOrderState, payload: any) => {
      state.edit = payload
    },
    'workOrders.createUpdateInput': (state: WorkOrderState, payload: { name: string; value: any }) => {
      if (payload.name.indexOf('.') === -1) {
        (state.create as any)[payload.name] = payload.value
      } else {
        let parts = payload.name.split('.');
        // TODO: get it to work with more than one nested level
        (state.create as any)[parts[0]][parts[1]] = payload.value
      }
    },
    'workOrders.createAddFile': (state: WorkOrderState, payload: any) => {
      state.create.files.push(payload)
    },
    'workOrders.createRemoveFile': (state: WorkOrderState, payload: { id: any }) => {
      state.create.files = state.create.files.filter((file: { id: any }) => {
        return file.id !== payload.id
      })
    },
    'workOrders.editUpdateInput': (state: WorkOrderState, payload: { name: string; value: any }) => {
      if (payload.name.indexOf('.') === -1) {
        (state.edit as any)[payload.name] = payload.value
      } else {
        let parts = payload.name.split('.');
        // TODO: get it to work with more than one nested level
        (state.edit as any)[parts[0]][parts[1]] = payload.value
      }
    },
    'workOrders.createClear': (state: WorkOrderState) => {
      state.create = cloneDeep(emptyWorkOrderObject)
    },
    'workOrders.editClear': (state: WorkOrderState) => {
      state.edit = cloneDeep(emptyWorkOrderObject)
    },
    'workOrders.addToList': (state: WorkOrderState, payload: any) => {
      state.list.push(payload)
    },
    'workOrders.setListTotalCount': (state: WorkOrderState, payload: any) => {
      state.listTotal = payload
    },
    'workOrders.setListLoadingTo': (state: WorkOrderState, payload: any) => {
      state.listLoading = payload
    },
    'workOrders.edit': (state: WorkOrderState, payload: any) => {
      state.list = state.list.map((workOrder: any) => {
        return workOrder.id === payload.id ? payload.workOrderData : workOrder
      })
    },
    'workOrders.editAddFile': (state: WorkOrderState, payload: any) => {
      state.edit.files.push(payload)
    },
    'workOrders.editRemoveFile': (state: WorkOrderState, payload: any) => {
      state.edit.files = state.edit.files.filter((file: any) => {
        return file.id !== payload.id
      })
    },
    'workOrders.deleteById': (state: WorkOrderState, payload: any) => {
      state.list = state.list.filter((workOrder: any) => {
        return workOrder.id !== payload.id
      })
    }
  },
  actions: {
    'workOrders.fetch': async ({ commit }: any, pagination: any) => {
      // TODO: Catch errors
      commit('workOrders.setListLoadingTo', true)
      const res = await fetchWorkOrders(pagination)
      // commit('workOrders.setListTo', res.data.data)
      commit('workOrders.setListTotalCount', res.data.total)
      commit('workOrders.setListLoadingTo', false)

      return res.data.data
    },
    'workOrders.fetchSingleForEdit': async ({ commit, state }: any, id: any) => {
      let res = await fetchWorkOrder(id)

      let workOrder = res.data
      // Start
      workOrder.datetime_start = datetimeSplit(workOrder.datetime_start)
      workOrder.date_start = workOrder.datetime_start.date
      workOrder.time_start = workOrder.datetime_start.time

      // End
      workOrder.datetime_end = datetimeSplit(workOrder.datetime_end)
      workOrder.date_end = workOrder.datetime_end.date
      workOrder.time_end = workOrder.datetime_end.time

      // Status
      workOrder.status = workOrder.status.name || 'DRAFT'

      res = await fetchWorkOrderNotes(id)

      workOrder.notes = res.data

      commit('workOrders.setEditTo', workOrder)
    },
    'workOrders.create': ({ commit, state }: { commit : Commit, state: WorkOrderState}) => {
      return new Promise((resolve, reject) => {
        let objectCreate = cloneDeep(state.create);
        objectCreate.date_start = dateAndTimeToDatetime(objectCreate.date_start!, objectCreate.time_start!);
        objectCreate.date_end = dateAndTimeToDatetime(objectCreate.date_end!, objectCreate.time_end!)
        objectCreate.files = objectCreate.files.map((file: any) => {
          return file.id
        })
        createWorkOrder(objectCreate).then((res) => {
          let workOrder = Object.assign(state.create, { id: res.data.id })
          commit('workOrders.addToList', workOrder)
          commit('workOrders.createClear')
          resolve(workOrder.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'workOrders.edit': ({ commit, state }: { commit : Commit, state: WorkOrderState}) => {
      let objectEdit = cloneDeep(state.edit);
      // Some mutations
      objectEdit.date_start = dateAndTimeToDatetime(objectEdit.date_start!, objectEdit.time_start!)
      objectEdit.date_end = dateAndTimeToDatetime(objectEdit.date_end!, objectEdit.time_end!)
      objectEdit.files = objectEdit.files.map((file: any) => {
        return file.id
      })
      // Ensure the location is the id
      if (objectEdit.location instanceof Object && objectEdit.location.id !== undefined) {
        objectEdit.location = objectEdit.location.id
      }
      // Ensure the Van Amerongen location is the id
      if (objectEdit.location_va instanceof Object && objectEdit.location_va.id !== undefined) {
        objectEdit.location_va = objectEdit.location_va.id
      }
      // Data which is not to be sent
      let toDelete: string[] = [
        'selectedEmployee', 'selectedEmployees', 'selectedResponsible', 'revisions', 'created_by',
        'client', 'employee', 'responsible', 'status_ongoing'
      ]
      for (let i in toDelete) {
        delete objectEdit[toDelete[i] as keyof typeof objectEdit]
      }
      // Return the promise
      return new Promise(function (resolve, reject) {
        updateWorkOrder(state.edit.id, objectEdit).then((res) => {
          commit('workOrders.edit', {
            id: state.edit.id,
            workOrderData: Object.assign(state.edit, { revisions: res.data.revisions })
          })
          resolve(state.edit)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'workOrders.delete': ({ commit }: any, id: any) => {
      return new Promise((resolve, reject) => {
        deleteWorkOrder(id).then((res) => {
          commit('workOrders.deleteById', { id: id })
          resolve('workOrders.deleteById')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'workOrders.duplicate': ({ commit }: any, id: any) => {
      return new Promise((resolve, reject) => {
        duplicateWorkOrder(id).then((res) => {
          const workOrder = res.data.workOrder

          commit('workOrders.addToList', workOrder)
          resolve(workOrder.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'workOrders.deleteFile': ({ commit }: {commit: Commit}, id: any) => {
      return new Promise((resolve, reject) => {
        deleteWorkOrderFile(id).then((res) => {
          resolve('workOrders.delete')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    }
  }
}

export default workOrderStore

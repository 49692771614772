export function focusNextInput (current: string)  {
  switch (current) {
    case 'date_start':
      document.getElementById('dp-input-time_start')?.focus()
      break;
    case 'time_start':
      document.getElementById('dp-input-date_end')?.focus()
      break;
    case 'date_end':
      document.getElementById('dp-input-time_end')?.focus()
      break;
  }
}

export default {
  focusNextInput
}
import { getBranches, getBranch, createBranch, updateBranch, deleteBranch } from "@/api/branches";
import { createStore } from "vuex";
import { BranchState } from "../state";
import { Branch } from "../types";


export const branchStore = createStore({
  state: {
    branches: [],
    branch: {} as any,
    branchListTotal: 0,
    branchListLoading: false
  },
  getters: {
    branches: (state: BranchState) => state.branches,
    branch: (state: BranchState) => state.branch,
    branchListTotal: (state: BranchState) => state.branchListTotal,
    branchListLoading: (state: BranchState) => state.branchListLoading
  },
  mutations: {
    setBranches(state: BranchState, payload: Branch[]) {
      state.branches = payload
    },
    setBranch(state: BranchState, payload: Branch) {
      state.branch = payload
    },
    clearBranch(state: BranchState) {
      state.branch = {} as any
    },
    deleteBranchById(state: BranchState, payload: any) {
      state.branches = state.branches.filter((branch: any) => {
        return String(branch.id) !== String(payload.id)
      })
      state.branchListTotal = state.branchListTotal - 1
    },
    setBranchListTotal(state: BranchState, payload: number) {
      state.branchListTotal = payload
    },
    setBranchListLoading(state: BranchState, payload: boolean) {
      state.branchListLoading = payload
    }
  },
  actions: {
    async fetchBranches({ commit }, pagination) {
      commit('setBranchListLoading', true)
      const response = await getBranches(pagination)
      commit('setBranches', response.data.data)
      commit('setBranchListTotal', response.data.total)
      commit('setBranchListLoading', false)
    },
    async fetchBranch({ commit }, id) {
      if(this.state.branchListLoading) return

      commit('setBranchListLoading', true)
      const response = await getBranch(id)
      commit('setBranch', response.data.data)
    },
    async createBranch({ commit }, data) {
      const response = await createBranch(data)
      commit('setBranch', response.data.data)
      return response.data.data.id
    },
    async updateBranch({ commit }, { id, data }) {
      const response = await updateBranch(id, data)
      commit('setBranch', response.data.data)
    },
    async deleteBranch({ commit }, id) {
      await deleteBranch(id)
      commit('deleteBranchById', { id: id })
    }
  },
})

export default branchStore;

import { notify } from "@kyvg/vue3-notification";

export default function (type: any, data: any) {
  let message = ''
  if (typeof data === 'string' || data ) {
    message = data
  } else if (data instanceof Array) {
    message = data.join('<br>')
  } else if (typeof data === 'object') {
    let msgArray: any[] = []
    for (let i = 0; i < Object.keys(data).length; i++) {
      let key = Object.keys(data)[i]
      msgArray = msgArray.concat(data[key])
    }
    message = msgArray.join('<br>')
  }
  notify({
    type: type,
    text: message
  })
}
<template>
  <div>
    <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
    <EquipmentForm
      :equipment="equipment"
      :submit="submit"
      :buttonText="t('edit')"
      :onInput="onInput"
      :loading="loading"
      @loading="loading = $event"
      v-if="!preLoading"
    />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { default as EquipmentForm } from './Form.vue'
import Say from '@/utils/Say'
import { onBeforeUnmount, computed, ref, defineComponent } from 'vue';
import store from '@/store';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import sayErrorResponse from '@/mixins/sayErrorResponse'

defineComponent({
  EquipmentForm
})

const route = useRoute()
const { t } = useI18n()
  
const preLoading = ref(true)
const loading = ref(false)

const equipment = computed(() => {
  return store.getters.equipmentEdit
})
onBeforeMount(() => {
  store.dispatch('equipment.fetchCategories')
  store.dispatch('equipment.fetchBaseItems')
  store.dispatch('equipment.fetchSingleForEdit', route.params.id).then(function () {
    preLoading.value = false
    // UI
    store.commit('app.setPageTitle', 'Edit ' + store.getters.equipmentEdit.name)
    store.commit('app.setBreadcrumbs', [
      { text: t('equipment', 2), routeName: 'Equipment' },
      { text: t('edit') + ' ' + store.getters.equipmentEdit.name }
    ])
  }.bind(this))
})
onBeforeUnmount(() => {
  store.commit('equipment.editClear')
})

const submit = () => {
  loading.value = true
  return store.dispatch('equipment.edit').then(() => {
    loading.value = false
    Say('success', t('equipmentEditSuccess'))
  }).catch((err) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
const onInput = (name: any, value: any) => {
  store.commit('equipment.editUpdateInput', { name: name, value: value })
}

</script>

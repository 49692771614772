<template>
  <v-card flat>
    <v-card-text>
      <v-textarea
        :label="$t('instructions')"
        v-model="instructions"
        rows="3"
        auto-grows
        hide-details
        variant="outlined"
        @change="$nextTick(() => $emit('change', { instructions }))"
      ></v-textarea>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es';
import { ref, onMounted } from 'vue';

const props = defineProps({
  job: {
    type: Object,
    required: true
  }
})

const instructions = ref(null)
const originalJob = ref(null as any)

onMounted (() => {
  originalJob.value = cloneDeep(props.job)
  initializeInputs()
})

const initializeInputs = () => {
  instructions.value = originalJob.value.instructions ?? null
}

defineExpose({
  initializeInputs
});
</script>


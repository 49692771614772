<template>
  <v-container fluid>
    <v-row wrap>
      <v-col sm="12">
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default { }
export { default as ClientsList } from './List'
export { default as ClientsCreate } from './Create'
export { default as ClientsEdit } from './Edit'
</script>

<template>
  <v-row wrap>
    <v-col sm="6" >
      <v-card flat>
        <v-card-text class="px-0">
          <v-table class="table">
            <thead>
              <th class="text-start pl-4">Id</th>
              <th class="text-start pl-4">{{ $t('name') }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="role in rolesList" :key="`${role.id}`">
                <td>
                  <router-link class="custom-a" :to="{ name: 'RolesEdit', params: { id: role.id }}">
                    {{ role.id }}
                  </router-link>
                </td>
                <td>
                  <router-link class="custom-a" :to="{ name: 'RolesEdit', params: { id: role.id }}">
                    {{ role.name }}
                  </router-link>
                </td>
                <td class="text-end">
                  <v-btn variant="text" :to="{ name: 'RolesEdit', params: { id: role.id }}" icon small flat >
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed,  onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()

// Computed
const rolesList = computed(() => store.getters['rolesList'])

// Created Hook
onBeforeMount(() => {
  store.dispatch('roles.fetch')

  // UI
  store.commit('app.setPageTitle', t('role', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('user', 2), routeName: 'Users' },
    { text: t('role', 2) }
  ])
})
</script>

<style scoped>
  table.table thead th {
    padding: 10px 24px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  .table tbody tr:nth-child(even) td {
    background: #f5f5f5;
  }
</style>

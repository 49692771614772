<template>
  <div class="document">
    <div class="page">
      <template v-if="workOrder.id">
        <v-row class="justify-space-between">
          <v-row class="align-center justify-start flex-grow-0 flex-shrink-0">
            <v-col cols="4">
              <img src="../../../assets/logo.svg" alt="Van Amerongen" height="80">
            </v-col>

            <v-col cols="7" class="monospace ml-3 text_data font-weight-bold">
              <div>Van Amerongen Facilitair bv</div>
              <div>Rijksweg 3c</div>
              <div>6921 AA Duiven</div>
              <div>T) 026-3518621</div>
              <div>I) www.vaf-infra.nl</div>
              <div>E) info@vaf-infra.nl</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" class="d-flex align-end justify-center max-w-custom font-weight-bold">
              <div class="py-5 px-8 bg-custom-yellow max-2-line text-uppercase text-center" v-if="workOrder.category">{{ workOrder.category }}</div>
            </v-col>
          </v-row>

          <v-row class="flex-column align-center mb-1">
            <v-col>
              <h2 class="text-uppercase text-center">Opdrachtbon</h2>
            </v-col>

            <v-row class="pr-1 box">
              <v-col cols="6" class="text-right px-0">
                <div><b>Bonnr:</b></div>
                <div><b>Datum:</b></div>
                <div><b>Tijd:</b></div>
              </v-col>

              <v-col cols="6" class="px-0 pl-2 text-right">
                <div>{{ workOrder.id }}</div>
                <div><template v-if="workOrder.datetime_start">{{ reverseDateFormat(workOrder.datetime_start.date) }} {{ workOrder.datetime_start.time }} uur</template></div>
              </v-col>
            </v-row>
            <p class="aanmaakdatum">Aanmaakdatum: {{ normalizeDateFormat(workOrder.created_at) }}</p>
          </v-row>
        </v-row>

        <v-row>
          <v-col class="pb-1">
            <h4><span class="b-bottom">Klant:</span></h4>
            <div v-if="workOrder.client && workOrder.client.name"><strong>{{ workOrder.client.name }}</strong></div>

            <div class="pt-2">t.a.v.</div>
            <div class="py-1" v-if="workOrder.client && workOrder.client.address">{{ workOrder.client.address }}</div>
            <div v-if="workOrder.client"><span v-if="workOrder.client.postcode">{{ workOrder.client.postcode.match(/.{1,4}/g).join(' ') }}</span> <span v-if="workOrder.client && workOrder.client.city">{{ workOrder.client.city }}</span></div>
          </v-col>

          <v-col>
            <h4><span class="b-bottom">Leveradres</span></h4>

            <v-row class="flex-column justify-space-between h-100">
              <v-col>
                <div v-if="workOrder.location">{{ workOrder.location.location }}</div>
                <div>Contact: <span v-if="workOrder.client_responsible_1">{{ workOrder.client_responsible_1.name }}</span> <span class="pl-1" v-if="workOrder.client_responsible_1 && workOrder.client_responsible_1.phone"> {{ workOrder.client_responsible_1.phone }}</span> <span v-if="workOrder.client_responsible_2">,</span>
                  <div v-if="workOrder.client_responsible_2">
                    <span>{{ workOrder.client_responsible_2.name }}</span> <span v-if="workOrder.client_responsible_2.phone" class="pl-1">{{ workOrder.client_responsible_2.phone }}</span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr>

        <section>
          <v-row class="mt-0">
            <v-col class="pb-1 py-0">
              <div v-if="workOrder.job">
                <div class="pb-1">Project: <span v-if="workOrder.job.job_name">{{ workOrder.job.job_name }}</span></div>
                <div class="pb-1">Project omschrijving: <span v-if="workOrder.job.job_description">{{ workOrder.job.job_description }}</span></div>
                <hr style="border-color:lightgray;">
              </div>
              <div class="pb-1">Werknummer: <span v-if="workOrder.id">{{ workOrder.id }}</span></div>
              <div class="py-1">Klantreferentie: <span v-if="workOrder.client_ref">{{ workOrder.client_ref }}</span></div>
              <div class="py-1">Uitvoerder: <span v-if="workOrder.client_responsible_1">{{ workOrder.client_responsible_1.name }}</span></div>
              <div class="py-1"><span v-if="workOrder.category">{{ workOrder.category }}</span></div>
              <div class="py-1">Instructies: <span v-if="workOrder.instructions">{{ workOrder.instructions }}</span></div>

            </v-col>
          </v-row>
        </section>
        <hr class="mb-0">

        <section id="equip">
          <v-row class="my-0 py-0">
            <v-col cols="4" class="py-2">
              <strong>Naam</strong>
            </v-col>

            <v-col cols="2" class="py-2">
              <strong>Notities</strong>
            </v-col>

            <v-col cols="2" class="py-2">
              <strong>Verhuurleverancier</strong>
            </v-col>

            <v-col cols="1" class="py-2 text-right">
              <strong>Aantal</strong>
            </v-col>

            <v-col cols="1" class="py-2 text-right">
              <strong>Geplaatst</strong>
            </v-col>

            <v-col cols="2" class="py-2 text-right">
              <strong>Retour</strong>
            </v-col>
          </v-row>

          <hr class="mt-0">
          <v-row class="my-0 py-0" v-if="workOrder.suggestedEquipment" v-for="item in workOrder.suggestedEquipment">
            <v-col cols="4" class="py-2">
              {{ item.equipment.name }}
            </v-col>

            <v-col cols="2" class="py-2">
              <div>{{ item.note }}</div>
            </v-col>

            <v-col cols="2" class="py-2">
              <div>{{ getRentalSupplierName(item.rental_supplier_id) }}</div>
            </v-col>

            <v-col cols="1" class="py-2 text-right">
              {{ item.quantity }}
            </v-col>

            <v-col cols="1" class="pt-3 pb-1 text-right">
              ___________
            </v-col>

            <v-col cols="2" class="pt-3 pb-1 text-right">
              ___________
            </v-col>

            <v-col cols="12" class="py-0 my-0 equimentRow">
              <hr class="my-0 mx-0"/>
            </v-col>
          </v-row>
        </section>

        <hr class="mt-16">

        <section id="form">
          <v-row>
            <v-col cols="3" class="text-right">
              <div class="py-1">
                Monteur:
              </div>
              <div class="py-1">
                Vertrek huis:
              </div>
              <div class="py-1">
                Aankomst werk:
              </div>
              <div class="py-1">
                Vertrek werk:
              </div>
              <div class="py-1">
                Aankomst huis:
              </div>
              <div class="py-1">
                Opmerking monteur:
              </div>
            </v-col>

            <v-col>
              <div class="box_fixed my-1 w-50 pl-1">
                <span v-if="workOrder.selectedEmployees && workOrder.selectedEmployees.length > 0">
                  {{ workOrder.selectedEmployees[0].name }}
                </span>
              </div>
              <div class="box_fixed my-1 w-25 pl-1">

              </div>
              <div class="box_fixed my-1 w-25 pl-1">

              </div>
              <div class="box_fixed my-1 w-25 pl-1">

              </div>
              <div class="box_fixed my-1 w-25 pl-1">

              </div>
              <div class="box_big my-1 pl-1">

              </div>
            </v-col>
          </v-row>
        </section>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fetchWorkOrderHours } from '@/api/workOrders'
import MaterialList from './MaterialList.vue'

import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import store from '@/store'
import emitArray from '@/utils/emit'
import { useRoute } from 'vue-router'
import moment from 'moment'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const emit = defineEmits(emitArray.value)
defineComponent({
  MaterialList
})

const normalizeDateFormat = (dateString: any) => {
  if (!dateString) {
    return ''
  }

  return moment(dateString).format('DD-MM-YYYY')
}

const reverseDateFormat = (dateString: string) => {
  if (!dateString) {
    return ''
  }

  return dateString.split('-').reverse().join('-')
}

const hours = ref([] as any)
const equipment = ref([])
const route = useRoute()


const workOrder = computed(() => {
  return store.getters.workOrdersEdit
})

const workOrderId = computed(() => {
  return route.params.id
})

const rentalSuppliers = computed(() => store.getters['rentalSuppliersList'])

const getRentalSupplierName = (rental_supplier_id: any) => {
  return rentalSuppliers.value.filter((supplier: any) => supplier.id === rental_supplier_id )[0]?.name ?? ''
}

onBeforeMount(() => {
  emit('hide-template-ui')
  store.dispatch('rentalSuppliers.fetch');
})

onMounted(() => {
  Promise.all([
    fetchWorkOrderHoursLocal(),
    fetchWorkOrder(workOrderId.value)
  ])
  .then(() => {
    window.setTimeout(window.print, 1000)
  }).catch(err => {
    sayErrorResponse(err)
  })
})

onBeforeUnmount(() => {
  emit('show-template-ui')
})

const fetchWorkOrder = (id: any) => {
  return store.dispatch('workOrders.fetchSingleForEdit', id)
}

/**
 * Fetch the hours reported on this workorder (departure, returning etc),
 * format the response, so we get all hours, not nested per person,
 * and sort all reported hours by datetime_start
 */
const fetchWorkOrderHoursLocal = async() => {
  const response = await fetchWorkOrderHours(workOrderId.value)
  const data = response.data

  if (!data) {
    hours.value = []
    return
  }

  hours.value = []

  data.forEach((worker: any) => {
    if (worker.hours && worker.hours.length) {
      const mechanic = {
        employeeId: worker.id,
        name: worker.name,
        company: worker.company
      }
      worker.hours.forEach((hour: never) => {
        hours.value.push(Object.assign({}, hour, { mechanic }))
      })
    }
  })

  hours.value.sort((a: any, b: any) => {
    if (a.datetime_start > b.datetime_start) return 1
    if (a.datetime_start < b.datetime_start) return -1
    return 0
  })

  hours.value = Array.from(hours.value)
}
</script>

<style lang="scss" scoped>
  .document {
    height: 297mm;
    width: 210mm;
    margin: -8px;
    padding: 8px;
    page-break-after: always;
    page-break-inside: avoid;

    break-after: 'page';

    & > div {
      margin-top: 20px;
    }
  }

  #form {
    page-break-inside: avoid;
  }

  #equip {
    page-break-after: avoid;
  }

  .page {
    padding-bottom: 20px;
    page-break-after: always;
  }

  @page {
    margin: 60px 0;
  }

  @page :first {
    margin-top: 0;
  }

  .display-3 {
    font-size: 56px!important;
    font-weight: 400;
    line-height: 1.35!important;
    letter-spacing: -.02em!important;
  }

  .page-spacer {
    margin: 20px 0;
    @media print {
      display: block;
    }
  }

  .bottom-text {
    font-size: 0.8em;
    margin-top: 0.5cm;
  }

  table {
    width: 100%;
  }

  td {
    vertical-align: center;
    text-align: right;

    &:first-child {
      width: 110px;
    }
  }

  input,
  textarea {
    border: 1px solid black;
    width: 100%;
    margin: 4px 0;
    padding: 0 4px;
  }

  textarea {
    resize: none;
  }

  .box {
    border: 1px solid;
  }

  .box_fixed {
    border: 1px solid;
    height: 22px;
  }

  .box_big {
    border: 1px solid;
    height: 100px;
  }
  .monospace {
    font-family: monospace;
    font-size: 0.9em;
  }

  hr {
    margin: 2.5mm 0;
    border-color: #555;
  }

  pre {
    font-size: 0.9em;
    white-space: pre-wrap;
  }

  .header-side {
    max-width: 18rem;
  }

  .client-name {
    font-size: 1.2rem;
  }

  .category-name {
    font-size: 2.2rem;
    // Note: Background colors are normally ignored during print. We tell the browser to print exactly as shown here
    // but the implementation of this feature is non-standard. It may very well break in the future. The only other
    // option I am aware of is to go into the browser settings and specifically allow it.
    background-color: #ffffdd;
    -webkit-print-color-adjust: exact;
  }
  .b-bottom {
    border-bottom: 1px solid;
  }
  .aanmaakdatum {
    position: relative;
    bottom: -15px;
  }
  .text_data {
    color: #1e2cf1;
  }

  .bg-custom-yellow {
    background-color: #fffe7fad !important;
    -webkit-print-color-adjust: exact;
  }

  .max-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .equimentRow hr {
    border-top: 0 none;
    border-right: 0 none;
    border-bottom: 1px solid #dedede;
    border-left: 0 none;
  }
</style>

import {
  getEquipmentItems,
  getEquipmentItem,
  createEquipmentItem,
  updateEquipmentItem,
  deleteEquipmentItem,
  bulkUpdateEquipmentItems
} from "@/api/equipmentItem";
import { createStore } from "vuex";
import { EquipmentItemState } from "../state";
import { EquipmentItem } from "../types";

export const equipmentItemStore = createStore({
  state: {
    equipmentItems: [],
    equipmentItem: {} as any,
    equipmentItemWithAllEquipmentItemLocations: {} as any,
    equipmentItemListTotal: 0,
    equipmentItemListLoading: false
  },
  getters: {
    equipmentItems: (state: EquipmentItemState) => state.equipmentItems,
    equipmentItem: (state: EquipmentItemState) => state.equipmentItem,
    equipmentItemWithAllEquipmentItemLocations: (state: EquipmentItemState) => state.equipmentItemWithAllEquipmentItemLocations,
    equipmentItemListTotal: (state: EquipmentItemState) => state.equipmentItemListTotal,
    equipmentItemListLoading: (state: EquipmentItemState) => state.equipmentItemListLoading
  },
  mutations: {
    setEquipmentItems(state: EquipmentItemState, payload: EquipmentItem[]) {
      state.equipmentItems = payload
    },
    setEquipmentItem(state: EquipmentItemState, payload: EquipmentItem) {
      state.equipmentItem = payload
    },
    setEquipmentItemWithAllEquipmentItemLocations(state: EquipmentItemState, payload: EquipmentItem) {
      state.equipmentItemWithAllEquipmentItemLocations = payload
    },
    clearEquipmentItem(state: EquipmentItemState) {
      state.equipmentItem = {} as any
    },
    deleteEquipmentItemById(state: EquipmentItemState, payload: any) {
      state.equipmentItems = state.equipmentItems.filter((EquipmentItem: any) => {
        return String(EquipmentItem.id) !== String(payload.id)
      })
      state.equipmentItemListTotal = state.equipmentItemListTotal - 1
    },
    setEquipmentItemListTotal(state: EquipmentItemState, payload: number) {
      state.equipmentItemListTotal = payload
    },
    setEquipmentItemListLoading(state: EquipmentItemState, payload: boolean) {
      state.equipmentItemListLoading = payload
    }
  },
  actions: {
    async fetchEquipmentItems({ commit }, pagination) {
      commit('setEquipmentItemListLoading', true)
      const response = await getEquipmentItems(pagination)
      commit('setEquipmentItems', response.data.data)
      commit('setEquipmentItemListTotal', response.data.total)
      commit('setEquipmentItemListLoading', false)
    },
    async fetchEquipmentItem({ commit }, id) {
      if(this.state.equipmentItemListLoading) return

      commit('setEquipmentItemListLoading', true)
      const response = await getEquipmentItem(id)
      commit('setEquipmentItem', response.data.data)
    },
    async createEquipmentItem({ commit }, data) {
      const response = await createEquipmentItem(data)
      commit('setEquipmentItem', response.data.data)
      return response.data.data.id
    },
    async updateEquipmentItem({ commit }, { id, data }) {
      const response = await updateEquipmentItem(id, data)
      commit('setEquipmentItem', response.data.data)
    },
    async bulkUpdateEquipmentItems({ commit }, data) {
      return await bulkUpdateEquipmentItems(data)
    },
    async deleteEquipmentItem({ commit }, id) {
      await deleteEquipmentItem(id)
      commit('deleteEquipmentItemById', { id: id })
    }
  },
})

export default equipmentItemStore;

<template>
  <div>
    <branch-form
      :branch="branch"
      :submit="submit"
      :title="t('branchCreate')"
      :buttonText="t('create')"
      :onInput="() => {}"
      :loading="loading"
    />
  </div>
</template>

<script setup lang="ts">
import BranchForm from './Form.vue'
import Say from '@/utils/Say'
import { defineComponent, computed, ref, onBeforeMount } from 'vue';
import store from '@/store';
import { branchStore } from '@/store/branches'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import sayErrorResponse from '@/mixins/sayErrorResponse'

defineComponent({
  BranchForm
})

const loading= ref(false)
const router = useRouter()
const { t } = useI18n()

const branch = computed(() => {
  return branchStore.getters.branch
})

onBeforeMount(() => {
  branchStore.commit('clearBranch')

  store.commit('app.setPageTitle', t('branchCreate'))
  store.commit('app.setBreadcrumbs', [
    { text: t('branch', 2), routeName: 'BranchesList' },
    { text: t('branchCreate') }
  ])
})

const submit = () => {
  loading.value = true
  branchStore.dispatch('createBranch', branch.value).then((id) => {
    loading.value = false
    Say('success', t('branchCreateSuccess'))
    router.push({ name: 'BranchesEdit', params: { id: id } })
  }).catch((err) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
</script>

<template>
  <div>
    <Template 
      page="create"
    />
  </div>
</template>

<script setup lang="ts">
import Template from './utils/Template.vue'
import { provide, ref } from 'vue'

const job= ref({} as any)

provide('job', job)
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 10px;
}

.client-dialog .client-form {
  .contacts-projects {
    display: none;
  }

  .flex {
    max-width: 100% !important;
    flex-basis: 100%;
  }
}
</style>

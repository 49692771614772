import { fetchRoles, fetchRole, updateRole } from '@/api/roles'
import { Commit } from 'vuex'
import { RolesState } from '../state'

const rolesStore = {
  state: {
    list: [],
    edit: {
      id: null,
      name: '',
      permissions: []
    }
  },
  getters: {
    rolesList: (state: RolesState )=> {
      return state.list
    },
    roleEdit: (state: RolesState )=> {
      // state.edit.permissions = state.edit.permissions.map(String)
      return state.edit
    }
  },
  mutations: {
    'roles.setListTo': (state: RolesState, payload: any) => {
      state.list = payload
    },
    'roles.setEditTo': (state: RolesState, payload: any) => {
      state.edit = payload
    },
    'roles.editPermissionsInput': (state: RolesState, payload: any) => {
      state.edit.permissions = payload
    }
  },
  actions: {
    'roles.fetch': ({ commit }: {commit : Commit}) => {
      return fetchRoles().then((res) => {
        commit('roles.setListTo', res.data)
      })
    },
    'roles.getSingleForEdit': ({ commit }: {commit : Commit}, id: any) => {
      return fetchRole(id).then((role) => {
        commit('roles.setEditTo', role.data)
      })
    },
    'roles.updateRole': ({ commit, state }: { commit: Commit, state: {edit: any}}) => {
      return updateRole(state.edit.id, state.edit.permissions)
    }
  }
}

export default rolesStore

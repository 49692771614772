import axios from "axios";
import { EquipmentItem } from '@/store/types/'
import useBranchSelector from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function getEquipmentItemLocations(filters: any = null) {
  let requestData
  if (typeof filters !== 'undefined') {
    requestData = {
      params: filters
    }
  }

  if(requestData && requestData.params) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }
  return axios.get('/equipment-item-locations', requestData)
}

export function getEquipmentItemLocation(id: number | string) {
  return axios.get('/equipment-item-locations/' + id)
}

export function createEquipmentItemLocation(data: EquipmentItem) {
  return axios.post('/equipment-item-locations', data)
}

export function updateEquipmentItemLocation(id: number | string, data: EquipmentItem) {
  return axios.put('/equipment-item-locations/' + id, data)
}

export function updateEquipmentItemLocationAmount(id: number | string, data: EquipmentItem) {
  return axios.put('/equipment-item-locations/' + id + '/amount', data)
}

export function deleteEquipmentItemLocation(id: number | string) {
  return axios.delete('/equipment-item-locations/' + id)
}

export function getEquipmentAtLocation(locationId: number | string) {
  return axios.get(`/equipment-item-locations/at-location/${locationId}`)
}

export interface Bounds {
  north: number
  south: number
  east: number
  west: number
}

export function getEquipmentPerLocations(
  jobId: number,
  equipmentCategoryId: number,
  equipmentId: number,
  searchBoundery: Bounds | null = null,
  includeCollected: boolean = false,
  monthsBack: number | null = null
) {
  const requestData = {
    params: {
      jobId: jobId,
      equipmentCategoryId: equipmentCategoryId,
      equipmentId: equipmentId,
      searchBoundery,
      includeCollected: includeCollected,
      monthsBack: monthsBack
    }
  }
  return axios.get('/equipment-per-locations', requestData)
}

/**
 * Group the API Operations for easy/combined import
 */
export const equipmentItemLocationAPI = {
  index: getEquipmentItemLocations,
  store: createEquipmentItemLocation,
  getEquipmentAtLocation: getEquipmentAtLocation,
} as const

<template>
  <v-card>
    <v-tabs
      v-model="tab"
      bg-color="primary"
    >
      <v-tab value="calender">{{ $t('calenderView') }}</v-tab>
      <v-tab value="map">{{ $t('mapView') }}</v-tab>
      <v-tab value="search">{{ $t('searchView') }}</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="calender" :transition="false" :reverse-transition="false">
          <calender-view
            v-if="tab === 'calender'"
            ref="calenderView"
          />
        </v-window-item>
        <v-window-item value="map" :transition="false" :reverse-transition="false">
          <map-view
            v-if="tab === 'map'"
            ref="mapView"
          />
        </v-window-item>
        <v-window-item value="search" :transition="false" :reverse-transition="false">
          <search-view
            v-if="tab === 'search'"
            ref="searchView"
          />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import CalenderView from '@/components/Dashboard/CalenderView.vue'
import MapView from '@/components/Dashboard/MapView.vue'
import SearchView from '@/components/Dashboard/SearchView.vue'
import store from '@/store';

const tab = ref(null)

onBeforeMount(() => {
  store.commit('app.setPageTitle', 'Dashboard')
  store.commit('app.setBreadcrumbs', [])
})
</script>

<style lang="scss" scoped>
  .v-card {
    margin: 20px;
  }
  .v-tabs {
    color: #fff !important;
    background: #1E88E5 !important;
    font-weight: 400 !important;
    font-size: 24px !important;
  }
</style>

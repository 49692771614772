<template>
  <v-card flat>
    <v-card-text>
      <h1>Settings be here</h1>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import store from '@/store';
import { onBeforeMount } from 'vue';

onBeforeMount(() => {
  store.commit('app.setPageTitle', 'Settings')
  store.commit('app.setBreadcrumbs', [
    { text: 'Settings' }
  ])
})
</script>

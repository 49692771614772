<template>
  <div>
    <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
    <equipmentItem-form
      :equipmentItem="equipmentItem"
      :submit="submit"
      :title="t('equipmentItemEdit') + ' ' + equipmentItem.name"
      :buttonText="t('edit')"
      :onInput="() => {}"
      :loading="loading"
      v-if="!preLoading"
    />
  </div>
</template>

<script setup lang="ts">
import EquipmentItemForm from './Form.vue'
import Say from '@/utils/Say'
import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import { equipmentItemStore } from '@/store/equipmentItems'
import { useRoute } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'
import router from '@/router'

const { t } = useI18n()
const route = useRoute()

defineComponent({
  EquipmentItemForm
})

const preLoading= ref(true)
const loading= ref(false)

const equipmentItem = computed(() => {
  store.commit('app.setPageTitle', t('equipmentItemEdit') + ' | ' + equipmentItemStore.getters.equipmentItem.equipment.name)
  store.commit('app.setBreadcrumbs', [
    { text: t('inventory', 2), routeName: 'EquipmentItemsList' },
    { text: t('equipmentItemEdit') + ' ' + equipmentItemStore.getters.equipmentItem.equipment.name }
  ])
  return equipmentItemStore.getters.equipmentItem
})
onBeforeMount(() => {
  equipmentItemStore.dispatch('fetchEquipmentItem', route.params.id)
    .then(() => {
      preLoading.value = false
    })
})

const submit = async() => {
  if (loading.value) {
    return
  }
  loading.value = true

  try {
    await equipmentItemStore.dispatch('updateEquipmentItem', {id: equipmentItem.value.id,  data: equipmentItem.value})
    Say('success', t('equipmentItemEditSuccess'))
    router.push({ name: 'EquipmentItemView', params: { id: equipmentItem.value.id } })
  } catch (error) {
    sayErrorResponse(error)
  } finally {
    loading.value = false
  }
}
</script>

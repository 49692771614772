<script setup lang="ts">
import {computed, provide, ref} from 'vue'
import DocumentsIndex from '@/components/UI/Documents/Index.vue'
import {uploadJobFiles, jobFilesIndex, jobFileDestroy, jobFileUpdate, jobFileShow} from '@/api/jobs'
import {useRoute} from 'vue-router'

const route = useRoute()
const jobId = computed(() => {
  return route.params.id
})

provide('apiId', jobId)
provide('apiIdField', 'job_id')

const api  = ref({
  upload: uploadJobFiles,
  index: jobFilesIndex,
  delete: jobFileDestroy,
  update: jobFileUpdate,
  show: jobFileShow
})

// Pass the API to the gallery
provide('api', api)

</script>

<template>
  <div>
    <DocumentsIndex />
  </div>
</template>

<style scoped lang="scss">

</style>

import axios from 'axios'
import router from '../router'
import auth from '@/utils/auth'

export function initInterceptors() {
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if(response.headers.authorization) {
        axios.defaults.headers.common['Authorization'] = response.headers.authorization;
      }

      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const response = error.response

      if (!response) {
        return Promise.reject(error)
      }

      const status = response.status

      if (!status) {
        return Promise.reject(error)
      }

      const data = response.data
      const message = data.message ? data.message : response.statusText

      // If there is a Unauthenticated given
      if (message.indexOf('Unauthenticated') !== -1) {
        if (auth.state.authenticated) {
          auth.logout().then(() => {
            router.replace('/login').then(() => {})
          })
        } else {
          router.replace('/login').then(() => {})
        }
      }

      return Promise.reject(error)
    }
  )
}

export default {
  initInterceptors,
}

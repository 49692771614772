import { archiveClients, unarchiveClients } from '@/api/clients'
import store from '@/store'
import Say from '@/utils/Say'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export const bulkActions = () => {

  const selected= ref([] as any[])
  const status= ref(null as any)
  const route = useRoute()
  const { t } = useI18n()


  const selectedIds = ref()

  watch(selected, () => {
    selectedIds.value = selected.value.map((item: any) => item.id)
  }, { immediate: true, deep: true})

  onMounted(() => {
    status.value = route.params.status
  })
  const toggleArchive = (itemType: any) => {
    return status === 'archived' ? archive(itemType) : unarchive(itemType)
  }
  // The archive/unarchive methods could reutilise some of the code,
  // but due to possible future enhancements, it's best to keep them separate for now
  const archive = (itemType: any) => {
    let apiAction = null as any
    let storeRemoveMutation = ''
    switch (itemType) {
      case 'clients':
        apiAction = archiveClients
        storeRemoveMutation = 'clients.deleteById'
        break
    }
    let confirmAction = confirm(t('confirm'))
    if (confirmAction) {
      apiAction(selectedIds.value)
        .then(() => {
          Say('success', 'Success!')
          for (let id of selectedIds.value) {
            store.commit(storeRemoveMutation, { id })
          }
          selected.value = []
        })
        .catch((err: any) => {
          Say('error', err.response.data)
        })
    }
  }
  const unarchive = (itemType: any) => {
    let apiAction = null as any
    let storeRemoveMutation = '' // We're removing from the store even with unarchiving
    switch (itemType) {
      case 'clients':
        apiAction = unarchiveClients
        storeRemoveMutation = 'clients.deleteById'
        break
    }
    let confirmAction = confirm(t('confirm'))
    if (confirmAction) {
      apiAction(selectedIds.value)
        .then(() => {
          Say('success', 'Success!')
          for (let id of selectedIds.value) {
            store.commit(storeRemoveMutation, { id })
          }
          selected.value = []
        })
        .catch((err: any) => {
          Say('error', err.response.data)
        })
    }
  }

  return {
    selected,
    status,
    selectedIds,
    toggleArchive,
    archive,
    unarchive
  }
}

export default bulkActions

import { fetchJobs, fetchJob, createJob, updateJob, deleteJob, deleteJobFile } from '@/api/jobs'
import { cloneDeep } from 'lodash-es'
import { Commit } from 'vuex'
import { JobState } from '../state'

let emptyJobObject = {
  job_name: '',
  project_name: '',
  client_id: null,
  client: {},
  project_id: null,
  employees: [],
  employee_id: null,
  instructions: null,
  location: null,
  location_va: null,
  files: [],
  bulk_workers: [],
  status: null,
  // client_responsible_1_id: null,
  // client_responsible_2_id: null,
  id: null,
}

const jobStore = {
  state: {
    // TODO: Refactor and remove this. This should be unused now
    list: [],
    listTotal: 0,
    listLoading: false,
    create: cloneDeep(emptyJobObject),
    edit: cloneDeep(emptyJobObject)
  },
  getters: {
    'jobsCreate': (state: JobState) => {
      return state.create
    },
    'jobsEdit': (state: JobState) => {
      return state.edit
    },
    'jobsList': (state: JobState) => {
      return state.list
    },
    'jobsListTotalCount': (state: JobState) => {
      return state.listTotal
    },
    'jobsListLoading': (state: JobState) => {
      return state.listLoading
    }
  },
  mutations: {
    'jobs.setListTo': (state: JobState, payload: any) => {
      state.list = payload
    },
    'jobs.setEditTo': (state: JobState, payload: any) => {
      state.edit = payload
    },
    'jobs.createUpdateInput': (state: JobState, payload: { name: string; value: any }) => {
      if (payload.name.indexOf('.') === -1) {
        (state.create as any)[payload.name] = payload.value
      } else {
        let parts = payload.name.split('.');
        // TODO: get it to work with more than one nested level
        (state.create as any)[parts[0]][parts[1]] = payload.value
      }
    },
    'jobs.createAddFile': (state: JobState, payload: any) => {
      state.create.files.push(payload)
    },
    'jobs.createRemoveFile': (state: JobState, payload: { id: any }) => {
      state.create.files = state.create.files.filter((file: { id: any }) => {
        return file.id !== payload.id
      })
    },
    'jobs.editUpdateInput': (state: JobState, payload: { name: string; value: any }) => {
      if (payload.name.indexOf('.') === -1) {
        (state.edit as any)[payload.name] = payload.value
      } else {
        let parts = payload.name.split('.');
        // TODO: get it to work with more than one nested level
        (state.edit as any)[parts[0]][parts[1]] = payload.value
      }
    },
    'jobs.createClear': (state: JobState) => {
      state.create = cloneDeep(emptyJobObject)
    },
    'jobs.editClear': (state: JobState) => {
      state.edit = cloneDeep(emptyJobObject)
    },
    'jobs.addToList': (state: JobState, payload: any) => {
      state.list.push(payload)
    },
    'jobs.setListTotalCount': (state: JobState, payload: any) => {
      state.listTotal = payload
    },
    'jobs.setListLoadingTo': (state: JobState, payload: any) => {
      state.listLoading = payload
    },
    'jobs.edit': (state: JobState, payload: any) => {
      state.list = state.list.map((job: any) => {
        return job.id === payload.id ? payload.jobData : job
      })
    },
    'jobs.deleteById': (state: JobState, payload: any) => {
      state.list = state.list.filter((job: any) => {
        return job.id !== payload.id
      })
    }
  },
  actions: {
    'jobs.fetch': async ({ commit }: any, pagination: any) => {
      try {
        commit('jobs.setListLoadingTo', true)
        const res: { data: Record<string, unknown> } = await fetchJobs(pagination)
        commit('jobs.setListTotalCount', res.data.total)
        commit('jobs.setListLoadingTo', false)

        return res.data.data
      } catch (err: any) {
        return err.response.data
      }
    },
    'jobs.fetchSingle': async ({ commit, state }: any, id: any) => {
      let res = await fetchJob(id)

      let job = res.data

      return job
    },
    'jobs.fetchSingleForEdit': async ({ commit, state }: any, id: any) => {
      let res = await fetchJob(id)

      let job = res.data

      // Status
      job.status = job.status.name || 'DRAFT'

      commit('jobs.setEditTo', job)
    },
    'jobs.create': ({ commit, state }: { commit : Commit, state: JobState}) => {
      return new Promise((resolve, reject) => {
        let objectCreate = cloneDeep(state.create);
        objectCreate.files = objectCreate.files.map((file: any) => {
          return file.id
        })
        createJob(objectCreate).then((res: Record<string, any>) => {
          let job = Object.assign(state.create, { id: res.data.id })
          commit('jobs.addToList', job)
          commit('jobs.createClear')
          resolve(job.id)
        }).catch((err: any) => {
          reject(err.response.data)
        })
      })
    },
    'jobs.edit': ({ commit, state }: { commit : Commit, state: JobState}) => {
      let objectEdit = cloneDeep(state.edit);

      objectEdit.files = objectEdit.files.map((file: any) => {
        return file.id
      })
      // Ensure the location is the id
      if (objectEdit.location instanceof Object && objectEdit.location.id !== undefined) {
        objectEdit.location = objectEdit.location.id
      }
      // Ensure the Van Amerongen location is the id
      if (objectEdit.location_va instanceof Object && objectEdit.location_va.id !== undefined) {
        objectEdit.location_va = objectEdit.location_va.id
      }
      // Data which is not to be sent
      let toDelete: string[] = [
        'selectedEmployee', 'selectedEmployees', 'selectedResponsible', 'revisions', 'created_by',
        'client', 'employee', 'responsible', 'status_ongoing'
      ]
      for (let i in toDelete) {
        delete objectEdit[toDelete[i] as keyof typeof objectEdit]
      }
      // Return the promise
      return new Promise(function (resolve, reject) {
        updateJob(state.edit.id, objectEdit).then((res: Record<string, any>) => {
          commit('jobs.edit', {
            id: state.edit.id,
            jobData: Object.assign(state.edit, { revisions: res.data.revisions })
          })
          resolve(state.edit)
        }).catch((err: any) => {
          reject(err.response.data)
        })
      })
    },
    'jobs.delete': ({ commit }: any, id: any) => {
      return new Promise((resolve, reject) => {
        deleteJob(id).then((res: Record<string, any>) => {
          commit('jobs.deleteById', { id: id })
          resolve('jobs.deleteById')
        }).catch((err: any) => {
          reject(err.response.data)
        })
      })
    }
  }
}

export default jobStore

import { fetchUsers, fetchUser, createUser, updateUser, deleteUser } from '@/api/users'
import { UsersState } from '../state'
import { Commit } from 'vuex'

// An empty user object which is used to help with avoiding not defined errors and makes cleaner copies
const emptyUserObject = {
  name: '',
  email: '',
  password: '',
  default_branch_id: null as any,
  pincode: '',
  branches: [],
  roles: [],
  id: null as any
}

const usersStore = {
  state: {
    list: [],
    listTotal: 0,
    listLoading: false,
    edit: Object.assign({}, emptyUserObject),
    create: Object.assign({}, emptyUserObject),
  },
  getters: {
    'userCreate': (state: UsersState) => {
      return state.create
    },
    'userEdit': (state: UsersState) => {
      return state.edit
    },
    'usersList': (state: UsersState, rootGetters: any) => {
      let roles = rootGetters.rolesList
      return state.list.map(function (user) {
        user.rolesList = roles.filter((role: any) => {
          return user.roles.indexOf(role.id) !== -1
        }).map((role: any) => {
          return role.name
        }).join(', ')
        return user
      })
    },
    'usersListTotalCount': (state: UsersState) => {
      return state.listTotal
    },
    'usersListLoading': (state: UsersState) => {
      return state.listLoading
    }
  },
  mutations: {
    'users.setListTo': (state: UsersState, payload: any) => {
      state.list = payload
    },
    'users.deleteById': (state: UsersState, payload: any) => {
      state.list = state.list.filter((user) => {
        return String(user.id) !== String(payload.id)
      })
    },
    'users.addToList': (state: UsersState, payload: any) => {
      state.list.push(payload)
    },
    'users.setListTotalCount': (state: UsersState, payload: any) => {
      state.listTotal = payload
    },
    'users.setListLoadingTo': (state: UsersState, payload: any) => {
      state.listLoading = payload
    },
    'users.createUpdateInput': (state: UsersState, payload: any) => {
      state.create[payload.name] = payload.value
    },
    'users.createClear': (state: UsersState) => {
      state.create = Object.assign({}, emptyUserObject)
    },
    'users.editClear': (state: UsersState) => {
      state.edit = Object.assign({}, emptyUserObject)
    },
    'users.edit': (state: UsersState, payload: any) => {
      state.list = state.list.map((user) => {
        if (String(user.id) === String(payload.id)) {
          return payload.userData
        } else {
          return user
        }
      })
    },
    'users.setEditTo': (state: UsersState, payload: any) => {
      state.edit = Object.assign(state.edit, payload)
    },
    'users.editUpdateInput': (state: UsersState, payload: any) => {
      state.edit[payload.name] = payload.value
    }
  },
  actions: {
    'users.fetch': ({ commit }: { commit : Commit}, pagination: number) => {
      commit('users.setListLoadingTo', true)
      return new Promise(function (resolve, reject) {
        fetchUsers(pagination).then((res) => {
          let users = res.data.data
          commit('users.setListTo', users)
          commit('users.setListTotalCount', res.data.total)
          commit('users.setListLoadingTo', false)
        })
      })
    },
    'users.getSingleForEdit': ({ commit, state } : { commit : Commit, state: UsersState}, id: any) => {
      commit('users.setListLoadingTo', true)
      return new Promise((resolve, reject) => {
        if (state.list.length !== 0) {
          state.list.filter((user) => {
            if (user.id === parseInt(id)) {
              commit('users.setEditTo', user)
              commit('users.setListLoadingTo', false)
            }
          })
        } else {
          fetchUser(id).then((res) => {
            let user = res.data.data
            commit('users.setEditTo', user)
          })
        }
      })
    },
    'users.create': ({ commit, state } : { commit : Commit, state: UsersState}) => {
      return new Promise((resolve, reject) => {
        createUser(state.create).then((res) => {
          let user = Object.assign(state.create, { id: res.data.data.id })
          commit('users.addToList', user)
          commit('users.createClear')
          resolve(user.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'users.edit': ({ commit, state } : { commit : Commit, state: UsersState}) => {
      return new Promise((resolve, reject) => {
        updateUser(state.edit.id, state.edit).then((res) => {
          commit('users.edit', { id: state.edit.id, userData: state.edit })
          resolve(state.edit)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'users.delete': ({ commit }: { commit : Commit}, id: any) => {
      return new Promise((resolve, reject) => {
        deleteUser(id).then((res) => {
          commit('users.deleteById', { id: id })
          resolve('users.deleteById')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    }
  }
}

export default usersStore

import { fetchEmployees, fetchEmployee, createEmployee, updateEmployee, restoreEmployee, deleteEmployee } from '@/api/employees'
import { Commit } from 'vuex'
import { EmployeeState } from '../state'

let emptyEmployeeObject = {
  name: '',
  date_start: null,
  date_end: null,
  company: null,
  phone: '',
  id : null
}

const employeesStore = {
  state: {
    list: [],
    listTotal: 0,
    listLoading: false,
    edit: Object.assign({ }, emptyEmployeeObject),
    create: Object.assign({ }, emptyEmployeeObject)
  },
  getters: {
    'employeesList': (state: EmployeeState) => {
      return state.list
    },
    'employeeCreate': (state: EmployeeState) => {
      return state.create
    },
    'employeeEdit': (state: EmployeeState) => {
      return state.edit
    },
    'employeesListTotalCount': (state: EmployeeState) => {
      return state.listTotal
    },
    'employeesListLoading': (state: EmployeeState) => {
      return state.listLoading
    }
  },
  mutations: {
    'employees.setListTo': (state: EmployeeState, payload: any) => {
      state.list = payload
    },
    'employees.setListTotalCount': (state: EmployeeState, payload: any) => {
      state.listTotal = payload
    },
    'employees.setListLoadingTo': (state: EmployeeState, payload: any) => {
      state.listLoading = payload
    },
    'employees.setEditTo': (state: EmployeeState, payload: any) => {
      payload.flag_can_project_leader = payload.flag_can_project_leader === 1
      payload.flag_can_work_order_responsible = payload.flag_can_work_order_responsible === 1
      state.edit = payload
    },
    'employees.editUpdateInput': (state: EmployeeState, payload: any) => {
      (state.edit as any)[payload.name] = payload.value
    },
    'employees.createUpdateInput': (state: EmployeeState, payload: any) => {
      (state.create as any)[payload.name] = payload.value
    },
    'employees.edit': (state: EmployeeState, payload: any) => {
      state.list = state.list.map((employee: any) => {
        if (String(employee.id) === String(payload.id)) {
          return payload.employeeData
        } else {
          return employee
        }
      })
    },
    'employees.deleteById': (state: EmployeeState, payload: any) => {
      state.list = state.list.filter((employee: any) => {
        return String(employee.id) !== String(payload.id)
      })
    },
    'employees.createClear': (state: EmployeeState) => {
      state.create = Object.assign({}, emptyEmployeeObject)
    },
    'employees.editClear': (state: EmployeeState) => {
      state.edit = Object.assign({}, emptyEmployeeObject)
    },
    'employees.addToList': (state: EmployeeState, payload: any) => {
      state.list.push(payload)
    }
  },
  actions: {
    'employees.fetch': ({ commit }: {commit: Commit}, pagination: any) => {
      commit('employees.setListLoadingTo', true)
      return fetchEmployees(pagination).then((res) => {
        let employees = res.data.data
        commit('employees.setListTo', employees)
        commit('employees.setListTotalCount', res.data.total)
        commit('employees.setListLoadingTo', false)
      })
    },
    'employees.fetchSingleForEdit': ({ commit }: {commit: Commit}, id: any) => {
      return fetchEmployee(id).then((res) => {
        let employee = res.data
        commit('employees.setEditTo', employee)
      })
    },
    'employees.create': ({ commit, state }: { commit: Commit, state: EmployeeState}) => {
      return new Promise((resolve, reject) => {
        createEmployee(state.create).then((res) => {
          let employee = Object.assign(state.create, { id: res.data.id })
          commit('employees.addToList', employee)
          commit('employees.createClear')
          resolve(employee.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'employees.edit': ({ commit, state }: { commit: Commit, state: EmployeeState}) => {
      return new Promise(function (resolve, reject) {
        updateEmployee(state.edit.id, state.edit).then(() => {
          commit('employees.edit', { id: state.edit.id, employeeData: state.edit })
          resolve(state.edit)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'employees.delete': ({ commit }: { commit: Commit }, id: any) => {
      return new Promise((resolve, reject) => {
        deleteEmployee(id).then((res) => {
          commit('employees.deleteById', { id: id })
          resolve('employees.deleteById')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'employees.restore': ({ commit }: { commit: Commit }, id: number) => {
      return new Promise((resolve, reject) => {
        restoreEmployee(id).then((res) => {
          // This looks a bit silly, but since we are restoring the employee
          // they are removed from the list of currently displayed (archived)
          // employees.
          commit('employees.deleteById', { id: id })
          resolve('employees.deleteById')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    }
  }
}

export default employeesStore

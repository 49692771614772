import i18n from '@/i18n'

const { t }: any = i18n.global

export default {
  name: [
    (v: string) => !!v || t('validationNameRequired'),
    (v: string) => v.length < 128 || t('validationNameShorter')
  ],
  email: [
    (v: string) => (v.length === 0 || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || t('validationEmailValid')
  ],
  password: [
    (v: any) => v.length !== 0 || t('validationPasswordRequired'),
    (v: any) => v.length >= 6 || t('validationPasswordMinLength') // 'Password must be at least 6 characters long'
  ],
  pincode: [
    (v: any) => (v.length === 0 || v.length === 4) || t('validationPincodeExactlyFour'),
    (v: any) => (v.length === 0 || /^\d+$/.test(v)) || t('validationPincodeNumbersOnly')
  ],
  required: [
    (v: any) => !!v || 'dit ingediend is vereist'
  ]
}

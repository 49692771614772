import i18n from '@/i18n'

const { t }: any = i18n.global

export default {
  name: [
    (v: any) => !!v || t('validationNameRequired'),
    (v: any) => (v === null || v === undefined || v.length < 128) || t('validationNameRequired')
  ],
  postcode: [
    (v: any) => !!v || t('validationPostcodeRequired'),
    (v: any) => (v === null || v === undefined ) || /^([0-9]{4} ?[a-zA-Z]{2})+$/.test(v) || t('validationPostcodeRequired')
  ],
  contact: {
    email: [
      (v: any) => (v === null || v === undefined || (typeof v === 'string' && v.length === 0) || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || t('validationEmailValid')
    ]
  }
}

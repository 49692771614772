<template>
    <v-container fluid>
      <v-row wrap>
        <v-col sm="12">
          <router-view/>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
  export default { }
  export { default as BranchesList } from './List'
  export { default as BranchesCreate } from './Create'
  export { default as BranchesEdit } from './Edit'
</script>
  
import axios from 'axios'
import  useBranchSelector  from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function fetchSearchResults (filters : any = null) {
  let requestData
  if (typeof filters !== 'undefined') {
    requestData = {
      params: filters
    }
  }

  if(requestData) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }
  return axios.get('/search', requestData)
}

<template>
  <v-form v-on:submit.prevent="submit()">
    <v-row row wrap>
      <v-col sm="6" >
        <v-card>
          <v-card-text>
            <h2>{{ $t('permission', 2) }}</h2>
            <v-checkbox
              :label="`${permission.name}`"
              v-model="permissions"
              :value="permission.id"
              hide-details
              :key="permission.id"
              v-for="permission in permissionsList"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading">{{ $t('save') }}</v-btn>
            <v-progress-circular indeterminate color="orange-darken-1" v-show="loading"/>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import Say from '@/utils/Say';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse';

const loading = ref(false);
const store = useStore();
const { t } = useI18n();
const route = useRoute();

const permissions = computed({
  get: () => store.getters.roleEdit.permissions,
  set: value => {
    store.commit('roles.editPermissionsInput', value);
  },
});

const permissionsList = computed(() => {
  return store.state.permissions.list;
});

onBeforeMount(async () => {
  await store.dispatch('permissions.fetch');
  await store.dispatch('roles.getSingleForEdit', route.params.id);

  store.commit('app.setPageTitle', 'Edit ' + store.getters.roleEdit.name);
  store.commit('app.setBreadcrumbs', [
    { text: t('role', 2), routeName: 'Roles' },
    { text: t('edit') + ' ' + store.getters.roleEdit.name }
  ]);
});

const submit = () => {
  loading.value = true;

  store.dispatch('roles.updateRole')
    .then(() => {
      // Display success
      loading.value = false;
      Say('success', t('rolePermissionsSaved'));
    })
    .catch(err => {
      loading.value = false;
      sayErrorResponse(err)
    });
};
</script>

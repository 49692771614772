// Exposes some methods that prevent navigating away from a form with unsaved changes
//
// inBrowser
// should be set on component mount(), inside this.$nextTick
//
// inVueRouter
// should be added to beforeRouteLeave(), a method which is available to vue-router

import { isEqual } from 'lodash-es'

export default {
  inBrowser (originalData: any, newData: any) {
    window.onbeforeunload = () => !isEqual({...originalData.value}, {...newData.value}) || null
  },

  inVueRouter (originalData: any, newData: any, next: any) {
    if (isEqual({...originalData}, {...newData})) {
      window.onbeforeunload = null
      next()
    } else {
      if (window.confirm('Do you really want to leave? You have unsaved changes!')) {
        // Naturally unbind the confirmation when leaving the site altogether
        window.onbeforeunload = null
        next()
      } else {
        next(false)
      }
    }
  }
}

<template>
  <v-card flat class="pa-4" style="overflow: visible !important;z-index:1!important;">
      <v-row >
        <v-col sm="6" >
          <!-- Date: Date start -->
          <Datepicker
            id="date_start"
            :placeholder="$t('dateStart')"
            :model-value="dateStart"
            :name="'date_start'"
            :maxDate="dateEnd ? dateEnd: ''"
            @update-dates-and-times="update"
            @keyup.tab="focusNextInput('date_start')"
          />
        </v-col>
        <!-- Time: Time start -->
        <v-col sm="6" >
          <Timepicker
            id="time_start"
            :placeholder="$t('timeStart')"
            :model-value="timeStart"
            :name="'time_start'"
            @update-dates-and-times="update"
            @keyup.tab="focusNextInput('time_start')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" >
          <!-- Date: date end -->
          <Datepicker
            v-if="!workOrder.is_for_equipment_movement"
            id="date_end"
            :placeholder="$t('dateEnd')"
            :model-value="dateEnd"
            :name="'date_end'"
            @update-dates-and-times="update"
            :minDate="dateStart ? dateStart: ''"
            @keyup.tab="focusNextInput('date_end')"
          />
        </v-col>
        <!-- Time: Time end -->
        <v-col sm="6" >
          <Timepicker
            v-if="!workOrder.is_for_equipment_movement"
            id="time_end"
            :placeholder="$t('timeEnd')"
            :model-value="timeEnd"
            :name="'time_end'"
            @update-dates-and-times="update"
          />
        </v-col>
      </v-row>
  </v-card>
</template>

<script setup lang="ts">
import Datepicker from '@/components/UI/Datepicker.vue';
import Timepicker from '@/components/UI/Timepicker.vue';
import emitArray from '@/utils/emit';
import '@vuepic/vue-datepicker/dist/main.css'
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { watch } from 'vue';
import { onBeforeMount, defineComponent, ref } from 'vue';
import { focusNextInput } from '@/utils/functions';

const emit = defineEmits(emitArray.value)
defineComponent({
  components: {
    Datepicker,
    Timepicker
  }
})

const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  },
  originalWorkOrder: {
    type: Object as any,
    reqired: false
  }
})

const dateStart = ref(null as any)
const dateEnd = ref(null as any)
const timeStart = ref(null as any)
const timeEnd = ref(null as any)
const originalWorkOrder = ref(null as any)

onBeforeMount(() => {
  originalWorkOrder.value = cloneDeep(props.workOrder)
  initializeInputs()
})

watch(props.workOrder, () => {
  dateStart.value = props.workOrder.date_start ? moment(props.workOrder.date_start) : null
  timeStart.value = props.workOrder.time_start ? formatTime(props.workOrder.time_start) : null
  dateEnd.value = props.workOrder.date_end ? moment(props.workOrder.date_end) : null
  timeEnd.value = props.workOrder.time_end ? formatTime(props.workOrder.time_end) : null
}, {  deep: true })


const initializeInputs = () => {
  dateStart.value = originalWorkOrder.value.date_start ? moment(originalWorkOrder.value.date_start) : null
  timeStart.value = originalWorkOrder.value.time_start ? formatTime(originalWorkOrder.value.time_start) : null
  dateEnd.value = originalWorkOrder.value.date_end ? moment(originalWorkOrder.value.date_end) : null
  timeEnd.value = originalWorkOrder.value.time_end ? formatTime(originalWorkOrder.value.time_end) : null
}

const formatTime = (time: any) => {
  if (!time) {
    return ;
  }

  const hour = parseInt(time.split(':')[0]);
  const minute = parseInt(time.split(':')[1]);
  const seconds = parseInt(time.split(':')[2]) || 0;

  return {
    hours: hour,
    minutes: minute,
    seconds: seconds
  }
}

const update = (data: any) => {
  emit('change', data)
}

defineExpose({
  initializeInputs
});
</script>


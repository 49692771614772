import { fetchRentalSuppliers, fetchRentalSupplier, createRentalSupplier, updateRentalSupplier, deleteRentalSupplier  } from '@/api/rentalSuppliers'
import { Commit } from 'vuex'
import { RentalSuppliersState } from '../state'

// An empty rentalSupplier object which is used to help with avoiding not defined errors and makes cleaner copies
const emptyRentalSupplierObject = {
  name: '',
  address: '',
  postcode: '',
  city: '',
  phone: '',
  email: '',
  website: '',
  location_id: 0,
  id: null as any
}

const rentalSuppliersStore = {
  state: {
    list: [],
    listTotal: 0,
    listLoading: false,
    edit: Object.assign({}, emptyRentalSupplierObject),
    create: Object.assign({}, emptyRentalSupplierObject),
  },
  getters: {
    'rentalSuppliersList': (state: RentalSuppliersState) => {
      return state.list
    },
    'rentalSupplierCreate': (state: RentalSuppliersState) => {
      return state.create
    },
    'rentalSupplierEdit': (state: RentalSuppliersState) => {
      return state.edit
    },
    'rentalSuppliersListTotalCount': (state: RentalSuppliersState) => {
      return state.listTotal
    },
    'rentalSuppliersListLoading': (state: RentalSuppliersState) => {
      return state.listLoading
    }
  },
  mutations: {
    'rentalSuppliers.setListTo': (state: RentalSuppliersState, payload: any) => {
      state.list = payload
    },
    'rentalSuppliers.setListTotalCount': (state: RentalSuppliersState, payload: any) => {
      state.listTotal = payload
    },
    'rentalSuppliers.setListLoadingTo': (state: RentalSuppliersState, payload: any) => {
      state.listLoading = payload
    },
    'rentalSuppliers.deleteById': (state: RentalSuppliersState, payload: any) => {
      state.list = state.list.filter((rentalSupplier) => {
        return String(rentalSupplier.id) !== String(payload.id)
      })
    },
    'rentalSuppliers.addToList': (state: RentalSuppliersState, payload: any) => {
      state.list.push(payload)
    },
    'rentalSuppliers.createUpdateInput': (state: RentalSuppliersState, payload: any) => {
      state.create[payload.name] = payload.value
    },
    'rentalSuppliers.createClear': (state: RentalSuppliersState) => {
      state.create = Object.assign({}, emptyRentalSupplierObject)
    },
    'rentalSuppliers.editClear': (state: RentalSuppliersState) => {
      state.edit = Object.assign({}, emptyRentalSupplierObject)
    },
    'rentalSuppliers.edit': (state: RentalSuppliersState, payload: any) => {
      state.list = state.list.map((rentalSupplier) => {
        if (String(rentalSupplier.id) === String(payload.id)) {
          return payload.rentalSupplierData
        } else {
          return rentalSupplier
        }
      })
    },
    'rentalSuppliers.setEditTo': (state: RentalSuppliersState, payload: any) => {
      state.edit = Object.assign(state.edit, payload)
    },
    'rentalSuppliers.editUpdateInput': (state: RentalSuppliersState, payload: any) => {
      state.edit[payload.name] = payload.value
    }
  },
  actions: {
    'rentalSuppliers.fetch': ({ commit }: { commit: Commit }, pagination: any) => {
      commit('rentalSuppliers.setListLoadingTo', true)
      fetchRentalSuppliers().then((res) => {
        let rentalSuppliers = res.data.data
        commit('rentalSuppliers.setListTo', rentalSuppliers)
        commit('rentalSuppliers.setListTotalCount', res.data.total)
      })
    },
    'rentalSuppliers.getSingleForEdit': ({ commit, state } : { commit : Commit, state: RentalSuppliersState}, id: any) => {
      commit('rentalSuppliers.setListLoadingTo', true)
      return new Promise((resolve, reject) => {
        if (state.list.length !== 0) {
          state.list.filter((rentalSupplier) => {
            if (rentalSupplier.id === parseInt(id)) {
              commit('rentalSuppliers.setEditTo', rentalSupplier)
              commit('rentalSuppliers.setListLoadingTo', false)
            }
          })
        } else {
          fetchRentalSupplier(id).then((res) => {
            let rentalSupplier = res.data.data
            commit('rentalSuppliers.setEditTo', rentalSupplier)
          })
        }
      })
    },
    'rentalSuppliers.create': ({ commit, state } : { commit : Commit, state: RentalSuppliersState}) => {
      return new Promise((resolve, reject) => {
        createRentalSupplier(state.create).then((res) => {
          let rentalSupplier = Object.assign(state.create, { id: res.data.data.id })
          commit('rentalSuppliers.addToList', rentalSupplier)
          commit('rentalSuppliers.createClear')
          resolve(rentalSupplier.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'rentalSuppliers.edit': ({ commit, state } : { commit : Commit, state: RentalSuppliersState}) => {
      return new Promise((resolve, reject) => {
        updateRentalSupplier(state.edit.id, state.edit).then((res) => {
          commit('rentalSuppliers.edit', { id: state.edit.id, rentalSupplierData: state.edit })
          resolve(state.edit)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'rentalSuppliers.delete': ({ commit }: { commit : Commit}, id: any) => {
      return new Promise((resolve, reject) => {
        deleteRentalSupplier(id).then((res) => {
          commit('rentalSuppliers.deleteById', { id: id })
          resolve('rentalSuppliers.deleteById')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    }
  }
}

export default rentalSuppliersStore

<template>
  <v-container fluid>
    <v-data-table-server v-if="searchResults" :headers="headers" :items="searchResults" :loading="loading"
      :items-per-page="25" :items-length="searchResultsTotal" v-model:page="pagination.page"
      @update:options="getSearchResults" @update:sort-by="sortEvent">
      <template v-slot:headers="{ isSorted, getSortIcon, toggleSort }">
        <tr>
          <template v-for="header in headers" :key="header.key">
            <th class="custom_header_table" :style="{ width: `${header.width}px !important` }">
              <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                <span class="cursor-pointer">{{ header.title }}</span>
                <template v-if="isSorted(header)">
                  <v-icon :icon="getSortIcon(header)" size="small"></v-icon>
                </template>
              </span>
            </th>
          </template>
        </tr>
      </template>

      <template v-slot:item="{ item }: {item: any}">
        <tr v-if="type === 'client'">
          <!-- Clients -->
          <td @click="show(type, item)">
            <!-- clients name -->
            {{ item.name }}
          </td>
          <td>
            <!-- Postcode -->
            {{ item.postcode }}
          </td>
          <td>
            <!-- City -->
            {{ item.city }}
          </td>
          <td>
            <!-- Address -->
            {{ item.address }}
          </td>

          <td>
            <!-- Actions -->
            <v-btn size="small" @click="$router.push({ name: 'WorkOrdersList', query: { clients_name: item.name } })">
              <v-icon>mdi-car-estate</v-icon>
              {{ t('workOrder', 2) }}
            </v-btn>

            <v-btn size="small" class="ml-2"
              @click="$router.push({ name: 'JobsList', query: { clients_name: item.name } })">
              <v-icon>mdi-traffic-cone</v-icon>
              {{ t('job', 2) }}
            </v-btn>
          </td>
        </tr>
        <tr v-else-if="type === 'job'">
          <!-- Job -->
          <td @click="show(type, item)" class="pointer">
            <!-- Job name -->
            {{ item.job_name }}
          </td>
          <td @click="show(type, item)" class="pointer">
            <!-- Job description -->
            {{ item.job_description }}
          </td>
          <td @click="show(type, item)" class="pointer">
            <!-- Instructions -->
            {{ item.instructions }}
          </td>
          <td>
            <!-- Location -->
            {{ item.city ? `${item.city}, ` : '' }} {{ item.postcode ? `${item.postcode}, ` : '' }} {{
              item.street ? `${item.street}` : '' }} {{ item.street_number ? ` - ${item.street_number}` : ''}}
          </td>

          <td @click="show('clientFromJobAndLocation', item)" class="pointer">
            <!-- Client -->
            {{ item.client_name }}
          </td>
          <td>
            <!-- Status name -->
            {{ item.status.name }}
          </td>
        </tr>
        <tr v-else-if="type === 'workOrder'">
          <!-- WorkOrders -->
          <td @click="show(type, item)" class="pointer">
            <!-- Work order number -->
            {{ item.standard_name_prefix }}
          </td>
          <td @click="show(type, item)" class="pointer">
            <!-- Project name -->
            {{ item.project_name }}
          </td>
          <td @click="show(type, item)" class="pointer">
            <!-- Instructions -->
            {{ item.instructions }}
          </td>
          <td>
            <!-- Location -->
            {{ item.city ? `${item.city}, ` : '' }} {{ item.postcode ? `${item.postcode}, ` : '' }} {{
              item.street ? `${item.street}` : '' }} {{ item.street_number ? ` - ${item.street_number}` : ''}}
          </td>
          <td @click="show('clientFromJobAndLocation', item)" class="pointer">
            <!-- Client -->
            {{ item.client_name }}
          </td>
          <td>
            <!-- Status name -->
            {{ item.status.name }}
          </td>
        </tr>
        <tr v-else-if="type === 'location'">
          <!-- Locations -->
          <td>
            <!-- City -->
            {{ item.city }}
          </td>
          <td>
            <!-- Postcode -->
            {{ item.postcode }}
          </td>
          <td>
            <!-- Address -->
            {{ item.street }} {{ item.street_number ? `- ${item.street_number}` : '' }}
          </td>
          <td @click="show('client', item)" class="pointer">
            <!-- Client -->
            {{ item.name }}
          </td>
          <td>
            <!-- Actions -->
            <v-btn size="small" @click="$router.push({ name: 'WorkOrdersList', query: { location: item.street } })">
              <v-icon>mdi-car-estate</v-icon>
              {{ t('workOrder', 2) }}
            </v-btn>

            <v-btn size="small" class="ml-2"
              @click="$router.push({ name: 'JobsList', query: { location: item.street } })">
              <v-icon>mdi-traffic-cone</v-icon>
              {{ t('job', 2) }}
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <div>{{ t('searchResultsNoResults') }}</div>
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
;
import { ref, watch, onBeforeMount, defineComponent, computed } from 'vue';
import datatableOptions from '@/mixins/datatableOptions';
import { debounce } from 'lodash-es';

const { pagination } = datatableOptions()


const route = useRoute()
const router = useRouter()
const store = useStore()
const { t } = useI18n()

const headers = computed(() => store.getters.searchHeaders)
const type = computed(() => store.getters.searchType)
const searchResults = computed(() => store.getters.searchResults)
const searchResultsTotal = computed(() => store.getters.searchResultsTotal)
const loading = computed(() => store.getters.searchLoading)

const isValidSearch = computed<boolean>(() => {
  return !!(route.query.keyword && route.query.searchFor)
})

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('search'))
  store.commit('app.setBreadcrumbs', [
    { text: t('search') }
  ])
})

const getSearchResults = debounce(() => {
  if (!isValidSearch.value) {
    store.commit('search.setSearchTo', [])
    return
  }
  store.dispatch('search.fetch', Object.assign(
    {},
    pagination.value,
    {
      keyword: route.query.keyword,
      class: route.query.searchFor
    }
  ))
}, 200)

watch(route, () => {
  pagination.value.sortBy = null
  pagination.value.descending = null

  /** NEED it to check everytime the keyword or search folder changes, oterwhise we don't fetch the new params */
  getSearchResults()
}, { immediate: true })

watch(pagination, () => {
  getSearchResults()
}, { immediate: false, deep: true })

const sortEvent = (value: any) => {
  if (value.length === 0) {
    return
  }

  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false
}

const routeNameList = ref<{ [key: string]: string }>({
  "client": "ClientsEdit",
  "job": "JobsView",
  "workOrder": "WorkOrdersView",
  "location": "location",
})

const show = (routeName: string, item: Record<string, any>) => {
  if (routeName === 'clientFromJobAndLocation') {
    router.push({ name: routeNameList.value['client'], params: { id: Number(item.client_id) } })
  } else {
    router.push({ name: routeNameList.value[routeName], params: { id: Number(item.id) } })
  }
}
</script>

<template>
  <v-navigation-drawer fixed app v-model="drawer.open" v-if="auth.check()"
    :style="[drawer.open ? {'width': '300px !important'} : {'width': '0px !important'}]"
  >
    <v-list-item class="logo-wrapper" height="100">
      <img src="../assets/logo.svg" />
    </v-list-item>

    <v-list open-strategy="multiple" density="compact" nav >
      <template v-for="(item, i) in items" :key="item.text">
        {{ item.disabled }}
        <v-list-group
          v-if="item.children"
          :value="item"
        >
        <!-- :active-class="activeRoute === item.children.find(routeName) ? 'primary' : ''" -->
          <template v-slot:activator="{ props }">
            <v-list-item
              class="py-0 mb-0"
              v-bind="props"
              @click.stop
              :color="isOnChildrenRoute(item) ? 'primary' : ''"
            >
              <template v-slot:prepend>
                <v-icon>{{ item.icon }}</v-icon>
              </template>
              <div class="custom-title" >{{ item.text }}</div>
            </v-list-item>
          </template>
          <v-list-item
            class="py-0 mb-0"
            v-for="(child, childIndex) in item.children"
            :key="childIndex"
            :to="{ name: child.routeName, params: child.params }"
            :ripple="true"
            v-bind:disabled="child.disabled || !child.routeName"
            color="primary"
          >

            <span class="custom-title" >{{ child.text }}</span>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else-if="!item.children && item.key !== 'search'"
          class="py-0 mb-0"
          :to="{ name: item.routeName, params: item.params }"
          :ripple="true"
          :prepend-icon="item.icon"
          :exact="item.exact !== false"
          v-bind:disabled="item.disabled || !item.routeName"
          color="primary"
        >
          <span class="custom-title" >{{ item.text }}</span>
        </v-list-item>

        <v-list-item
          v-if="item.key === 'search'"
          class="py-0 mb-0"
          :ripple="true"
          :prepend-icon="item.icon"
          color="primary"
        >
          <v-text-field
            type="text"
            variant="underlined"
            placeholder="Search"
            class="py-0 mb-0"
            @keydown="$emit('open-search', true)"
          />
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue'
import { useAuth } from 'vue-auth3';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import emitArray from '@/utils/emit'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const auth = useAuth()
const emit = defineEmits(emitArray.value)

type ChildType = {
  text: string;
  routeName: string;
  params?: { job_id?: any };
  disabled?: boolean;
};

defineProps({
  drawer: {
    type: Object,
    default() {
      return {
        open: true,
      }
    },
  },
})

const getNavigationItems = () => {
  const showEquipmentItem = import.meta.env.VITE_SHOW_INVENTORY === 'true';

  const baseItems = [
    {
      icon: 'mdi-home',
      text: t('dashboard'),
      routeName: 'Dashboard',
      exact: true,
      // initialize variable to remove the errors
      disabled: null,
      params: {},
    },
    {
      icon: 'mdi-traffic-cone',
      text: t('job', 2),
      routeName: 'JobsList',
      children: [
        { text: t('allJobs'), routeName: 'JobsList' },
        { text: t('jobCreate'), routeName: 'JobsCreate' },
      ] as ChildType[],
    },
    {
      icon: 'mdi-car-estate',
      text: t('workOrder', 2),
      routeName: 'WorkOrdersList',
      children: [
        { text: t('allWorkOrders'), routeName: 'WorkOrdersList' },
        {
          text: t('workOrderCreate'),
          routeName: 'WorkOrdersCreate',
          params: { job_id: null as any },
        },
      ] as ChildType[],
    },
    {
      icon: 'mdi-briefcase-variant',
      text: t('client', 2),
      routeName: 'ClientsList',
      children: [
        { text: t('allClients'), routeName: 'ClientsList' },
        { text: t('clientCreate'), routeName: 'ClientsCreate' },
        { text: t('clientsArchived'), routeName: 'ClientsArchivedList' },
      ] as ChildType[],
    },
    {
      icon: 'mdi-account-multiple',
      routeName: 'EmployeesList',
      text: t('employee', 2),
      children: [
        { text: t('allEmployees'), routeName: 'EmployeesList' },
        { text: t('allArchivedEmployees'), routeName: 'EmployeesArchivedList' },
        { text: t('employeeCreate'), routeName: 'EmployeesCreate' },
        {
          text: t('weekOverview'),
          routeName: 'EmployeesWeekOverview',
        },
        {
          text: t('leaveAndHolidays'),
          routeName: 'EmployeesTimeoff',
        },
      ] as ChildType[],
    },
    {
      icon: 'mdi-car-hatchback',
      routeName: 'EquipmentList',
      text: t('equipment', 2),
      children: [
        { text: t('allEquipments'), routeName: 'EquipmentList' },
        { text: t('equipmentCreate'), routeName: 'EquipmentCreate' },
      ] as ChildType[],
    },
    {
      icon: 'mdi-face-man',
      routeName: 'UsersList',
      text: t('user', 2),
      children: [
        { text: t('allUsers'), routeName: 'UsersList' },
        { text: t('userCreate'), routeName: 'UsersCreate' },
        { text: t('roleManage'), routeName: 'RolesList' },
      ] as ChildType[],
    },
    {
      icon: 'mdi-home-variant',
      text: t('branch', 2),
      routeName: 'BranchesList',
      children: [
        { text: t('allBranches'), routeName: 'BranchesList' },
        { text: t('branchCreate'), routeName: 'BranchesCreate' },
      ] as ChildType[],
    },
  ];

  baseItems.push({
    icon: 'mdi-book-open-page-variant',
    text: t('rentalSuppliers', 2),
    routeName: 'RentalSuppliersList',
    children: [
      { text: t('allRentalSuppliers'), routeName: 'RentalSuppliersList' },
      { text: t('rentalSupplierCreate'), routeName: 'RentalSuppliersCreate' },
    ] as ChildType[],
  });

  baseItems.push({
    icon: 'mdi-book-open-page-variant',
    text: t('inventory', 2),
    routeName: 'EquipmentItemsList',
    children: [
      { text: t('allEquipmentItems'), routeName: 'EquipmentItemsList' },
      { text: t('equipmentItemCreate'), routeName: 'EquipmentItemsCreate' },
    ] as ChildType[],
  });

  baseItems.push({
      icon: 'mdi-magnify',
      key: 'search',
    });

  return baseItems;
};

const activeRoute = ref(null as any)
const items = ref(getNavigationItems())

watch(route ,() => {
  setActiveRoute()
})

onMounted(() => {
  setActiveRoute()
})

const setActiveRoute = () => {
  items.value.forEach((item: Record<string, any>) => {
    const itemRouteName = item.routeName

    if (router.currentRoute.value.matched.length == 0) {
      activeRoute.value = null
      return
    }

    const currentRouteName = route.name

    if (itemRouteName === currentRouteName) {
      activeRoute.value = currentRouteName
      return
    }
    if (
      itemRouteName === route.name
    ) {
      activeRoute.value = route.name
    } else if(item.children) {
      item.children.forEach((child: Record<string, undefined>) => {
        if(child.routeName === currentRouteName) {
          activeRoute.value = route.name
        }
      })
    }
  })
}
const isOnChildrenRoute = (item: any) => {
  let result = false
  item.children.forEach((r: Record<string, undefined>) => {
    if(r.routeName === activeRoute.value) {
      result =  true;
    }
  })
  return result;
}
</script>

<style scoped lang="scss">
.text--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.logo-wrapper {
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.logo-wrapper {
  img {
    width: 38%;
  }
}
.custom-title {
  text-transform: capitalize;
  font-weight: 700 !important;
}
.list__group--active {
  background: #f5f5f5;
}
</style>

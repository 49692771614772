import axios from 'axios'

export function fetchRoles () {
  return axios.get('/roles')
}

export function fetchRole (id: number|string) {
  return axios.get('/roles/' + id)
}

export function updateRole (id: number|string, permissions: any) {
  return axios.put('/roles/' + id, {
    permissions: permissions
  })
}

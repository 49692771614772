<template>
  <v-card flat>
    <v-card-text>
      <v-row wrap>
        <v-col sm="12">
          <v-file-input multiple clearable flat variant="underlined" @change="onFileChange" />
          <v-table class="table table-files">
            <thead>
              <tr>
                <th v-if="files.length"></th>
                <th>{{ $t('filename') }} / {{ $t('comment', 2) }}</th>
                <th>{{ $t('size') }}</th>
                <th v-if="files.length"></th>
              </tr>
              <tr v-if="!files.length">
                <th colspan="4">{{ $t('noFilesUploaded' )}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in files" :key="file.id">
                <td>
                  <img :src="fromStorage(file.href)" class="preview" v-if="file.image" />
                  <div v-else>
                    <v-avatar class="teal">
                      <span class="white--text headline">{{ file.extension }}</span>
                    </v-avatar>
                  </div>
                </td>
                <td>
                  {{ file.filename }}<br/>
                  <span :class="`file-comments ${file.comments ? 'file-comments-edit' : 'file-comments-add'}`" @click="editFile = file; editFileComments = file.comments; showFileCommentModal = true;">
                    <span v-if="!file.comments">{{ $t('addComment') }}</span>
                    <span v-else>{{ file.comments }}</span>
                    <v-icon>mdi-pencil</v-icon>
                  </span>
                </td>
                <td>{{formatBytes(file.size, 2) }}</td>
                <td>
                  <a :href="fromStorage(file.href)" class="btn btn--flat btn--icon" color="blue-darken-1" target="_blank" rel="noopener">
                    <v-icon>mdi-file-eye</v-icon>
                  </a>
                  <v-btn v-on:click="confirm($t('fileRemoveConfirm')) && removeFile(file.id)" icon flat size="small" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="showFileCommentModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h2>
            <a :href="fromStorage(editFile.href)" class="btn btn--flat btn--icon" color="blue-darken-1" target="_blank" rel="noopener">
              <v-icon>mdi-file-eye</v-icon>
            </a>
            {{ editFile.filename }}
          </h2>
        </v-card-title>
        <v-card-text>
          <!-- Description -->
          <v-text-field
            :label="$t('comment', 1)"
            v-model="editFileComments"
            textarea
            rows="2"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.stop="showFileCommentModal=false">{{ $t('cancel') }}</v-btn>
          <v-btn depressed color="orange-darken-2" @click.stop="showFileCommentModal = false; saveFileComments()">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script setup lang="ts">
import { uploadWorkOrderFiles, updateWorkOrderFileComments } from '@/api/workOrders'
import store from '@/store';
import Say from '@/utils/Say'
import emitArray from '@/utils/emit'
import { onMounted, ref, nextTick } from 'vue'
import { fromStorage, formatBytes } from '@/utils/filters';
import { useI18n } from 'vue-i18n';
import { cloneDeep } from 'lodash-es';
import { onBeforeMount } from 'vue';
import sayErrorResponse from '@/mixins/sayErrorResponse';

const { t }  = useI18n()
const emit = defineEmits(emitArray.value)
const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  }
})

const files = ref([] as any[])
const editFile= ref({} as any)
const editFileComments= ref(null)
const showFileCommentModal= ref(false)
const originalFiles = ref(null as any)

onBeforeMount(() => {
  originalFiles.value = cloneDeep(props.workOrder.files)
})

onMounted(() => {
  initializeInputs()
})

const initializeInputs = () => {
  files.value = originalFiles.value ? originalFiles.value : []
}

const confirm = (message: any) => {
  return message
}

// When files are selected, attempt their upload
const onFileChange = (event: any) => {
  if (event.target.files.length !== 0) {
    var form = new FormData()
    if (props.workOrder) {
      form.append('work_order_id', props.workOrder.id)
    }
    for (let file of event.target.files) {
      // Check for allowed file types and sizes, don't send garbage to the server
      form.append('name[]', file.name)
      form.append('file[]', file)
    }
    uploadWorkOrderFiles(form).then((res) => {
      if (res.data.uploadedFiles.length !== 0) {
        let successMessage = 'Files ' + res.data.uploadedFiles.map((file: any) => {
          // Add the files in here too
          addFile(file)

          // Return the filename for further use
          return file.filename
        }).join(', ') + ' were uploaded.'
        Say('success', successMessage)
      }
      if (res.data.errors.length !== 0) {
        Say('error', res.data.errors.join(' '))
      }
    }).catch((err) => {
      if (err.code && err.code === 'ERR_NETWORK') {
        Say('error', 'There was a network error while trying to upload a file. The file might be too large, or the server might be too busy to handle an upload right now.')
      } else {
        sayErrorResponse(err)
      }
    })
  }
}
const addFile = (file: any) => {
  // $store.commit('workOrders.editAddFile', file)
  files.value.push(file)
  emit('change', { files: files })
}
const removeFile = async(id: any)  => {
  try {
    await store.dispatch('workOrders.deleteFile', id)
    files.value = files.value.filter(file => file.id !== id)
    Say('success', t('fileRemoveSuccess'))
  } catch (err: any) {
    sayErrorResponse(err)
  }
}
const saveFileComments = () => {
  const file = files.value.find((file: any) => file.id === editFile.value.id)
  file.comments = editFileComments
  nextTick(() => {
    updateWorkOrderFileComments(file.id, file.comments)
  })
}

defineExpose({
  initializeInputs
});
</script>

<style scoped lang="scss">
.table-files {
  th {
    font-size: 12px !important;
  }
  tbody {
    tr {
      td {
        padding: 10px 0;
        &:first-child {
          width: 100px;
        }
        .preview {
          height: 48px;
          width: auto;
        }
      }
    }
  }
}
.list.list--two-line .list__tile__content {
  height: 72px;
}
.file-comments {
  line-height: 20px;
  color: #999;
  &:hover {
    cursor: pointer;
    .icon {
      display: inline;
    }
  }
  &.file-comments-add {
    font-style: italic;
  }
  .icon {
    font-size: 16px;
    display: none;
  }
}
</style>

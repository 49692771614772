<template>
  <div>
    <VueDatePicker
      :uid="id"
      :placeholder="placeholder"
      :name="name"
      :min-date="minDate ? new Date(minDate) : ''"
      :max-date="maxDate ? new Date(maxDate) : ''"
      :enable-time-picker="false"
      :week-start="1"
      :auto-apply="autoApply"
      :no-today="noToday"
      :disabled="disabled"
      :required="required"
      :readonly="readonly"
      :position="position"
      :teleport="teleport"
      :model-value="model"
      @update:model-value="updateModel"
      :text-input="textInputOptions"
      :format="format"
      locale="nl"
      timezone="Europe/Amsterdam"
    />
    <slot name="below" />
  </div>
</template>

<script setup lang="ts">
import emitArray from '@/utils/emit';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';
import { PropType ,defineComponent, ref, onMounted } from 'vue';

const emit = defineEmits(emitArray.value)

defineComponent({
  VueDatePicker,
})

const model = ref()

const removeUnexpectedCharacters = (value: any) => {
  return value.replaceAll(/[\/\-:.,]/g, '')
}

const textInputOptions = {
  format: (date: any): any => {
    const clearDate = removeUnexpectedCharacters(date)

    const length = clearDate.length;
    const currentDate = new Date();

    if(length <= 2 && clearDate < currentDate.getDate()) {
      return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, clearDate)
    } else if (length <= 2 && clearDate > currentDate.getDate()) {
      return new Date(currentDate.getFullYear(), currentDate.getMonth(), clearDate)
    } else if (length > 2 && length <= 4) {
      const day = clearDate.substring(0, 2);
      const month = clearDate.substring(2, 4);

      return new Date(currentDate.getFullYear(), month - 1 , day)
    } else if (length > 4 && length == 6) {
      const day = clearDate.substring(0, 2);
      const month = clearDate.substring(2, 4);
      const year = clearDate.substring(4, 6);

      return new Date(`${month}/${day}/${year}`)
    } else if (length > 4 && length == 8) {
      const day = clearDate.substring(0, 2);
      const month = parseInt(clearDate.substring(2, 4));
      const year = clearDate.substring(4, 8);

      return new Date(`${month}/${day}/${year}`)
    }
  }
};

const props = defineProps({
  placeholder: {
    type: String,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  id: {
    type: String,
    required: false
  },
  minDate: {
    type:  [Object as any, String as any],
    required: false,
  },
  maxDate:  {
    type:  [Object as any, String as any],
    required: false,
  },
  autoApply: {
    type: Boolean,
    required: false,
    default: true
  },
  noToday: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false
  },
  required: {
    type: Boolean,
    required: false
  },
  position: {
    type: String as PropType<'left' | 'center' | 'right'>,
    required: false,
    default: 'center'
  },
  teleport: {
    type: Boolean,
    required: false,
    default: false
  },

  modelValue: {
    required: false,
  }
})

onMounted(() => {
  model.value = props.modelValue
})

const updateModel = (newValue: any) => {
  model.value = newValue;
  emit('update-dates-and-times', { name: props.name, value: newValue})
  emit('update:value-datetime', moment(newValue).format('YYYY-MM-DD'))
}

const format = () => {
  if (!model.value) {
    return ;
  }

  const day = (new Date(model.value).getDate()).toString().padStart(2, "0");
  const month = (new Date(model.value).getMonth() + 1).toString().padStart(2, "0");
  const year = new Date(model.value).getFullYear();

  return `${day}-${month}-${year}`;
}
</script>

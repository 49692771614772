import { fetchPermissions } from '@/api/permissions'
import { Commit } from 'vuex'
import { PermissionsState } from '../state'

const permissionsStore = {
  state: {
    list: []
  },
  mutations: {
    'permissions.setListTo': (state: PermissionsState, payload: any) => {
      state.list = payload
    }
  },
  actions: {
    'permissions.fetch': ({ commit }: { commit: Commit }) => {
      fetchPermissions().then((res) => {
        let permissions = res.data.map((permission: any) => {
          return {
            id: permission.id,
            name: permission.name
          }
        })
        commit('permissions.setListTo', permissions)
      })
    }
  }
}

export default permissionsStore

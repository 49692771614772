import axios from 'axios'
import  useBranchSelector  from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function uploadWorkOrderFiles (filesForm: any) {
  return axios.post('/work-orders/file', filesForm)
}

export function fetchWorkOrders (data: Record<string, any>) {
  let requestData
  if (typeof data !== 'undefined') {
    requestData = {
      params: data
    }
  }
  if(requestData) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }

  return axios.get('/work-orders', requestData)
}

export function fetchActiveWorkOrders (params: any) {
  return axios.get('/work-orders/active', { params })
}

export function fetchWorkOrder (id: number|string) {
  return axios.get('/work-orders/' + id)
}

export function createWorkOrder (data: Record<string, any>) {
  return axios.post('/work-orders', data)
}

export function updateWorkOrder (id: number|string, data: Record<string, any>) {
  return axios.put('/work-orders/' + id, data)
}

export function deleteWorkOrder (id: number|string) {
  return axios.delete('/work-orders/' + id)
}

export function deleteWorkOrderFile (id: number|string) {
  return axios.delete('/work-orders/files/' + id)
}

export function updateWorkOrderFileComments (id: number|string, comments: any) {
  return axios.post('/work-orders/files/' + id + '/comments', {
    comments: comments
  })
}

export function fetchWorkOrderNotes (workOrderId: string|number|any) {
  return axios.get('/work-orders/' + workOrderId + '/notes')
}

export function fetchWorkOrderHours (workOrderId: string|number|any) {
  return axios.get(`/work-orders/${workOrderId}/hours`)
}

export function addWorkOrderHours (workOrderId: string|number|any, data: Record<string, any>) {
  return axios.post(`/work-orders/${workOrderId}/hours`, data)
}

export function updateWorkOrderHours (workOrderId: string|number|any, entryId: number|string, data: Record<string, any>) {
  return axios.put(`/work-orders/${workOrderId}/hours/${entryId}`, data)
}

export function removeWorkOrderHours (workOrderId: string|number|any, entryId: number|string) {
  return axios.delete(`/work-orders/${workOrderId}/hours/${entryId}`)
}

export function saveWorkOrderEquipment (workOrderId: string|number|any, data: Record<string, any>) {
  return axios.post(`/work-orders/${workOrderId}/suggested-equipment`, { data })
}

export function duplicateWorkOrder (workOrderId: string|number|any) {
  return axios.post(`/work-orders/${workOrderId}/duplicate`)
}

export function approveWorkOrder (id: number|string) {
  return axios.put('/work-orders/' + id + '/status', {
    newStatus: 'APPROVED'
  })
}

export function linkWorkOrderToOtherJob (work_order_id: number|string, job_id: number|string) {
  return axios.put('/work-orders/' + work_order_id + '/link-to-other-job', {
    jobId: job_id
  })
}

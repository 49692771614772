<template>
  <div>
    <equipmentItemLocation-form
      :equipment-item="equipmentItemStore.getters.equipmentItem"
      :equipmentItemLocation="equipmentItemLocation"
      :submit="submit" :title="t('equipmentItemLocationCreate')"
      :buttonText="t('create')" :onInput="() => { }" :loading="loading" />
  </div>
</template>

<script setup lang="ts">
import EquipmentItemLocationForm from './Form.vue'
import Say from '@/utils/Say'
import { defineComponent, computed, ref, onBeforeMount } from 'vue';
import store from '@/store';
import { equipmentItemStore } from '@/store/equipmentItems'
import { equipmentItemLocationStore } from '@/store/equipmentItemLocations'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import sayErrorResponse from '@/mixins/sayErrorResponse';

defineComponent({
  EquipmentItemLocationForm,
})

const loading = ref(false)
const router = useRouter()
const { t } = useI18n()

const equipmentItemLocation = computed(() => {
  let equipmentItemLocation = equipmentItemLocationStore.getters.equipmentItemLocation
  equipmentItemLocation.toVaLocation = true
  return equipmentItemLocation
})

onBeforeMount(() => {
  equipmentItemLocationStore.commit('clearEquipmentItemLocation')

  store.commit('app.setPageTitle', t('equipmentItemLocationCreate'))

  const equipmentItemId = router.currentRoute.value.query.equipment_item_id

  equipmentItemStore.dispatch('fetchEquipmentItem', equipmentItemId).then(() => {
    const equipmentName = equipmentItemStore.getters.equipmentItem.equipment.name

    if (equipmentItemId) {
      store.commit('app.setBreadcrumbs', [
        { text: t('equipmentItem', 2), routeName: 'EquipmentItemsList' },
        { text: equipmentName, routeName: 'EquipmentItemView', routeParams: { id: router.currentRoute.value.query.equipment_item_id } },
        { text: t('equipmentItemLocationCreate') }
      ])
    } else {
      store.commit('app.setBreadcrumbs', [
        { text: t('equipmentItem', 2), routeName: 'EquipmentItemsList' },
        { text: t('equipmentItemLocationCreate') }
      ])
    }
  })

})

const submit = async () => {
  if (loading.value) {
    return
  }
  loading.value = true

  let data = {
    ...equipmentItemLocation.value
  }

  if(data.toLocationType === 'vaLocation') {
    data.location_id = data.va_location_id
  } else if(data.toLocationType === 'rentalSuppliersLocation') {
    data.location_id = data.rental_suppliers_location_id
  }
  delete data.va_location_id
  delete data.rental_suppliers_location_id
  delete data.toLocationType

  try {
    await equipmentItemLocationStore.dispatch('createEquipmentItemLocation', data)

    const equipmentItem = equipmentItemStore.getters.equipmentItem
    await equipmentItemStore.dispatch('updateEquipmentItem', {id: equipmentItemLocation.value.equipment_item_id,  data: equipmentItem})

    Say('success', t('equipmentItemLocationCreateSuccess'))

    await router.push({name: 'EquipmentItemView', params: {id: equipmentItemLocation.value.equipment_item_id}})
  } catch (error) {
    sayErrorResponse(error)
  } finally {
    loading.value = false
  }
}

</script>
@/store/equipmentItemLocations

<template>
  <div class="mb-6">
    <router-link :to="{ name: 'WorkOrdersCreate', params: { job_id: jobId } }">
      <v-btn
        size="small"
        color="primary"
        class="mr-1 first show-icon"
      >
        {{ t('workOrderCreate') }}
      </v-btn>
    </router-link>
  </div>
  <WorkOrdersList :jobId="Number(jobId)"></WorkOrdersList>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { default as WorkOrdersList } from '@/components/WorkOrders/List.vue';
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const jobId = inject('jobId', 0)
</script>

<style scoped lang="scss">

</style>

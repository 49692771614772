<template>
  <div>
    <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
    <branch-form
      :branch="branch"
      :submit="submit"
      :title="t('branchEdit') + ' ' + branch.name"
      :buttonText="t('edit')"
      :onInput="() => {}"
      :loading="loading"
      v-if="!preLoading"
    />
  </div>
</template>

<script setup lang="ts">
import BranchForm from './Form.vue'
import Say from '@/utils/Say'
import { defineComponent, ref, computed, onBeforeUnmount, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import { branchStore } from '@/store/branches'
import { useRoute } from 'vue-router';
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { t } = useI18n()
const route = useRoute()

defineComponent({
  BranchForm
})

const preLoading= ref(true)
const loading= ref(false)

const branch = computed(() => {
  store.commit('app.setPageTitle', 'Edit ' + branchStore.getters.branch.name)
  store.commit('app.setBreadcrumbs', [
    { text: t('branch', 2), routeName: 'BranchesList' },
    { text: t('branchEdit') + ' ' + branchStore.getters.branch.name }
  ])
  return branchStore.getters.branch
})
onBeforeMount(() => {
  branchStore.dispatch('fetchBranch', route.params.id)
    .then(() => {
      preLoading.value = false
    })
})
const submit = () => {
  loading.value = true
  branchStore.dispatch('updateBranch', {id: branch.value.id,  data: branch.value}
  ).then(() => {
    loading.value = false
    Say('success', t('branchEditSuccess'))
  }).catch((err: any) => {
    loading.value = false
    sayErrorResponse(err)
  })
}
</script>

<template>
  <div>
    <Template 
      page="edit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import store from '@/store'
import Template from './utils/Template.vue'
import { provide } from 'vue'

const job = computed({
  get() {
    return store.getters.jobsEdit
  },
  set(value: any) {
    store.commit('jobs.edit', value)
  }
})

provide('job', job)
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 10px;
}

.client-dialog .client-form {
  .contacts-projects {
    display: none;
  }

  .flex {
    max-width: 100% !important;
    flex-basis: 100%;
  }
}
</style>

import { ref } from "vue";

const branchSelected = ref(null);

const useBranchSelector = () => {
  return {
    branchSelected
  };
}

export default useBranchSelector;



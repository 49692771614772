<template>
  <v-row wrap>
    <v-col sm="6">
      <!-- Address -->
      <v-text-field 
        :label="$t('search')" 
        v-model="searchLocation"
        @keyup="handleSearchInput()"
        hide-details
      ></v-text-field>
      <div v-if="autocompleteResults" id="autocompleteResults">
        <div v-if="autocompleteResults" v-for="result in autocompleteResults" class="autocompleteResult"
          @click="getGeocodeLocation(result.placePrediction.text.text)">
          {{ result.placePrediction.text.text }}
        </div>
      </div>
    </v-col>
    <v-col sm="6">
      <div class="map-info">
        <div class="py-1">
          {{ t('mapOnlyShowsProjectLocations') }}
        </div>
        <v-row>
          <v-col sm="4">
            <v-row>
              <v-col sm="1">
                <svg class="pt-1" xmlns="http://www.w3.org/2000/svg" width="14px" viewBox="0 0 26 37" style="display: block; overflow: visible;">
                  <g fill="none" fill-rule="evenodd" style="pointer-events: auto;">
                    <path d="M13 0C5.8175 0 0 5.77328 0 12.9181C0 20.5733 5.59 23.444 9.55499 30.0784C12.09 34.3207 11.3425 37 13 37C14.7225 37 13.975 34.2569 16.445 30.1422C20.085 23.8586 26 20.6052 26 12.9181C26 5.77328 20.1825 0 13 0Z" fill="#1E88E5"></path>
                    <path fill="#1E88E5" d="M13.0167 35C12.7836 35 12.7171 34.9346 12.3176 33.725C11.9848 32.6789 11.4854 31.0769 10.1873 29.1154C8.92233 27.1866 7.59085 25.6173 6.32594 24.1135C3.36339 20.5174 1 17.7057 1 12.6385C1.03329 6.19808 6.39251 1 13.0167 1C19.6408 1 25 6.23078 25 12.6385C25 17.7057 22.6699 20.55 19.6741 24.1462C18.4425 25.65 17.1443 27.2193 15.8793 29.1154C14.6144 31.0442 14.0818 32.6135 13.749 33.6596C13.3495 34.9346 13.2497 35 13.0167 35Z"></path>
                  </g>
                </svg>
              </v-col>
              <v-col sm="10" class="d-flex align-center">
                {{ t('allEquipmentAtLocation') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="4">
            <v-row>
              <v-col sm="1">
                <svg class="pt-1" xmlns="http://www.w3.org/2000/svg" width="14px" viewBox="0 0 26 37" style="display: block; overflow: visible;">
                  <g fill="none" fill-rule="evenodd" style="pointer-events: auto;">
                    <path d="M13 0C5.8175 0 0 5.77328 0 12.9181C0 20.5733 5.59 23.444 9.55499 30.0784C12.09 34.3207 11.3425 37 13 37C14.7225 37 13.975 34.2569 16.445 30.1422C20.085 23.8586 26 20.6052 26 12.9181C26 5.77328 20.1825 0 13 0Z" fill="#f57c00"></path>
                    <path fill="#f57c00" d="M13.0167 35C12.7836 35 12.7171 34.9346 12.3176 33.725C11.9848 32.6789 11.4854 31.0769 10.1873 29.1154C8.92233 27.1866 7.59085 25.6173 6.32594 24.1135C3.36339 20.5174 1 17.7057 1 12.6385C1.03329 6.19808 6.39251 1 13.0167 1C19.6408 1 25 6.23078 25 12.6385C25 17.7057 22.6699 20.55 19.6741 24.1462C18.4425 25.65 17.1443 27.2193 15.8793 29.1154C14.6144 31.0442 14.0818 32.6135 13.749 33.6596C13.3495 34.9346 13.2497 35 13.0167 35Z"></path>
                  </g>
                </svg>
              </v-col>
              <v-col sm="10" class="d-flex align-center">
                {{ t('equipmentPartiallyCollected') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="4">
            <v-row>
              <v-col sm="1">
                <svg class="pt-1" xmlns="http://www.w3.org/2000/svg" width="14px" viewBox="0 0 26 37" style="display: block; overflow: visible;">
                  <g fill="none" fill-rule="evenodd" style="pointer-events: auto;">
                    <path d="M13 0C5.8175 0 0 5.77328 0 12.9181C0 20.5733 5.59 23.444 9.55499 30.0784C12.09 34.3207 11.3425 37 13 37C14.7225 37 13.975 34.2569 16.445 30.1422C20.085 23.8586 26 20.6052 26 12.9181C26 5.77328 20.1825 0 13 0Z" fill="#F2C82C"></path>
                    <path fill="#F2C82C" d="M13.0167 35C12.7836 35 12.7171 34.9346 12.3176 33.725C11.9848 32.6789 11.4854 31.0769 10.1873 29.1154C8.92233 27.1866 7.59085 25.6173 6.32594 24.1135C3.36339 20.5174 1 17.7057 1 12.6385C1.03329 6.19808 6.39251 1 13.0167 1C19.6408 1 25 6.23078 25 12.6385C25 17.7057 22.6699 20.55 19.6741 24.1462C18.4425 25.65 17.1443 27.2193 15.8793 29.1154C14.6144 31.0442 14.0818 32.6135 13.749 33.6596C13.3495 34.9346 13.2497 35 13.0167 35Z"></path>
                  </g>
                </svg>
              </v-col>
              <v-col sm="10" class="d-flex align-center">
                {{ t('allEquipmentCollected') }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
  <v-row wrap>
    <v-col sm="3">
      <!-- Distance in km -->
      <v-select 
        :label="$t('areaInMeters')" 
        v-model="distance" 
        :items="[
          {label: '500 x 500', value: 0.25},
          {label: '1.000 x 1.000', value: 0.5},
          {label: '2.000 x 2.000', value: 1.0},
          {label: '5.000 x 5.000', value: 2.5},
          {label: '10.000 x 10.000', value: 5.0}
        ]"
        item-title="label" 
        item-value="value"
        variant="underlined"
        @update:modelValue="updateMap()"
      /> 
    </v-col>
    <v-col sm="3" class="pr-6">
      <!-- Date -->
      <v-select
        :label="$t('amountOfMonthsBack')"
        v-model="monthsBack"
        :items="[
          {label: 1 + ' ' + t('month'), value: 1},
          {label: 3 + ' ' + t('months'), value: 3},
          {label: 6 + ' ' + t('months'), value: 6},
          {label: 12 + ' ' + t('months'), value: 12}
        ]"
        item-title="label" 
        item-value="value"
        variant="underlined"
        @update:modelValue="updateMap()"
      />
    </v-col>
    <v-col sm="3">
      <!-- Category -->
      <v-autocomplete
        :items="categories"
        v-model="equipmentCategoryId"
        :label="$t('category')"
        item-value="id"
        item-title="name"
        variant="underlined"
        clearable
        @update:modelValue="updateMap()"
      ></v-autocomplete>
    </v-col>
    <v-col sm="3">
      <v-autocomplete
        :items="equipment"
        v-model="equipmentId"
        :label="$t('equipment')"
        item-value="id"
        item-title="name"
        variant="underlined"
        clearable
        @update:modelValue="updateMap()"
      ></v-autocomplete>
    </v-col>
  </v-row>
  <div id="map"></div>
</template>

<script lang="ts" setup>
import { onMounted, computed, ref } from 'vue';
import { debounce } from 'lodash';
import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { autocompleteLocation, geocodeLocation } from '@/api/locations'
import { Bounds, getEquipmentPerLocations } from '@/api/equipmentItemLocation'
import sayErrorResponse from '@/mixins/sayErrorResponse';
import store from '@/store'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const searchLocation = ref(null as String | null)
const searchLastLocation = ref(null as String | null)
const equipmentCategoryId: any = ref(null as String | null)
const equipmentId: any = ref(null as String | null)

const distance = ref(0.25 as number)
const monthsBack = ref(1 as number)

const autocompleteResults = ref(null as any)

const latitude = ref(null as any)
const longitude = ref(null as any)

const searchBoundery = computed<Bounds>(() => {
  // https://en.wikipedia.org/wiki/Longitude
  // Longitude is a coordinate that specifies the east–west position
  // Latitude is a coordinate that specifies the north-south position
  const latitudeDistance = distance.value * 0.009
  const LongitudeDistance = distance.value * 0.014
  return {
      north: latitude.value + latitudeDistance,
      south: latitude.value - latitudeDistance,
      east: longitude.value + LongitudeDistance,
      west: longitude.value - LongitudeDistance,
    }
})

let markerCluster: any = null

const categories = computed(() => {
  return store.getters.equipmentCategories
})
const equipment = computed(() => {
  return store.getters.equipmentList
})

// get locations from getEquipmentPerLocations response
let locations = ref<{ id: number; name: string }[]>([]);

const setMapMarkers = async () => {
  // Remove the previous locations
  if (locations) {
    locations.value.length = 0
  }

  // Remove the previous markers if they exist
  if (mapMarkers) {
    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].map = null;
    }
    mapMarkers = []
  }

  // Clear cluster
  if (markerCluster) {
    markerCluster.clearMarkers()
  }

  if(distance.value === 0.25) {
    map.setZoom(16.5)
  } else if(distance.value === 0.5) {
    map.setZoom(15.5)
  } else if(distance.value === 1.0) {
    map.setZoom(14.5)
  } else if(distance.value === 2.5) {
    map.setZoom(13.2)
  } else if(distance.value === 5) {
    map.setZoom(12.2)
  }

  map.setCenter({ lat: latitude.value, lng: longitude.value })

  setSimpleMapMarker({ lat: latitude.value, lng: longitude.value })

  // also get collected equipment per location
  const includeCollected = true
  
  getEquipmentPerLocations(0, equipmentCategoryId.value, equipmentId.value, searchBoundery.value, includeCollected, monthsBack.value).then((response) => {
    Object.keys(response.data.data).forEach((locationId: any) => {
      const equipmentPerLocation = response.data.data[locationId]

      // check location coordinates
      if (!equipmentPerLocation.location_lat || !equipmentPerLocation.location_long) {
        return
      }

      locations.value.push({
        id: locationId,
        name: equipmentPerLocation.location
      })

      let hasNonCollectedEquipment = false
      let hasCollectedEquipment = false

      const equipment = equipmentPerLocation.equipment.reduce((acc: string, equipment: any) => {
        let link = ' --- '
        if (equipment.job_id && equipment.job_id > 0) {
          link = `<a href="/jobs/view/${equipment.job_id}">${equipment.job_name}</a>`
        }
        let collected_at = ''
        if (equipment.collected_at) {
          collected_at = formatDateTime(equipment.collected_at)
          hasCollectedEquipment = true
        } else {
          hasNonCollectedEquipment = true
        }
        const eqName = (equipment.unique_feature ? `${equipment.name} | ${equipment.unique_feature}` : equipment.name ) + (equipment.rental_supplier_id ? ` (${equipment.rental_supplier_name})` : '')
        return acc + `<tr><td>${eqName}</td><td>${equipment.count}</td><td>${collected_at}</td><td>${link}</td><tr>`
      }, '')

      const totalCount = equipmentPerLocation.equipment.reduce((acc: string, equipment: any) => {
        return acc + equipment.count
      }, 0)

      const style = 'table {width:100%;border-spacing:0;border-collapse:collapse;margin-top:15px;margin-bottom:15px;} th {text-align:left;padding:5px;} td {padding:5px;border:1px solid #ddd;}'
      let headers = '<th>' + t('equipment') + '</th><th>' + t('quantity') + '</th><th>' + t('collectedAt') + '</th><th>' + t('project') + '</th>'

      setMapMarker(
        {
          lat: parseFloat(equipmentPerLocation.location_lat),
          lng: parseFloat(equipmentPerLocation.location_long)
        },
        equipmentPerLocation.location,
        `<div style='min-width:300px;'>
          <b style='font-size:15px;'>${equipmentPerLocation.location}</b>
          <style>${style}</style>
          <table><tr>${headers}</tr>${equipment}</table>
        </div>`,
        locationId,
        totalCount,
        hasCollectedEquipment,
        hasNonCollectedEquipment,
      ).then(() => {
        // render clusters
        markerCluster.render();
      })
    })
  })

  boxRectange.setOptions({
    strokeColor: "#f57c00",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#f57c00",
    fillOpacity: 0.15,
    map,
    bounds: searchBoundery.value,
  });
}

const getAutocompleteLocation = async () => {

  if (searchLocation.value && searchLocation.value !== searchLastLocation.value) {
    // don't redo sme search over and over
    searchLastLocation.value = searchLocation.value
    try {
      // Call API to autocomplete location
      const response = await autocompleteLocation({
        address: searchLocation.value + ', Nederland'
      });

      autocompleteResults.value = response.data.suggestions;
    } catch (err: any) {
      sayErrorResponse(err)
    }
  }
};

const getGeocodeLocation = async (dataToGeocode: any) => {
  searchLocation.value = dataToGeocode
  searchLastLocation.value = dataToGeocode
  autocompleteResults.value = null

  if (dataToGeocode) {
    try {

      let response = null

      response = await geocodeLocation({
        dataToGeocode: dataToGeocode,
        dataType: 'address',
        format: true
      });

      latitude.value = response.data.latitude
      longitude.value = response.data.longitude

      // get nearby locations with equipment
      setMapMarkers()

    } catch (err: any) {
      sayErrorResponse(err)
    }
  }
};

// Define a debounced version of the getAutocompleteLocation function
const debouncedGetAutocompleteLocation = debounce(getAutocompleteLocation, 500);

// Replace the @keyup event handler with the debounced function
const handleSearchInput = () => {
  debouncedGetAutocompleteLocation();
}

const updateMap = () => {
  if (latitude.value && longitude.value && distance.value) {
    setMapMarkers()
  }
}

const handleClickOutside = (event: MouseEvent) => {
  const autocompleteResultsDiv = document.getElementById('autocompleteResults')
  // Check if the click target is outside the autocompleteResultsDiv
  if (autocompleteResultsDiv && !autocompleteResultsDiv.contains(event.target as Node)) {
    // Set autocompleteResults to null
    autocompleteResults.value = null
    // Set last serach location to null
    searchLastLocation.value = null
  }
}

onMounted(() => {
  document.body.addEventListener('click', handleClickOutside)
  store.dispatch('equipment.fetchCategories')
  store.dispatch('equipment.fetch')
  googleMapsInit()
})

//
//  Google Maps
//

let map: any = null
let mapBoundery: any = null
let infoWindow: any = null
let boxRectange: any = null
let mapMarkers = Array()

const language = () => {
  switch (import.meta.env.VITE_APP_I18N_LOCALE) {
    case 'en':
      return 'en-GB'
    case 'nl':
      return 'nl-NL'
    default:
      return 'en-GB'
  }
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  language: language(),
});

const googleMapsInit = async () => {
  const { Map, InfoWindow, Rectangle } = await loader.importLibrary("maps")
  const { LatLngBounds } = await loader.importLibrary("core")

  map = new Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 51.6340706, lng: 5.2135066 },
    zoom: 8,
    mapId: import.meta.env.VITE_GOOGLE_MAPS_MAP_ID,
  });

  mapBoundery = new LatLngBounds();
  infoWindow = new InfoWindow();
  boxRectange = new Rectangle();
  markerCluster = new MarkerClusterer({ map })
}
const setSimpleMapMarker = async (latLng: any) => {
  const { AdvancedMarkerElement } = await loader.importLibrary('marker')

  // Create a new marker using GoogleMapsMarker
  const marker = new AdvancedMarkerElement({
    position: latLng,
    map,
  });

  // Store a reference to the new marker in the mapMarkers array
  mapMarkers.push(marker)
}
const setMapMarker = async (latLng: any, title: string, info: string, locationId: number, count: number, hasCollectedEquipment: boolean, hasNonCollectedEquipment: boolean) => {
  const { AdvancedMarkerElement, PinElement } = await loader.importLibrary('marker')

  const glyph = count == 0 ? "" : count.toString()
  let scale = 1.0
  let background = ""
  if(hasCollectedEquipment && hasNonCollectedEquipment) {
    background = "#f57c00" // #f57c00
  } else if(hasCollectedEquipment) {
    background = "#F2C82C" // #F2C82C
  } else if(hasNonCollectedEquipment) {
    background = "#1E88E5"
  }

  const pin = new PinElement({
    scale: scale,
    glyph: glyph,
    glyphColor: "black",
    background: background,
    borderColor: background
  });

  const textElement = pin.element.querySelector('text');
  if (textElement) {
    textElement.setAttribute('style', 'font-size: 12px');
  }

  // Create a new marker using GoogleMapsMarker
  const marker = new AdvancedMarkerElement({
    position: latLng,
    map,
    title: title,
    content: pin.element,
  });

  // Not allowed in constructor, but we can do it here :-)
  marker.info = info;

  marker.addListener('click', () => {
      infoWindow.close();
      infoWindow.setContent(marker.info);
      infoWindow.open(marker.map, marker);
  });

  // Store a reference to the new marker in the mapMarkers arrayh)
  mapMarkers.push(marker)

  // add marker to the map boundery (for scaling)
  mapBoundery.extend(latLng);

  // add marker to the cluster
  markerCluster.addMarker(marker, true);
}

const formatDateTime = (dateTimeString: string) => {
  if (! dateTimeString) return ''
  dateTimeString = dateTimeString.toString()
  let parts = []
  if (dateTimeString.includes('T')) {
    parts = dateTimeString.split('T')
  } else {
    parts = dateTimeString.split(' ')
  }
  const date = parts[0]
  const time = parts[1]
  const dateParts = date.split('-')
  const timeParts = time.split(':')
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`
}
</script>

<style scoped>
#map {
  height: calc(100vh - 370px) !important;
  min-height: 350px;
}
#autocompleteResults {
  background-color: #fff;
  width: 100%;
}
#autocompleteResults {
  margin-top: 0;
  position: absolute;
  z-index: 5;
}
.autocompleteResult {
  border: 1px solid #f0f0f0;
  padding: 10px;
  cursor: pointer;
}
.autocompleteResult:hover,
.autocompleteResult:focus {
  background-color: #f0f0f0;
}
.map-info {
  height: 56px;
  padding: 2px 10px;
  border: 1px solid rgb(0, 0, 0, 0.3);
  font-size: 9px;
}
</style>

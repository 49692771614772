<template>
  <v-container fluid grid-list-md>
    <v-row wrap>
      <v-col sm="12" style="position: relative;">
        <router-view @hide-template-ui="onHideTemplateUi" @show-template-ui="showTemplateUI"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import "./index"
import emitArray from "@/utils/emit";

const emit = defineEmits(emitArray.value)

const onHideTemplateUi = () => {
  emit('hide-template-ui')
}

const showTemplateUI = () => {
  emit('show-template-ui')
}
</script>

<template>
  <!-- Meta and save -->
  <v-card flat>
    <v-toolbar class="custom-bg" density="compact">
      <v-toolbar-title>{{ ucfirst(t('actions')) }}</v-toolbar-title>
    </v-toolbar>
    <v-card-actions class="justify-space-between">
      <v-btn color="grey" depressed @click="$emit('reset')" :disabled="!hasChanges">
        {{ $t('reset') }}
      </v-btn>
      <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading"  :disabled="!(valid && hasChanges)">
        {{ buttonText }}
      </v-btn>
      <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ucfirst } from '@/utils/filters'

const {t} = useI18n()

const props = defineProps({
  workOrder: {
    type: Object,
    required: true
  },
  hasChanges: {
    type: Boolean,
    default: true
  },
  valid: {
    type: Boolean,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  }
})

const buttonText = computed(() => {
  if (props.workOrder.id) return t('save')
  return t('create')
})
</script>

<template>
  <v-dialog
    v-model="show"
    :content-class="contentClass"
    :max-width="maxWidth"
    :scrollable="scrollable"
    class="open"
  >
    <template v-slot:default>
      <v-card>
        <v-card-title>
          <ToolbarHeader :title="title" :closeDialog="closeDialog" />
        </v-card-title>
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </template>

    <template v-slot:activator="{ props }">
      <slot v-bind="props" name="activator"></slot>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import emitArray from '@/utils/emit';
import ToolbarHeader from './ToolbarHeader.vue'
import { defineComponent, computed, nextTick } from 'vue';

const emit = defineEmits(emitArray.value)
defineComponent({
  ToolbarHeader,
})
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  contentClass: {
    type: String,
    required: false,
    default: '',
  },
  scrollable: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxWidth: {
    type: String,
    required: false,
    default: '80%',
  },
  title: {
    type: String,
    required: true,
  },
})

const show = computed({
  get() {
    return props.visible
  },
  set(value: any){
    if (!value) {
      closeDialog()
    }
  }
})

const closeDialog = () => {
  nextTick(() => {
    emit('update:visible', false)
  })
}

</script>

<style scoped lang="scss">
.card__title {
  padding: 0;
}
</style>

import axios from "axios";
import { EquipmentItem } from '@/store/types/'
import  useBranchSelector  from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function getEquipmentItems(filters: any = null) {
  let requestData
  if (typeof filters !== 'undefined') {
    requestData = {
      params: filters
    }
  }

  if(requestData && requestData.params) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }
  return axios.get('/equipment-items', requestData)
}

export function getEquipmentItem(id: number|string) {
  return axios.get('/equipment-items/' + id)
}

export function createEquipmentItem(data: EquipmentItem) {
  return axios.post('/equipment-items', data)
}

export function updateEquipmentItem(id: number|string, data: EquipmentItem) {
  return axios.put('/equipment-items/' + id, data)
}

export function bulkUpdateEquipmentItems(data: object) {
  return axios.post('/equipment-items/bulk-update', data)
}

export function deleteEquipmentItem(id: number|string) {
  return axios.delete('/equipment-items/' + id)
}

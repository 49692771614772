<script setup lang="ts">
import {computed, defineEmits, inject, ref, watch} from 'vue';
import {jobEquipmentItemLocationAPI as API} from '@/api/jobs/jobEquipmentItemLocation'

import {useI18n} from 'vue-i18n'
import store from '@/store'
import {cloneDeep} from 'lodash-es'
import Say from '@/utils/Say'
import sayErrorResponse from '@/mixins/sayErrorResponse'
const { t } = useI18n()

const emit = defineEmits(["equipment-collected", "dialog-closed","show-template-ui","hide-template-ui"]);

const formSubmitted = ref(false)

const workOrderId = inject('workOrderId', null)

// Injected from Job/View
const jobId = inject('jobId', null)
API.setJobId(jobId.value)
const formatDate = inject('formatDate')

// Locations that the item/items can be moved to
const vanAmerongenLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const rentalSuppliers = computed(() => store.getters['rentalSuppliersList'])

const job = computed(() => store.getters['jobsEdit'])

const locationOptions = computed(() => {
  if(!selectedDetails.value.supplier) {
    return vanAmerongenLocations.value
  }

  const sup = selectedDetails.value.supplier.split(',')
  let getSupplier = false;
  for (const i in sup) {
    if(!getSupplier) {
      getSupplier = sup[i];
    }
    if(sup[i] !== getSupplier) {
      getSupplier = false;
      break;
    }
  }
  if(!getSupplier) {
    return vanAmerongenLocations.value
  }
  let selectedSupplier = rentalSuppliers.value.filter((supplier) => {
    return supplier.id == getSupplier
  })

  if(!selectedSupplier.length) {
    return vanAmerongenLocations.value
  }

  let options = cloneDeep(vanAmerongenLocations.value)
  selectedSupplier = selectedSupplier.pop()

  for(const option of options) {
    option.city = `VAF-Infra: ${option.city}`
  }

  options.unshift({
    id: selectedSupplier.location.id,
    city: selectedSupplier.location.location}
  )
  return options
})
const jobWorkOrders = computed(() => {
  let data = [];
  for (const workOrder of job.value?.work_orders ? job.value.work_orders : []) {
    let name = `${workOrder.standard_name_prefix} | ${workOrder.category}`
    if(workOrder.datetime_start) {
      name = `${formatDate(workOrder.datetime_start, ' ')} | ${name}`
    }
    data.push({
      name: name,
      id: workOrder.id
    })
  }
  return data
})


// The list of all items, used to extract details of needed
const items = inject('items', [])

// Currently selected item, when in single item mode
const selectedItem = inject('selectedItem', {})

// Currently selected items, when in grouped item mode
const selectedGroup = inject('selectedGroup', {})

const selectedDetails = computed(() => {
  let data = {}
  if(mode.value === 'single') {
    data.name = selectedItem.value.name
    data.placed_at_location = selectedItem.value.placed_at
    data.rent_started_at = selectedItem.value.rent_started_at
    data.rent_ended_at = selectedItem.value.rent_ended_at
    data.location = selectedItem.value.location.location
    data.rental_supplier_name = selectedItem.value.rental_supplier_name
    data.amount = 1
  } else {
    data.name = selectedGroup.value.name
    data.amount = selectedGroup.value.count
    data.placed_at_location = selectedGroup.value.placed_at
    data.rental_supplier_name = selectedGroup.value.rental_supplier_name
  }
  if(data.amount === 1) {
    formData.value.amount = 1
  }

  return data
})

const mode = computed(() => {
  if(selectedItem.value && Object.keys(selectedItem.value).length) {
    return 'single'
  }
  if(selectedGroup.value && Object.keys(selectedGroup.value).length) {
    return 'grouped'
  }
  return null
})

// Show the Collect Form
const collectDialogVisible = ref(false as boolean)

const dialogTitle = computed(() => {
  if(mode.value === 'single') {
    return t('CollectEquipmentSingle')
  }
  if(mode.value === 'grouped') {
    return t('CollectEquipmentGroup')
  }
  return t('CollectEquipment')
})


/**
 * The Form and all the references needed to keep track of that.
 */
const collectForm = ref(null as HTMLFormElement | null)

// Keep track of the form validation state
const formValid = ref(false)

// The model keys being created/edited
const formData = ref({
  amount: null,
  location_id: null,
  work_order_id: workOrderId,
})

/**
 * Form validation rules
 */
const formRules = ref({
  location_id: [
    value => {
      if (value) return true
      return t('pleaseFillInLocation')
    },
  ],
  amount: [
    value => {
      if (mode.value === 'single') return true
      if (value) return true
      return t('pleaseFillInQuantity')
    },
  ],
})

/**
 * When the dialog is opened then load van Amerongen Locations, just in case it is not yet loaded
 */
watch(
  () => collectDialogVisible.value,
  () => {
    if(collectDialogVisible.value) {
      store.dispatch('cache.vanAmerongenLocations.fetch');
      store.dispatch('rentalSuppliers.fetch');

      if(locationOptions.value.length > 0 && !formData.value.location_id) {
        if(job.value?.location_va?.id) {
          formData.value.location_id = job.value?.location_va?.id
        }
      }
    }
  }
)

watch(
  () => locationOptions.value,
  () => {
    if(locationOptions.value.length > 0 && !formData.value.location_id) {
      if(job.value?.location_va?.id) {
        formData.value.location_id = job.value?.location_va?.id
      }
    }
  }
)

/**
 * This is to open/close the dialog depending on if there is an selectedItem selected
 */
watch(
  () => [selectedItem.value, selectedGroup.value],
  () => {
    if(selectedItem.value?.id || selectedGroup.value?.id) {

      // if the dialog is going to be visible, then also pre-fill some data if needed
      if(!formData.value.amount && selectedDetails.value.amount) {
        formData.value.amount = selectedDetails.value.amount
      }
      collectDialogVisible.value = true
    } else {
      collectDialogVisible.value = false
    }
  }
)

const postFormData = computed(() => {
  let data = {};
  data = formData.value


  data.compositeItems = [];
  data.normalItems = [];
  if(mode.value === 'single') {
    if(selectedItem.value.composite_identifier) {
      data.compositeItems.push(selectedItem.value.composite_identifier)
    } else {
      data.normalItems.push(selectedItem.value.id)
    }
    data.process_grouped = false
  } else {
    if(selectedGroup.value.composite_equipment_id) {
      data.compositeItems.push(selectedGroup.value.HASH)
    } else {
      data.normalItems.push(selectedGroup.value.HASH)
    }
    data.process_grouped = true
  }

  data.location_change_type = 'collect'
  return data
})


/**
 * Save data to the server
 */
const submitForm = () => {
  if(formSubmitted.value) {
    return
  }
  formSubmitted.value = true

  // Make sure that the form is valid
  if (!formValid.value) {
    formSubmitted.value = false
    return
  }

  API.bulkUpdate({
    ...postFormData.value,
    ...{job_id: jobId?.value}
  })
    .then((response) => {
      collectForm.value.reset()
      formData.value.work_order_id = workOrderId
      Say('success', t('equipmentItemEditSuccess', 1))
      emit("equipment-collected");
      closeDialog()
      formSubmitted.value = false
    })
    .catch((error) => {
      formSubmitted.value = false
      sayErrorResponse(error.response?.data?.message ?? error.message)
    })
}

const closeDialog = () => {
  emit("dialog-closed");
  selectedItem.value = {}
  selectedGroup.value = {}
  formData.value.amount = null
  formData.value.location_id = null
  collectForm.value.reset()
  formData.value.work_order_id = workOrderId
}
</script>

<template>
  <div>
    <v-dialog
      v-model="collectDialogVisible"
      width="400"
      @afterLeave="closeDialog"
    >
      <v-form
        v-model="formValid"
        ref="collectForm"
        @submit.prevent="submitForm"
      >
        <v-card
          max-width="400"
          prepend-icon="mdi-pencil"
          :title="dialogTitle"
        >
          <template v-slot:text>
            <v-table class="bulk-change-items-list mb-4" >
              <tbody>
                <tr>
                  <th>{{t('amount')}}:</th><td>{{selectedDetails.amount}}</td>
                </tr>
                <tr>
                  <th>{{t('name')}}:</th><td>{{selectedDetails.name}}</td>
                </tr>
                <tr>
                  <th>{{t('rentalSupplier')}}:</th><td>{{selectedDetails.rental_supplier_name}}</td>
                </tr>
                <tr>
                  <th>{{t('PlacedEquipmentAt')}}:</th><td>{{selectedDetails.placed_at_location}}</td>
                </tr>
                <tr>
                  <th>{{t('RentEquipmentStart')}}</th><td>{{selectedDetails.rent_started_at}}</td>
                </tr>
                <tr>
                  <th>{{t('RentEquipmentEnd')}}:</th><td>{{selectedDetails.rent_ended_at}}</td>
                </tr>
              </tbody>
            </v-table>

            <v-text-field
              v-if="mode === 'grouped' && selectedDetails.amount > 1"
              v-model="formData.amount"
              :rules="formRules.amount"
              :label="t('Amount')"
              min="1"
              :max="selectedDetails.amount"
              type="number"
              variant="underlined"
            ></v-text-field>

            <v-autocomplete
              v-model="formData.work_order_id"
              id="form-work_order_id"
              :items="jobWorkOrders"
              :label="t('workOrder')"
              clearable
              item-title="name"
              item-value="id"
              variant="underlined"
              @change=""
              autocomplete="off"
              :disabled="!!workOrderId && formData.work_order_id === workOrderId"
            />

            <v-autocomplete
              v-model="formData.location_id"
              :rules="formRules.location_id"
              :items="locationOptions"
              :label="t('PlaceEquipmentAtLocation')"
              :placeholder="t('PlaceEquipmentAtLocation')"
              item-title="city"
              item-value="id"
              variant="underlined"
            />
          </template>

          <template v-slot:actions>
            <v-btn
              :text="t('save')"
              type="submit"
            />
            <v-btn
              :text="t('cancel')"
              type="reset"
              @click="closeDialog"
            />
          </template>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.bulk-change-items-list {
  &.v-table {
    .v-table__wrapper > table > tbody > tr > td,
    .v-table__wrapper > table > tbody > tr > th,
    .v-table__wrapper > table > thead > tr > td,
    .v-table__wrapper > table > thead > tr > th,
    .v-table__wrapper > table > tfoot > tr > td,
    .v-table__wrapper > table > tfoot > tr > th {
      padding:3px;
      height:auto;
    }
  }
}
</style>

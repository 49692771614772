<template>
  <v-card flat>
    <v-card-title>
        <v-container class="pa-0">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="7"><strong>{{ t('equipmentCompositeWith') }}</strong></v-col>
          <v-col cols="2"><strong>{{ t('quantity') }}</strong></v-col>
        </v-row>

        <v-row v-if="equipment.id == null || equipment.deletable" class="align-center">
          <v-col cols="1"><v-icon color="success">mdi-plus</v-icon></v-col>
          <v-col cols="7"><v-autocomplete hide-details variant="underlined" :items="allEquipment" item-title="name" return-object v-model="newEquipment"></v-autocomplete></v-col>
          <v-col cols="2"><v-text-field flat hide-details variant="underlined" v-model="newAmount" type="number" /></v-col>
          <v-col cols="2"><v-btn v-if="equipment.id == null || equipment.deletable" size="small" flat color="secondary" @click="addCombinedEquipment">{{ t('add') }}</v-btn></v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <!-- header -->
      <v-row v-for="item in combinedEquipment" :key="item.id" class="mt-0">
        <v-col class="b-b mb-3 pb-0" cols="1">
          <img v-if="item.image_href" :src="getImageUrl(item.image_href)" width="40" />
        </v-col>
        <v-col class="b-b mb-3 pb-0" cols="7">
          {{ item.name }}
        </v-col>
        <v-col class="pt-0" cols="2">
          <v-text-field flat hide-details variant="underlined" v-model="item.quantity" type="number" @change="emit('change', { combinedEquipment: combinedEquipment })" />
        </v-col>
        <v-col class="pb-0" cols="2">
          <v-btn v-if="equipment.id == null || equipment.deletable" size="small" flat color="error" @click="removeCombinedEquipment(item)"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import axios from 'axios'
import { cloneDeep, eq } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { fetchEquipmentNonCombiItems } from '@/api/equipment'
import emitArray from '@/utils/emit';

const emit = defineEmits(emitArray.value)

const props = defineProps({
  equipment: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()

const allEquipment = ref([])
const combinedEquipment = ref(null as any)
const newEquipment = ref(null as any) 
const newAmount = ref(1)

const initializeInputs = () => {
  combinedEquipment.value = props.equipment.combinedEquipment ? cloneDeep(props.equipment.combinedEquipment) : []
}

const fetchEquipmentLocal = async() => {
  const response: Record<string, any> = await fetchEquipmentNonCombiItems()
  response.data = response.data.filter((equip: any) => equip.is_consumable === 0)
  allEquipment.value = response.data
}

const addCombinedEquipment = () => {

  if (!newEquipment.value) return

  let exists = false;

  // Foreach equipment, check if it already exists in the suggestedEquipment array
  combinedEquipment.value.forEach((equip: any) => {
    if (equip.id === newEquipment.value.id && !exists) {
      equip.quantity += newAmount.value || 1
      exists = true
      return
    }
  })

  if (!exists) {
    combinedEquipment.value.push({
      id: newEquipment.value.id,
      quantity: newAmount.value || 1,
      name: newEquipment.value.name,
      image_href: newEquipment.value.image_href,
    })
  }

  nextTick(() => {
    newAmount.value = 1
    emit('change', { combinedEquipment: combinedEquipment.value })
  })
}

const removeCombinedEquipment = (equipmentId: any) => {
  if(equipmentId.id) {
    const removeEquipment = combinedEquipment.value.filter((equip: any) => equip.id !== equipmentId.id)
    combinedEquipment.value = removeEquipment
    nextTick(() => {
      emit('change', { combinedEquipment: removeEquipment })
    })
  }
}

// Turns a relative href of a resource to an absolute one
const fromStorage = (href: any) => {
  return axios.defaults.baseURL ? axios.defaults.baseURL + href : href
}

const getImageUrl = (imageHref: string) => {
  return fromStorage(imageHref)
}

onMounted(() => {
  initializeInputs()
  fetchEquipmentLocal()
})
</script>

<style lang="scss" scoped>
.input-group {
  padding: 0;
}
</style>
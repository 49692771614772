import axios from "axios";

class ClientEquipmentItemLocationAPI {

  clientId: string;

  setClientId(clientId: int | string) {
    this.clientId = clientId
  }

  index(filters: any = null) {
    let requestData = {}
    if (typeof filters !== 'undefined') {
      requestData = {
        params: filters
      }
    }
    return axios.get(`/clients/${this.clientId}/equipment-locations`, requestData)
  }

}
export const clientEquipmentItemLocationAPI = new ClientEquipmentItemLocationAPI()

import { ref } from "vue";

const storedTab = window.localStorage.getItem('jobs/View/projectTabSelector') ? window.localStorage.getItem('jobs/View/projectTabSelector') : ''
const projectTabSelector = ref(storedTab)

const useProjectTabSelector = () => {
    return {
        projectTabSelector
    };
}

export default useProjectTabSelector;



<template>
  <v-row wrap>
    <v-col sm="6">
      <!-- Location -->
      <v-autocomplete
        :items="locations"
        v-model="selectedLocationId"
        :label="$t('location')"
        item-value="id"
        item-title="name"
        variant="underlined"
        clearable
        @update:modelValue="updateMap()"
      />
    </v-col>
    <v-col sm="3">
      <!-- Category -->
      <v-autocomplete
        :items="categories"
        v-model="equipmentCategoryId"
        :label="$t('category')"
        item-value="id"
        item-title="name"
        variant="underlined"
        clearable
        @update:modelValue="updateMap()"
      ></v-autocomplete>
    </v-col>
    <v-col sm="3" class="pr-6">
      <v-autocomplete
        :items="equipment"
        v-model="equipmentId"
        :label="$t('equipment')"
        item-value="id"
        item-title="name"
        variant="underlined"
        clearable
        @update:modelValue="updateMap()"
      ></v-autocomplete>
    </v-col>
  </v-row>
  <div id="map"></div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import store from '@/store'
import { getEquipmentPerLocations } from '@/api/equipmentItemLocation'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  jobId: {
    type: Number,
    required: false,
    default: 0
  }
})

  // get locations from getEquipmentPerLocations response
let locations = ref<{ id: number; name: string }[]>([]);

const categories = computed(() => {
  return store.getters.equipmentCategories
})
const equipment = computed(() => {
  return store.getters.equipmentList
})

const selectedLocationId: any = ref(null)
const equipmentCategoryId: any = ref(null)
const equipmentId: any = ref(null)

const updateMap = () => {
  setMapMarkers(equipmentCategoryId.value, equipmentId.value)
}

let map: any = null
let mapBoundery: any = null
let infoWindow: any = null
let markerCluster: any = null
let mapMarkers = Array()

const language = () => {
  switch (import.meta.env.VITE_APP_I18N_LOCALE) {
    case 'en':
      return 'en-GB'
    case 'nl':
      return 'nl-NL'
    default:
      return 'en-GB'
  }
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  language: language(),
});

const googleMapsInit = async () => {
  const { Map, InfoWindow } = await loader.importLibrary("maps")
  const { LatLngBounds } = await loader.importLibrary("core")

  map = new Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 51.6340706, lng: 5.2135066 },
    zoom: 8,
    mapId: import.meta.env.VITE_GOOGLE_MAPS_MAP_ID
  });

  mapBoundery = new LatLngBounds();
  infoWindow = new InfoWindow();
  markerCluster = new MarkerClusterer({ map })
}

const setMapMarkers = async (equipmentCategoryId: number, equipmentId: number) => {
  // Remove the previous locations
  if (locations) {
    locations.value.length = 0
  }

  // Remove the previous markers if they exist
  if (mapMarkers) {
    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].map = null;
    }
    mapMarkers = []
  }

  // Clear cluster
  if (markerCluster) {
    markerCluster.clearMarkers()
  }

  getEquipmentPerLocations(props.jobId, equipmentCategoryId, equipmentId).then((response) => {

    let selectedLocationFound = false

    Object.keys(response.data.data).forEach((locationId: any) => {
      const equipmentPerLocation = response.data.data[locationId]
      const isBranch = equipmentPerLocation.isBranch ? true : false
      const isRentalSupplier = equipmentPerLocation.isRentalSupplier ? true : false

      // check location coordinates
      if (!equipmentPerLocation.location_lat || !equipmentPerLocation.location_long) {
        return
      }

      locations.value.push({
        id: locationId,
        name: equipmentPerLocation.location
      })

      // check if selected location is in the response
      if (selectedLocationId.value == locationId) {
        selectedLocationFound = true
      }

      // only show selected location
      if (selectedLocationId.value && selectedLocationId.value != locationId) {
        return
      }

      const equipment = equipmentPerLocation.equipment.reduce((acc: string, equipment: any) => {
        let link = ' --- '
        if (props.jobId > 0) {
          if (equipment.placed_by_work_order_id && equipment.placed_by_work_order_id > 0) {
            link = `<a href="/work-orders/view/${equipment.placed_by_work_order_id}">${equipment.work_orders_name}</a>`
          }
        } else {
          if (equipment.job_id && equipment.job_id > 0) {
            link = `<a href="/jobs/view/${equipment.job_id}">${equipment.job_name}</a>`
          }
        }

        const eqName = (equipment.unique_feature ? `${equipment.name} | ${equipment.unique_feature}` : equipment.name ) + (equipment.rental_supplier_id ? ` (${equipment.rental_supplier_name})` : '')
        return acc + `<tr><td>${eqName}</td><td>${equipment.count}</td><td>${link}</td><tr>`
      }, '')

      const totalCount = equipmentPerLocation.equipment.reduce((acc: string, equipment: any) => {
        return acc + equipment.count
      }, 0)

      const style = 'table {width:100%;border-spacing:0;border-collapse:collapse;margin-top:15px;margin-bottom:15px;} th {text-align:left;padding:5px;} td {padding:5px;border:1px solid #ddd;}'
      let headers = '<th>' + t('equipment') + '</th><th>' + t('quantity') + '</th><th>' + t('project') + '</th>'
      if (props.jobId > 0) {
        headers = '<th>' + t('equipment') + '</th><th>' + t('quantity') + '</th><th>' + t('placedByWorkOrder') + '</th>'
      }

      setMapMarker(
        {
          lat: parseFloat(equipmentPerLocation.location_lat),
          lng: parseFloat(equipmentPerLocation.location_long)
        },
        equipmentPerLocation.location,
        `<div style='min-width:300px;'>
          <b style='font-size:15px;'>${equipmentPerLocation.location}</b>
          <style>${style}</style>
          <table><tr>${headers}</tr>${equipment}</table>
        </div>`,
        locationId,
        isBranch,
        isRentalSupplier,
        totalCount
      ).then(() => {
        // reset scale op map
        map.fitBounds(mapBoundery)
        // check zoom level
        const maxZoom = 18
        if (map.getZoom() > maxZoom) {
          map.setZoom(maxZoom)
        }
        // render clusters
        markerCluster.render();
      })
    })

    // if selected location is not in the response, reset the selected location
    if (!selectedLocationFound) {
      selectedLocationId.value = null
    }
  })
}

const setMapMarker = async (latLng: any, title: string, info: string, locationId: number, isBranch: boolean, isRentalSupplier: boolean, count: number) => {
  const { AdvancedMarkerElement, PinElement } = await loader.importLibrary('marker')

  const glyph = count == 0 ? "" : count.toString()
  let scale = isBranch || isRentalSupplier ? 1.5 : 1.0
  let background = isBranch || isRentalSupplier ? "#F57827" : "#1E88E5"
  if (locationId == selectedLocationId.value) {
    scale = 1.5
    background = "#f7394a"
  }

  const pin = new PinElement({
    scale: scale,
    glyph: glyph,
    glyphColor: "black",
    background: background,
    borderColor: background,
  });

  const textElement = pin.element.querySelector('text');
  if (textElement) {
    if(scale === 1.5) {
      textElement.setAttribute('style', 'font-size: 10px');
    } else {
      textElement.setAttribute('style', 'font-size: 12px');
    }
  }

  // Create a new marker using GoogleMapsMarker
  const marker = new AdvancedMarkerElement({
    position: latLng,
    map,
    title: title,
    content: pin.element,
  });

  // Not allowed in constructor, but we can do it here :-)
  marker.info = info;

  marker.addListener('click', () => {
      infoWindow.close();
      infoWindow.setContent(marker.info);
      infoWindow.open(marker.map, marker);
  });

  // Store a reference to the new marker in the mapMarkers arrayh)
  mapMarkers.push(marker)

  // add marker to the map boundery (for scaling)
  mapBoundery.extend(latLng);

  // add marker to the cluster
  markerCluster.addMarker(marker, true);
}

onMounted(() => {
  store.dispatch('equipment.fetchCategories')
  store.dispatch('equipment.fetch')
  googleMapsInit()
  setMapMarkers(equipmentCategoryId.value, equipmentId.value)
})
</script>

<style scoped>
#map {
  height: calc(100vh - 290px) !important;
  min-height: 450px;
}
</style>

<template>
  <Map :jobId="Number(jobId)"></Map>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import Map from '@/components/UI/Map.vue'

const jobId = inject('jobId', 0)
</script>

<style scoped>
</style>

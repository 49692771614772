<template>
  <v-card flat :border="true">
    <v-toolbar class="custom-bg" density="compact" v-if="job.revisions">
      <v-toolbar-title>{{ `${$t('revisions')} (${Object.values(job.revisions).length})` }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0">
      <template v-if="Object.keys(revisionItems).length">
        <v-expansion-panels >
          <v-expansion-panel v-for="(changes, datetime) in revisionItems" :key="datetime">
              <v-expansion-panel-title class="align-center pa-2">
                <template v-slot:default>
                  <div class="text-start">
                    <div class="date ml-0 mb-1" style="font-size: 12px;">{{ prettyDateFormat(datetime) }}</div>
                    <div class="user">{{ changes[0].userResponsible }}</div>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-card class="ma-0 py-2" style="border-radius: 0 !important;">
                  <v-card-text class="pa-0">
                    <ul>
                      <li v-for="change in changes" class="revision-change" :key="change.field">
                        {{ t('changed') }} <strong>{{ change.field }}</strong> {{ lcfirst(t('from')) }}
                        <strong v-if="change.oldValue">
                          <span v-if="change.field === 'status'">{{ $t('jobStatuses.' + change.oldValue) }}</span>
                          <span v-else>{{ change.oldValue }}</span>
                        </strong>
                        <span v-else>{{ lcfirst(t('nothing')) }}</span>
                        {{ lcfirst(t('to2')) }}
                        <strong v-if="change.newValue">
                          <span v-if="change.field === 'status'">{{ $t('jobStatuses.' + change.newValue) }}</span>
                          <span v-else>{{ change.newValue }}</span>
                        </strong>
                        <span v-else>{{ lcfirst(t('nothing')) }}</span>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn small @click="showMore()" v-if="hasMoreItems">{{ t('showMore') }}</v-btn>
      </template>
      <template v-else slot="content">
        <div class="pt-3 pr-3 pb-3 pl-3">
          {{ t('noRevisions') }}
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { lcfirst, prettyDateFormat } from '@/utils/filters'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  job: {
    type: Object,
    required: true
  }
})

const itemsToShow= ref(5)

const hasMoreItems = computed(() => {
  return Object.keys(props.job.revisions).length > itemsToShow.value
})

const revisionItems = computed(() => {
  if (!props.job.revisions) return {}

  const items: any = {}
  const datetimes = Object.keys(props.job.revisions)
  for (let i = 0; i < Math.min(itemsToShow.value, datetimes.length); i++) {
    const datetime = datetimes[i]
    const rows = props.job.revisions[datetime]
    items[datetime] = rows
  }

  return items
})

const showMore = () => {
  itemsToShow.value += 5
}

</script>

<style scoped lang="scss">
.card__text {
  padding: 0;
}

.user {
  font-size: 0.9em;
}

.expansion-panel__header, .expansion-panel__body {
  padding: 5px 10px!important;
}

.revision-change {
  margin-left: 28px;
  margin-right: 10px;
  font-size: 0.9em;
}
</style>

<style>
.v-expansion-panel-text__wrapper {
  padding: 0 !important;
}
.v-expansion-panel__shadow {
  box-shadow: none !important;
}
</style>

import { ref } from "vue";

const emitArray = ref([
    'change',
    'close',
    'created',
    'created-employees',
    'equipment:loaded',
    'hide-template-ui',
    'loading',
    'open-search',
    'show-template-ui',
    'show-contact-dialog',
    'show-client-dialog',
    'show-location-dialog',
    'update',
    'update-dates-and-times',
    'update:loading',
    'update:model',
    'update:saving',
    'update:value-datetime',
    'update:visible',
    'update:valid',
])

export default emitArray;

import axios from "axios";
import {EquipmentItem} from '@/store/types';

class JobEquipmentItemLocationAPI {

  jobId: string;

  setJobId(jobId: int | string) {
    this.jobId = jobId
  }

  /**
   * Show a list of the equipment items that are attached to this job that are attached do this
   * @param filters
   */
  index(filters: any = null) {
    let requestData = {}
    if (typeof filters !== 'undefined') {
      requestData = {
        params: filters
      }
    }
    return axios.get(`/jobs/${this.jobId}/equipment-locations`, requestData)
  }

  /**
   * Get equipment items at location
   * @param locationId
   * @param supplierId
   */
  getEquipmentAtLocation(locationId: number | string, supplierId = null) {
    let url = `/jobs/${this.jobId}/equipment-locations/at-location/${locationId}`
    if(supplierId) {
      url = `${url}?supplier=${supplierId}`
    }
    return axios.get(url)
  }

  /**
   * Move an equipment item to a location
   * @param data
   */
  store(data: EquipmentItem) {
    return axios.post(`/jobs/${this.jobId}/equipment-locations`, data)
  }

  /**
   *bulk Update fields on multiple location items
   * @param data
   */
  bulkUpdate(data: EquipmentItem) {
    return axios.put(`/jobs/${this.jobId}/equipment-locations/bulk-update`, data)
  }
}
export const jobEquipmentItemLocationAPI = new JobEquipmentItemLocationAPI()

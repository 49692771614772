<template>
  <v-row id="login" class="justify-center align-center h-100">
    <v-main>
      <v-form v-on:submit.prevent="login()">
        <v-container fluid>
          <v-row class="align-center justify-center">
            <v-col xs='12' sm='8' md='3'>
              <v-card flat class="elevation-12">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{ $t('logIn') }}</v-toolbar-title>
                  <v-spacer/>
                </v-toolbar>
                <v-card-text class="pb-0">
                  <v-text-field prepend-icon="mdi-account" name="username" :label="$t('username')" type="text" v-model="body.username" variant="underlined"/>
                  <v-text-field prepend-icon="mdi-lock" name="password" :label="$t('password')" id="password" type="password" v-model="body.password" variant="underlined"/>
                </v-card-text>
                <v-card-actions class="justify-center align-center pb-4">
                  <v-btn color="primary" rounded="xl" height="30" class="px-12 py-1" variant="elevated" type="submit" v-show="!loading">{{ $t('logIn') }}</v-btn>
                  <v-progress-circular indeterminate color="primary" v-show="loading"/>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-main>
  </v-row>
</template>

<script setup lang="ts">
import Say from '@/utils/Say'
import axios from 'axios';
import { onBeforeMount, ref } from 'vue';
import { useAuth } from 'vue-auth3';
import { useStore } from 'vuex';
import sayErrorResponse from '@/mixins/sayErrorResponse';

const auth = useAuth()
const store = useStore()

const loading = ref(false)
const error = ref(null)
const body = ref({
  username: '',
  password: ''
})

onBeforeMount(() => {
  store.commit('app.setPageTitle', 'Van Amerongen')
})

const login = () => {
  let credentials = body.value
  loading.value = true
  auth.login({
    data: credentials,
    fetchUser: true,
  })
  .then((res: Record<string, any>) => {
    axios.defaults.headers.common['Authorization'] = res.headers.authorization
   }, (err: any) => {
    loading.value = false
    sayErrorResponse(err)
  })
  .catch((err: Error) => {
    sayErrorResponse(err)
  })
}
</script>

<template>
  <v-row>
    <v-col sm="6">
      <v-form v-on:submit.prevent="submitForm()" v-model="valid" ref="form">
        <v-card flat :border="true" class="overflow-visible">
          <v-card-text>

            <v-autocomplete
              v-if="!equipmentItem.id"
              :items="equipmentOptions"
              v-model="equipmentItem.equipment_id"
              :label="$t('equipment')"
              item-value="id"
              item-title="name"
              @input="onInput('equipment_id', $event.target.value)"
              variant="underlined"></v-autocomplete>

            <v-text-field
              v-if="!equipmentItem.id && props.equipmentItem.equipment_id && !checkIfEquipmentIsBulk()"
              :label="t('uniqueFeature', 1)"
              v-model="equipmentItem.unique_feature"
              required
              variant="underlined"></v-text-field>

            <v-text-field
              v-if="!equipmentItem.id && checkIfEquipmentIsBulk()"
              :label="t('quantity', 1)"
              v-model="equipmentItem.quantity"
              type="number"
              min="1"
              max="500"
              required
              variant="underlined"
            ></v-text-field>

            <v-autocomplete
              v-if="!equipmentItem.id"
              :items="rentalSuppliers"
              v-model="equipmentItem.rental_supplier_id"
              :label="$t('rentedFrom')"
              item-value="id"
              item-title="name"
              @input="onInput('rental_supplier_id', $event.target.value)"
              clearable
              variant="underlined"></v-autocomplete>

            <v-select
              v-if="equipmentItem.id && equipmentItem.status !== 'USED'"
              :items="statuses"
              :label="t('status', 1)"
              v-model="equipmentItem.status"
              required
              variant="underlined"
              :disabled="equipmentItem.location?.job_id"
            ></v-select>

            <div class="datepickerWrapper">
              <div class="datepickerLabel">{{ !equipmentItem.id ? t('dateAcquired', 1) : t('statusDate', 1) }}</div>
              <Datepicker
                v-model="equipmentItem.status_date"
                required
                v-on:update:value-datetime="equipmentItem.status_date = $event"
                variant="underlined"
                :teleport="true"
                :disabled="equipmentItem.location?.job_id"
              />
            </div>
            <v-radio-group
              v-if="!equipmentItem.id"
              v-model="equipmentItem.toLocationType"
              row
              >
              <v-radio
                :label="$t('vaLocation')"
                value="vaLocation"
              ></v-radio>
              <v-radio
                :label="$t('rentalSuppliersLocation')"
                value="rentalSuppliersLocation"
                :disabled="!equipmentItem.rental_supplier_id"
              ></v-radio>
              <!-- <v-radio
                :label="$t('clientLocation')"
                value="clientLocation"
              ></v-radio> -->
            </v-radio-group>

            <v-autocomplete
              v-if="!equipmentItem.id && equipmentItem.toLocationType === 'vaLocation'"
              :items="vaLocations"
              v-model="equipmentItem.va_location_id"
              :label="$t('location')"
              item-value="id"
              item-title="location"
              @input="onInput('va_location_id', $event.target.value)"
              variant="underlined"></v-autocomplete>

            <v-autocomplete
              v-if="!equipmentItem.id && equipmentItem.toLocationType === 'rentalSuppliersLocation'"
              :items="rentalSuppliersLocations"
              v-model="equipmentItem.rental_suppliers_location_id"
              :label="$t('location')"
              item-value="id"
              item-title="location"
              @input="onInput('rental_suppliers_location_id', $event.target.value)"
              variant="underlined"></v-autocomplete>

          </v-card-text>
          <v-card-actions>
            <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading"
              :disabled="!valid || equipmentItem.location?.job_id">
              {{ buttonText }}
            </v-btn>
            <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import Datepicker from '../UI/Datepicker.vue'
import store from '@/store';
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  equipmentItem: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: true
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Save'
  }
})

const valid = ref(false)
const form = ref()

const { t } = useI18n()

const fetchData = async () => {
  await store.dispatch('equipment.fetch')
  await store.dispatch('equipment.fetchNonCombiItems')
  await store.dispatch('rentalSuppliers.fetch')
  await store.dispatch('cache.vanAmerongenLocations.fetch')
  await store.dispatch('cache.rentalSuppliersLocations.fetch')
  }

onMounted(async () => {
  await fetchData()
  props.equipmentItem.toLocationType = 'vaLocation'
})

const vaLocations = computed(() => {
  return store.getters['cache.vanAmerongenLocations']
})

const rentalSuppliersLocations = computed(() => {
  return store.getters['cache.rentalSuppliersLocations']?.filter((item) => {
    return props.equipmentItem.rental_supplier_id === item.rental_supplier_id
  }) ?? []
})

const statuses = computed(() => {
  return [
    'ACQUIRED',
    'LOST',
    'STOLEN',
    'DAMAGED',
    'RETIRED',
  ]
})

const equipmentOptions = computed(() => {
  return store.getters.equipmentNonCombiItems
  //let options = store.getters.equipmentNonCombiItems

  //return options.filter((item: any) => !item.parent_id)
})

const rentalSuppliers = computed(() => {
  return store.getters.rentalSuppliersList
})

const submitForm = () => {
  props.submit()
}

const checkIfEquipmentIsBulk = () => {
  if (!props.equipmentItem.equipment_id) return 0

  const is_bulk = store.getters.equipmentList.find((equipment: any) => equipment.id === props.equipmentItem.equipment_id)?.is_bulk

  if (is_bulk) {
    props.equipmentItem.unique_feature = ""
  } else {
    props.equipmentItem.quantity = 1
  }
  return is_bulk
}

watch(() => props.equipmentItem.rental_supplier_id, () => {
  if (!props.equipmentItem.rental_supplier_id) {
    props.equipmentItem.rental_suppliers_location_id = null
    props.equipmentItem.toLocationType = 'vaLocation'
  }
})
</script>

<style scoped lang="scss">
table.table {
  tbody {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
.datepickerWrapper {
  margin-bottom: 15px;
}
.datepickerLabel {
  margin-bottom: 5px;
  font-size: 10px;
  color: #777;
}
</style>

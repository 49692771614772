<template>
  <div>
    <v-list-item @click="doAction()" :inactive="!hasAction()">
      <template v-slot:prepend v-if="avatar">
        <v-icon :class="iconClass" @click="onIconClick ? onIconClick() : () => { }">
          {{ icon }}
        </v-icon>
      </template>
      <v-list-item>
        <v-list-item-title :class="titleClass" v-if="title">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ subtitle }}
        </v-list-item-subtitle>

        <span v-if="$slots.content">
          <slot name="content"></slot>
        </span>
      </v-list-item>
      <v-list-item-action>
        <slot name="action"></slot>
      </v-list-item-action>
    </v-list-item>
    <v-divider v-if="divider" />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter()

const props = defineProps({
  icon: {
    type: String,
    default: null,
    required: false
  },
  iconClass: {
    type: String,
    default: null,
    required: false
  },
  title: {
    type: [String, Number],
    required: true
  },
  titleClass: {
    type: String,
    required: false,
    default: null
  },
  subtitle: {
    type: String,
    required: false,
    default: null
  },
  routeLink: {
    type: Object,
    required: false,
    default: null
  },
  avatar: {
    type: Boolean,
    required: false,
    default: true
  },
  divider: {
    type: Boolean,
    required: false,
    default: true
  },
  onIconClick: {
    type: Function,
    default: null,
    required: false
  },
  onClick: {
    type: Function,
    default: null,
    required: false
  }
})

const hasAction = () => {
  return typeof props.onClick === 'function' || typeof props.onIconClick === 'function' || props.routeLink
}
const doAction = () => {
  if (props.onClick) {
    props.onClick()
  } else if (props.onIconClick) {
    props.onIconClick()
  } else if (props.routeLink) {
    router.push(props.routeLink)
  }
}

</script>

<style lang="scss" scoped>
  .list__tile__title {
    font-weight: bold;
    font-size: 16px;
    &.regular {
      font-weight: normal;
    }
  }
</style>

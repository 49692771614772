<template>
  <v-list
    dense
    :class="getListClass()"
  >
    <slot name="items"></slot>
  </v-list>
</template>

<script setup lang="ts">
const props = defineProps({
  oneLine: {
    type: Boolean,
    default: false
  },
  twoLine: {
    type: Boolean,
    default: false
  },
  threeLine: {
    type: Boolean,
    default: false
  }
})

const getListClass = () => {
  if (props.oneLine) return 'list--one-line'
  if (props.twoLine) return 'list--two-line'
  if (props.threeLine) return 'list--three-line'
}
</script>

import axios from 'axios'
import useBranchSelector from '@/composable/useBranchSelector'

const { branchSelected } = useBranchSelector()

export function fetchUsers (data: any) {
  let requestData
  if (typeof data !== 'undefined') {
    requestData = {
      params: data
    }
  }

  if(requestData) {
    requestData.params.branchId = branchSelected.value
  } else {
    requestData = {
      params: {
        branchId: branchSelected.value
      }
    }
  }
  return axios.get('/users', requestData)
}

export function fetchUser (id: number|string) {
  return axios.get('/users/' + id)
}

export function createUser (data: Record<string, any>) {
  return axios.post('/users', data)
}

export function updateUser (id: number|string, data: Record<string, any>) {
  return axios.put('/users/' + id, data)
}

export function deleteUser (id: number|string) {
  return axios.delete('/users/' + id)
}

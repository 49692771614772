<template>
  <v-row wrap>
    <v-col sm="8" offset-sm="2">
      <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
      <UserForm
        :user="user"
        :submit="submit"
        :title="t('userEdit') + ' ' + user.name"
        :onInput="onInput"
        :loading="loading"
        :buttonText="t('save')"
        :customRules="rules"
        v-if="!preLoading"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, onUnmounted, defineComponent, computed, onBeforeMount, watch, nextTick } from 'vue'
import { default as UserForm } from './Form.vue'
import Say from '@/utils/Say'
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import sayErrorResponse from '@/mixins/sayErrorResponse'

defineComponent({
  UserForm
})

const { t } = useI18n()
const route = useRoute()
const store = useStore()

const preLoading = ref(true)
const loading = ref(false)

const rules = {
  password: [
    (v: any) => (v.length === 0 || v.length >= 6) || t('validationPasswordMinLength')
  ],
  pincode: [
    (v: any) => (v.length === 0 || v.length === 4) || t('validationPincodeExactlyFour'),
    (v: any) => (v.length === 0 || /^\d+$/.test(v)) || t('validationPincodeNumbersOnly')
  ]
}

const user = computed(() => {

  return store.getters.userEdit
})

watch(store.getters.userEdit, () => {
  if(store.getters.userEdit.name !== '') {
    preLoading.value = false
  }
}),

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('edit') + ' ' + store.getters.userEdit.name)
  store.commit('app.setBreadcrumbs', [
    { text: t('user', 2), routeName: 'Users' },
    { text: t('edit') + ' ' + store.getters.userEdit.name }
  ])
  if ((store.state as any).roles.list.length === 0) {
    store.dispatch('roles.fetch')
  }
  store.dispatch('users.getSingleForEdit', route.params.id)
})

onUnmounted(() => {
  store.commit('users.editClear')
})

const submit = () => {
  loading.value = true
  store.dispatch('users.edit').then(() => {
    // Display success
    loading.value = false
    Say('success', t('userEditSuccess'))
  }).catch((err) => {
    // Validation error
    loading.value = false
    sayErrorResponse(err)
  })
}

const onInput = (name: string, value: any) => {
  store.commit('users.editUpdateInput', { name: name, value: value })
}
</script>

import { createI18n } from 'vue-i18n'
import translations from "./translations";

function loadLocaleMessages(): any {
  const locales: Record<string, any> = translations;
  const messages: Record<string, undefined> = {};
  Object.keys(locales).forEach((key) => {
    const locale = key;
    messages[locale] = locales[key];
  });
  return messages;
}

export default createI18n({
  legacy: false,
  allowComposition: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "nl",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "nl",
  messages: loadLocaleMessages(),
});

import i18n from '@/i18n'

const { t }: any = i18n.global

export default {
  name: [
    (v: string) => !!v || t('validationNameRequired'),
    (v: string) => v.length < 128 || t('validationNameShorter')
  ],
  phone: [
    (v: any) => !!v || t('validationPhoneRequired'),
    (v: any) => !v || (v && v.length < 15) || t('validationPhoneShorter'),
    (v: any) => !v || /^\d+$/.test(v) || t('validationPhoneNumbersOnly')
  ],
  required: [
    (v: any) => !!v || 'dit ingediend is vereist'
  ]
}

import { createAuth } from "vue-auth3"
import router from "@/router";
import driverAuthBearer from "vue-auth3/drivers/auth/bearer"
import axios from "axios";

const auth = createAuth({
  rolesKey: 'roles_list',
  userKey: 'auth_user',
  rememberKey:'auth_remember',
  tokenDefaultKey:'auth_token_default',
  staySignedInKey: 'auth_stay_signed_in',
  stores:['storage'],
  fetchData: {
    url: '/me',
    keyUser: 'data',
    enabled: true,
    cache: false,
    enabledInBackground: true,
  },
  refreshToken: {
    url: '/auth/refresh',
    method: 'POST',
    enabled: true,
    enabledInBackground: true,
    interval: 120,
  },
  impersonateData: {
    fetchUser: true,
    cacheUser: false,
  },
  loginData: {
    staySignedIn: true,
    fetchUser: true,
    keyUser: 'auth_user',
    cacheUser: false,
    remember: false,
  },
  logoutData: {
    makeRequest: true,
  },
  drivers: {
    http: axios,
    auth: driverAuthBearer,
  },
  plugins: {
    router,
  },
})

export default auth
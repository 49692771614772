import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/components/Dashboard.vue'
import SearchResults from '@/components/SearchResults.vue'
import LogIn from '@/components/LogIn.vue'
import Error403 from '@/components/Errors/403.vue'
import Settings from '@/components/Settings/index.vue'
import Roles, { RolesList, RolesEdit } from '@/components/Roles'
import Users, { UsersList, UsersEdit, UsersCreate } from '@/components/Users'
import Employees, {
  EmployeesList,
  EmployeesArchivedList,
  EmployeesEdit,
  EmployeesCreate,
  EmployeesWeekOverview,
  EmployeesTimeoff,
} from '@/components/Employees'
import WorkOrders, {
  WorkOrdersList,
  WorkOrdersEdit,
  WorkOrdersCreate,
  WorkOrdersView,
  WorkOrdersPrint,
} from '@/components/WorkOrders'
import Jobs, {
  JobsList,
  JobsEdit,
  JobsCreate,
  JobsView,
} from '@/components/Jobs'
import Branches, {
  BranchesList,
  BranchesEdit,
  BranchesCreate,
} from '@/components/Branches'
import Clients, {
  ClientsList,
  ClientsArchivedList,
  ClientsEdit,
  ClientsCreate,
} from '@/components/Clients'
import Equipment, {
  EquipmentList,
  EquipmentCreate,
  EquipmentEdit,
} from '@/components/Equipment'
import EquipmentItems, {
  EquipmentItemsList,
  EquipmentItemsEdit,
  EquipmentItemsCreate,
  EquipmentItemView,
  EquipmentItemsPrint
} from '@/components/EquipmentItem'
import EquipmentItemLocations, {
  EquipmentItemLocationCreate,
} from '@/components/EquipmentItemLocations'
import RentalSuppliers, {
  RentalSuppliersList,
  RentalSuppliersEdit,
  RentalSuppliersCreate
} from '@/components/RentalSuppliers'
import { useAuth } from 'vue-auth3'

const routes = [
  {
    path: '/403',
    name: '403',
    component: Error403,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: '/search-results',
    name: 'searchResults',
    component: SearchResults,
    meta: {
      auth: true,
    },
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn,
    meta: {
      auth: false,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    redirect: { name: 'RolesList' },
    meta: {
      auth: {
        roles: ['superadmin'],
      },
    },
    children: [
      {
        path: 'list',
        name: 'RolesList',
        component: RolesList,
      },
      {
        path: 'edit/:id',
        name: 'RolesEdit',
        component: RolesEdit,
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    redirect: { name: 'UsersList' },
    meta: {
      auth: {
        roles: ['admin', 'superadmin'],
      },
    },
    children: [
      {
        path: 'list',
        name: 'UsersList',
        component: UsersList,
      },
      {
        path: 'create',
        name: 'UsersCreate',
        component: UsersCreate,
      },
      {
        path: 'edit/:id',
        name: 'UsersEdit',
        component: UsersEdit,
      },
    ],
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    redirect: { name: 'EmployeesList' },
    meta: {
      auth: {
        roles: ['admin'],
      },
    },
    children: [
      {
        path: 'list',
        name: 'EmployeesList',
        component: EmployeesList,
      },
      {
        path: 'archived',
        name: 'EmployeesArchivedList',
        component: EmployeesArchivedList,
      },
      {
        path: 'create',
        name: 'EmployeesCreate',
        component: EmployeesCreate,
      },
      {
        path: 'edit/:id',
        name: 'EmployeesEdit',
        component: EmployeesEdit,
      },
      {
        path: 'week-overview/:year?/:week?',
        name: 'EmployeesWeekOverview',
        component: EmployeesWeekOverview,
      },
      {
        path: 'timeoff/:employeeId?',
        name: 'EmployeesTimeoff',
        component: EmployeesTimeoff,
      },
    ],
  },
  {
    path: '/work-orders',
    name: 'WorkOrders',
    component: WorkOrders,
    meta: {
      auth: {
        roles: ['admin', 'superadmin'],
      },
    },
    redirect: { name: 'WorkOrdersList' },
    children: [
      {
        path: 'list',
        name: 'WorkOrdersList',
        component: WorkOrdersList,
      },
      {
        path: 'create/:job_id?',
        name: 'WorkOrdersCreate',
        component: WorkOrdersCreate,
      },
      {
        path: 'edit/:id',
        name: 'WorkOrdersEdit',
        component: WorkOrdersEdit,
      },
      {
        path: 'view/:id',
        name: 'WorkOrdersView',
        component: WorkOrdersView,
      },
      {
        path: 'print/:id',
        name: 'WorkOrdersPrint',
        component: WorkOrdersPrint,
      },
    ],
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
    meta: {
      auth: {
        roles: ['admin', 'superadmin'],
      },
    },
    redirect: { name: 'JobsList' },
    children: [
      {
        path: 'list',
        name: 'JobsList',
        component: JobsList,
      },
      {
        path: 'create',
        name: 'JobsCreate',
        component: JobsCreate,
      },
      {
        path: 'edit/:id',
        name: 'JobsEdit',
        component: JobsEdit,
      },
      {
        path: 'view/:id',
        name: 'JobsView',
        component: JobsView,
      },
    ],
  },
  {
    path: '/branches',
    name: 'Branches',
    component: Branches,
    meta: {
      auth: {
        roles: ['superadmin'],
      },
    },
    redirect: { name: 'BranchesList' },
    children: [
      {
        path: 'list',
        name: 'BranchesList',
        component: BranchesList,
      },
      {
        path: 'create',
        name: 'BranchesCreate',
        component: BranchesCreate,
      },
      {
        path: 'edit/:id',
        name: 'BranchesEdit',
        component: BranchesEdit,
      },
    ],
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      auth: true,
    },
    redirect: { name: 'ClientsList' },
    children: [
      {
        path: 'list',
        name: 'ClientsList',
        component: ClientsList,
      },
      {
        path: 'archived',
        name: 'ClientsArchivedList',
        component: ClientsArchivedList,
      },
      {
        path: 'create',
        name: 'ClientsCreate',
        component: ClientsCreate,
      },
      {
        path: 'edit/:id',
        name: 'ClientsEdit',
        component: ClientsEdit,
      },
    ],
  },
  {
    path: '/equipment',
    name: 'Equipment',
    component: Equipment,
    meta: {
      auth: true,
    },
    redirect: { name: 'EquipmentList' },
    children: [
      {
        path: 'list',
        name: 'EquipmentList',
        component: EquipmentList,
      },
      {
        path: 'create',
        name: 'EquipmentCreate',
        component: EquipmentCreate,
      },
      {
        path: 'edit/:id',
        name: 'EquipmentEdit',
        component: EquipmentEdit,
      },
    ],
  },
  {
    path: '/equipment-items',
    name: 'EquipmentItems',
    component: EquipmentItems,
    meta: {
      auth: {
        roles: ['superadmin', 'admin'],
      },
    },
    redirect: { name: 'EquipmentItemsList' },
    children: [
      {
        path: 'list',
        name: 'EquipmentItemsList',
        component: EquipmentItemsList,
      },
      {
        path: 'create',
        name: 'EquipmentItemsCreate',
        component: EquipmentItemsCreate,
      },
      {
        path: 'edit/:id',
        name: 'EquipmentItemsEdit',
        component: EquipmentItemsEdit,
      },
      {
        path: 'view/:id',
        name: 'EquipmentItemView',
        component: EquipmentItemView,
      },
      {
        path: 'print',
        name: 'EquipmentItemsPrint',
        component: EquipmentItemsPrint,
      },
    ],
  },
  {
    path: '/equipment-item-locations',
    name: 'EquipmentItem items',
    component: EquipmentItemLocations,
    meta: {
      auth: {
        roles: ['superadmin', 'admin'],
      },
    },
    redirect: { name: 'EquipmentItems' },
    children: [
      {
        path: 'create',
        name: 'EquipmentItemLocationCreate',
        component: EquipmentItemLocationCreate,
      },
    ],
  },
  {
    path: '/rental-suppliers',
    name: 'RentalSuppliers',
    component: RentalSuppliers,
    meta: {
      auth: {
        roles: ['superadmin', 'admin'],
      },
    },
    redirect: { name: 'RentalSuppliersList' },
    children: [
      {
        path: 'list',
        name: 'RentalSuppliersList',
        component: RentalSuppliersList,
      },
      {
        path: 'create',
        name: 'RentalSuppliersCreate',
        component: RentalSuppliersCreate,
      },
      {
        path: 'edit/:id',
        name: 'RentalSuppliersEdit',
        component: RentalSuppliersEdit,
      },
    ],
  },
]

const router =  createRouter({
  history: createWebHistory(),
  routes,
})

let fetched = false

router.beforeEach(async(to, from, next) => {

  const auth = useAuth()

  if(!fetched) {
    try {
      await auth.fetch()
    } catch (e) {
      // skip
    }
    fetched = true
  }

  if(auth.user() || to.meta.auth === false) {
    next()
  } else {
    next('/login')
  }
})

export default router

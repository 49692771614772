import axios from 'axios'

export function fetchLocations (data: Record<string, undefined>) {
  let requestData
  if (typeof data !== 'undefined') {
    requestData = {
      params: data
    }
  }
  return axios.get('/locations', requestData)
}

export function fetchVanAmerongenLocations () {
  return axios.get('/locations/van-amerongen')
}

export function fetchRentalSuppliersLocations () {
  return axios.get('/locations/rental-suppliers')
}

export function createLocation (data: Record<string, undefined>) {
  return axios.post('/locations', data)
}

export function deleteLocation (id: number|string) {
  return axios.delete('/locations/' + id)
}

export function autocompleteLocation (data: any) {
  return axios.post('/locations/autocomplete', data)
}

export function geocodeLocation (data: any) {
  return axios.post('/locations/geocode', data)
}

export function googlePlaceDetails (data: any) {
  return axios.post('/locations/place-details', data)
}


<template>
  <div class="document">
    <div class="page">
      <v-row class="mb-6">
        <v-col cols="3">
          <img src="../../../assets/logo.svg" alt="Van Amerongen" height="80">
        </v-col>
        <v-col cols="9" class="pt-9">
          <h2>{{ $t('equipment') }}</h2>
        </v-col>
      </v-row>
      <table class="table mb-2">
        <tbody>
          <tr v-if="locationId" style="height: 30px;">
            <td width="200"><strong>{{ t('location') }}:</strong></td>
            <td>
              <span v-if="locationBranchId" class="badge badgeOrange">{{ $t('branch') }}</span>
              <span v-if="locationRentalSupplierId" class="badge badgeBlue">{{ $t('rentalSupplierBrannch') }}</span>
              {{ locationName }}
            </td>
          </tr>
          <tr v-if="isAttachedToJob == 'true' || isAttachedToJob == 'false'">
            <td width="200"><strong>{{ t('isAttachedToJob') }}:</strong></td>
            <td>{{ isAttachedToJob == 'true' ? t('yes') : t('no') }}</td>
          </tr>
          <tr v-if="equipmentId" style="height: 30px;">
            <td width="200"><strong>{{ t('equipment') }}:</strong></td>
            <td>{{ equipmentName }}</td>
          </tr>
          <tr v-if="status" style="height: 30px;">
            <td width="200"><strong>{{ t('status') }}:</strong></td>
            <td>{{ status }}</td>
          </tr>
          <tr v-if="ownEquipment" style="height: 30px;">
            <td width="200"><strong>{{ t('ownEquipment') }}:</strong></td>
            <td>{{ ownEquipment == 'true' ? t('yes') : t('no') }}</td>
          </tr>
          <tr v-if="rentalSupplierId" style="height: 30px;">
            <td width="200"><strong>{{ t('rentalSupplier') }}:</strong></td>
            <td>{{ rentalSupplierName }}</td>
          </tr>
          <tr v-if="systemCreated" style="height: 30px;">
            <td width="200"><strong>{{ t('createdBySystem') }}:</strong></td>
            <td>{{ systemCreated == 'true' ? t('yes') : t('no') }}</td>
          </tr>
        </tbody>
      </table>
      <hr>
      <table class="table item-data" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th>{{ $t('equipment') }}</th>
            <th>{{ $t('uniqueFeature') }}</th>
            <th v-if="!status">{{ $t('status') }}</th>
            <th v-if="ownEquipment != 'true' && rentalSupplierId.length === 0">{{ $t('rentedFrom') }}</th>
            <th v-if="!systemCreated">{{ $t('createdBySystem') }}</th>
            <th v-if="!locationId">{{ $t('location') }}</th>
            <th v-if="isAttachedToJob != 'false'">{{ $t('job') }}</th>
            <!-- <th>{{ $t('client') }}</th> -->
            <th >{{ $t('amount') }}</th>
            <th style="width: 10px;"></th>
            <th style="width: 50px; text-align: right;"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="no-line" colspan="11">&nbsp;</td>
          </tr>
          <tr v-for="item in sortedEquipmentItemsList" :key="item.id">
            <td>
              {{ item.equipment.name }}
            </td>
            <td>
              {{ item.unique_feature }}
            </td>
            <td v-if="!status">
              {{ item.status }}
            </td>
            <td v-if="ownEquipment != 'true' && rentalSupplierId.length === 0">
              {{ item.rental_supplier_name }}
            </td>
            <td v-if="!systemCreated">
              {{ item.system_created ? t('yes') : t('no') }}
            </td>
            <td v-if="!locationId">
              <span v-if="item.location_branch_id" class="badge badgeOrange">{{ $t('branch') }}</span>
              <span v-if="item.location_rental_supplier_id" class="badge badgeBlue">{{ $t('rentalSupplierBrannch') }}</span>
              {{ item.location_searchable_address }}
            </td>
            <td v-if="isAttachedToJob != 'false'">
              {{ item.job_id }}
            </td>
            <!-- <td>
              {{ item.client_id }}
            </td> -->
            <td>
              {{ item.count }}
            </td>
            <td class="no-line"></td>
            <td class="underline" style="text-align: right;"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, onBeforeUnmount } from 'vue'
import store from '@/store'
import { equipmentItemStore } from '@/store/equipmentItems'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import emitArray from '@/utils/emit'

const {t} = useI18n()
const route = useRoute()
const emit = defineEmits(emitArray.value)

const showAsGroupedItems = true
const equipmentId = route.query.equipmentId && route.query.equipmentId !== '0' ? route.query.equipmentId : ''
const equipmentName = route.query.equipmentName || ''
const rentalSupplierId = route.query.rentalSupplierId && route.query.rentalSupplierId !== '0' ? route.query.rentalSupplierId : ''
const rentalSupplierName = route.query.rentalSupplierName || ''
const locationId = route.query.locationId && route.query.locationId !== '0' ? route.query.locationId : ''
const locationBranchId = route.query.locationBranchId && route.query.locationBranchId !== '0' ? route.query.locationBranchId : ''
const locationRentalSupplierId = route.query.locationRentalSupplierId && route.query.locationRentalSupplierId !== '0' ? route.query.locationRentalSupplierId : ''
const locationName = route.query.locationName || ''
const ownEquipment = route.query.ownEquipment || ''
const status = route.query.status || ''
const systemCreated = route.query.systemCreated || ''
const isAttachedToJob = route.query.isAttachedToJob || ''
const equipmentItemsList = computed(() => equipmentItemStore.getters.equipmentItems)

onBeforeMount(() => {
  emit('hide-template-ui')

  store.dispatch('cache.vanAmerongenLocations.fetch')

  getEquipmentItems()
})

onBeforeUnmount(() => {
  emit('show-template-ui')
})

const getEquipmentItems = () => {
  equipmentItemStore.dispatch('fetchEquipmentItems', Object.assign(
    99999,
    {
      showAsGroupedItems: showAsGroupedItems,
      locationId: locationId,
      ownEquipment: ownEquipment,
      status: status,
      systemCreated: systemCreated,
      equipmentId: equipmentId,
      rentalSupplierId: rentalSupplierId,
      isAttachedToJob: isAttachedToJob,
    }
  )).then(() => {
    window.setTimeout(window.print, 500)
  })
}

const sortEquipmentItemsList = (list: any) => {
  return list.slice().sort((a: any, b: any) => {
    const nameA = a.equipment.name.toUpperCase();
    const nameB = b.equipment.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names are equal, so sort by unique_feature
    const featureA = a.unique_feature ? a.unique_feature.toUpperCase() : '';
    const featureB = b.unique_feature ? b.unique_feature.toUpperCase() : '';
    if (featureA < featureB) {
      return -1;
    }
    if (featureA > featureB) {
      return 1;
    }

    // features are equal
    return 0;
  });
}

const sortedEquipmentItemsList = computed(() => {
  if(!equipmentItemsList.value) return []
  return sortEquipmentItemsList(equipmentItemsList.value)
})
</script>

<style scoped lang="scss">
.document {
  height: 297mm;
  width: 210mm;
  margin: -8px;
  padding: 8px;
  page-break-after: always;
  page-break-inside: avoid;

  break-after: 'page';

  & > div {
    margin-top: 20px;
  }

  .page {
    padding-bottom: 20px;
    page-break-after: always;

    .table {
      margin-top: 20px;

      th {
        text-align: left
      }
    }
  }

  .table {
    width: 100%;
  }
  .table.item-data {
    td {
      border-bottom: 1px solid #cecece;
      padding:3px 0 5px 0;
    }
    td.no-line {
      border-bottom: 1px solid transparent;
    }
    td.underline {
      border-bottom: 1px solid #000000;
    }
  }

  .table tr {
    border-bottom: 1px solid #000;
  }

  .table thead th {
    text-transform: none !important;
    padding-right: 15px;
    vertical-align: top;
  }

  .table tbody td {
    padding-right: 15px;
    vertical-align: top;
  }

  @page {
    margin: 60px 0;
  }

  @page :first {
    margin-top: 0;
  }
}
.badge {
  background-color: #999;
  color: #fff;
  padding: 2px 5px;
  border-radius: 25px;
  text-wrap: nowrap;
}
.badgeOrange {
  background-color: #f57c00;
  color: #fff;
}
.badgeBlue {
  background-color: #1E88E5;
  color: #fff;
}
</style>

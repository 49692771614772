<template>
  <div>
    <VueDatePicker
      :uid="id"
      :placeholder="placeholder"
      :name="name"
      :min-time="minTime"
      :max-time="maxTime"
      :disabled="disabled"
      :required="required"
      :readonly="readonly"
      :position="position"
      :teleport="teleport"
      :model-value="model"
      :auto-apply="autoApply"
      @update:model-value="updateModel"
      :text-input="textInputOptions"
      time-picker
    >
      <template #input-icon>
          <v-icon class="pl-2">
            mdi-clock-outline
          </v-icon>
        </template>
    </VueDatePicker>
  </div>
</template>

<script setup lang="ts">
import VueDatePicker, { PartialTimeObj } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { PropType ,defineComponent, onMounted, ref } from 'vue';
import emitArray from '@/utils/emit';

const emit = defineEmits(emitArray.value)

const removeUnexpectedCharacters = (value: any) => {
  return value.replaceAll(/[:.]/g, '')
}

const textInputOptions = {
  format: (time: any): any => {
    const clearTime = removeUnexpectedCharacters(time)

    const length = clearTime.length;
    const currentDate = new Date();

    if(length <= 2) {
      return currentDate.setHours(clearTime, 0)
    } else if (length > 2 && length == 3) {
      const hours = clearTime.substring(0, 1);
      const minutes = clearTime.substring(1, 3);
      return currentDate.setHours(hours, minutes)
    } else if (length > 2 && length == 4) {
      const hours = clearTime.substring(0, 2);
      const minutes = clearTime.substring(2, 4);
      return currentDate.setHours(hours, minutes)
    }
  }
};

defineComponent({
  VueDatePicker,
})

const model = ref()

const props = defineProps({
  placeholder: {
    type: String,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  id: {
    type: String,
    required: false
  },
  maxTime: {
    type: Object as PropType<PartialTimeObj | undefined> as any,
    default: null
  },
  minTime: {
    type: Object as PropType<PartialTimeObj | undefined> as any,
    default: null
  },
  autoApply: {
    type: Boolean,
    required: false,
    default: true
  },
  noToday: {
    type: Boolean,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false
  },
  readonly: {
    type: Boolean,
    required: false
  },
  required: {
    type: Boolean,
    required: false
  },
  position: {
    type: String as PropType<'left' | 'center' | 'right'>,
    required: false,
    default: 'center'
  },
  teleport: {
    type: Boolean,
    required: false,
    default: false
  },
  modelValue: {
    required: false,
  }
})

onMounted(() => {
  model.value = props.modelValue
})

const updateModel = (newValue: any) => {
  model.value = newValue;
  emit('update-dates-and-times', { name: props.name, value: newValue})
  emit('update:value-datetime', formatTimeToString(newValue))
}

const formatTimeToString = (time: any) => {
  if (!time) {
    return ;
  }

  return `${(time.hours).toString().padStart(2, "0")}:${(time.minutes).toString().padStart(2, "0")}`
}
</script>

<script setup lang="ts">
import {computed, inject, ref, watch, defineEmits } from 'vue'
import {cloneDeep} from 'lodash-es'
import {useI18n} from 'vue-i18n'

// The string  translator
const { t } = useI18n()

const emit = defineEmits(["updated"]);

// Get the API from the parent
const api = inject('api', null)
const apiId = inject('apiId', null)

// Injected properties
const file = inject('file', false as boolean|Object)

// References
const editingFile = ref(false as boolean|Object)
const dialog = ref(false as boolean)

// computed properties
const dialogTitle = computed(() => {
  return t('document')
})

// Watchers
watch(file, (value: any) => {
  editingFile.value = cloneDeep(value)
  dialog.value = !!editingFile.value
});

// Methods
const submitForm = async() => {
  api.value.update(apiId.value, file.value.id, {
    name: editingFile.value.name
  }).then((response) => {
    emit("updated", response.data);
  }).catch((error) => {
    console.error(error)
  })
}

</script>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="400"
    >
      <v-card
        max-width="400"
        prepend-icon="mdi-pencil"
        :title="dialogTitle"
      >
        <template v-slot:text>
          <v-text-field
            v-model="editingFile.name"
            :placeholder="file.name"
            :suffix="`.${file.extension}`"
            :label="$t('name')"
            maxlength="50"
            variant="underlined"
          />
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
            text="Cancel"
            @click="file = false"
          />
          <v-btn
            text="Save"
            @click="submitForm"
          />
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">

</style>

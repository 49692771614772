import axios from "axios";
import { Branch } from '@/store/types/'

export function getBranches(filters: any = null) {
  let requestData
  if (typeof filters !== 'undefined') {
    requestData = {
      params: filters
    }
  }
  return axios.get('/branches', requestData)
}

export function getBranch(id: number|string) {
  return axios.get('/branches/' + id)
}

export function createBranch(data: Branch) {
  return axios.post('/branches', data)
}

export function updateBranch(id: number|string, data: Branch) {
  return axios.put('/branches/' + id, data)
}

export function deleteBranch(id: number|string) {
  return axios.delete('/branches/' + id)
}

import axios from 'axios'
import moment from 'moment-timezone'
import 'moment/dist/locale/nl'

// set via VITE_APP_I18N_LOCALE in .env
moment.locale(import.meta.env.VITE_APP_I18N_LOCALE || 'nl')

moment.updateLocale('nl', {
  longDateFormat : {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD-MM',
    LL: 'YYYY-MM',
    LLL: 'DD-MM-YYYY',
    lll: 'YYYY-MM-DD',
    LLLL: 'D MMM YYYY HH:mm',
    llll: 'DD-MM-YYYY HH:mm',
  }
});

moment.updateLocale('en', {
  longDateFormat : {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'MM-DD',
    LL: 'YYYY-MM',
    LLL: 'MM-DD-YYYY',
    lll: 'YYYY-MM-DD',
    LLLL: 'D MMM YYYY HH:mm',
    llll: 'MM-DD-YYYY HH:mm',
  }
});

// Categories for the workorders
export const categoryItems = [
  'Plaatsen verkeersmaatregelen',
  'Retour verkeersmaatregelen',
  'Verkeer regelen',
  'Opdraaien verkeersmaatregelen',
  'Afdraaien verkeersmaatregelen',
  'Wegdekreiniging',
  'Afhalen',
  'Monteur(s) + trekkend voertuig',
  'Diverse',
  'Materiaal ophalen bij verhuur leverancier',
  'Materiaal terugbrengen naar verhuur leverancier',
  'Materiaal ophalen bij vestiging',
  'Materiaal brengen naar vestiging',
]

// Filters users list, returning only those which are not used,
// either as a client or as employee
export let onlyUnusedUsers = (users: any, ignoreId: any) => {
  return users.filter((user: any) => {
    return user.id === ignoreId || (!user.client && !user.employee)
  })
}


// Turns a relative href of a resource to an absolute one
export let fromStorage = (href: any) => {
  return axios.defaults.baseURL ? axios.defaults.baseURL + href : href
}

export const getEquipmentPath = (image: any) => {
  return `/storage/equipment/${image}`
}

// Nice formatting of a file's size
export let formatBytes = (bytes: any, decimals: any) => {
  if (bytes === 0) return '0 Bytes'
  let k = 1024
  let dm = decimals || 2
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// Returns a google maps link for a given address
export let googleMapsSearch = (address: any) => {
  return address ? 'https://maps.google.com?q=' + address : null
}

// Removes the seconds from a datetime string
export let withoutSeconds = (datetime: any) => {
  if (!!datetime && datetime.length === 19 && moment(datetime).isValid()) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')
  } else {
    return datetime
  }
}

// Uppercase first letter of string/sentence
export let ucfirst = (text: any) => {
  return text[0].toUpperCase() + text.slice(1)
}

// Lowercase all letter of string/sentence
export let lcfirst = (text: any) => {
  return text[0].toLowerCase() + text.slice(1)
}

// Uppercases all first letters in words in a string/sentence
export let ucwords = (text: any) => {
  return text.split(' ').map((word: any) => {
    return ucfirst(word)
  }).join(' ')
}

export let toFixed = (value: any) => {
  return value && !isNaN(value) ? (Math.round(value * 4) / 4).toFixed(2) : null
}

export let dateTimeFormatByLocale = (value: any, format: string) => {
  const envLocale = import.meta.env.VITE_APP_I18N_LOCALE || 'nl'
  return moment(value).locale(envLocale).format(format)
}

export let shortDateFormat = (value: any) => {
  return moment(value).format('dd D MMM')
}

// Returns militaryish hour (09:30, 13:50 etc)
export let hourMinutesFromDateTime = function (datetimeIso: any) {
  return moment(datetimeIso).format('HH:mm')
}

// Returns day and month
export let dayMonthFromDateTime = function (datetimeIso: any) {
  return moment(datetimeIso).format('D MMM')
}

// Returns day, month and year and hour:minutes in human readable format
export let prettyDateFormat = function (datetimeIso: any) {
  // return moment(datetimeIso).format('D MMM YYYY HH:mm')
  return moment(datetimeIso).format('LLLL')
}

// Returns day, month and year in human readable format
export let prettyDateFormatWithoutHours = function (datetimeIso: any) {
  // return moment(datetimeIso).format('DD-MM-YYYY')
  return moment(datetimeIso).format('LLL')
}

// Returns time difference between two datetimeiso's
export let differenceFromDateTime = function (datetimeEnd: any, datetimeStart: any) {
  return moment.duration(moment(datetimeEnd).diff(moment(datetimeStart))).humanize()
}

// As found on https://stackoverflow.com/questions/7467840/nl2br-equivalent-in-javascript
export let nl2br = function (str: any, isXhtml: any) {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

// From UTC to Amsterdam
export let timezone = function (datetimeIso: any) {
  return moment.tz(datetimeIso, 'UTC').format('dd D MMM HH:mm')
}

export let formatLocalTime = function (localDatetime: any) {
  return moment.tz(localDatetime, 'Europe/Amsterdam').format('dd D MMM HH:mm')
}

export const capitalcase = function (string: string) {
  if(!string) {
    return '';
  }
  const capitalizedFirst = string.replace(/[A-Z]/g, ' $&').replace(/^./, str => str.toUpperCase())

  return capitalizedFirst;
}

export const standardPrefixFormat = function (prefix: string) {
  if(!prefix) {
    return '';
  }
  const pattern = /\d{4}-\d{2}-\d{2,}(\d{2,})/g;
  const prefixFormatted = prefix.match(pattern);

  if(prefixFormatted) {
    return prefixFormatted[0];
  }
}

export const ellipsis = function (string: string, length: number = 20) {
  if(!string) {
    return '';
  }
  return string.length > length ? string.substring(0, length) + '...' : string;
}

export default {
  onlyUnusedUsers,
  fromStorage,
  formatBytes,
  googleMapsSearch,
  withoutSeconds,
  ucfirst,
  ucwords,
  toFixed,
  shortDateFormat,
  nl2br,
  hourMinutesFromDateTime,
  dayMonthFromDateTime,
  differenceFromDateTime,
  timezone,
  capitalcase,
  getEquipmentPath,
  prettyDateFormatWithoutHours,
}

<template>
  <div>
    <v-card class="selectors" flat :border="true">
      <v-card-title>
        <v-autocomplete
          :label="t('employee')"
          :loading="temporaryData.employee.loading"
          cache-items
          :items="temporaryData.employee.list"
          :search-input.sync="temporaryData.employee.search"
          v-model="currentViewedEmployee"
          item-value="id"
          item-title="name"
          @update:model-value="triggerEmployeeChange"
          variant="underlined"
        ></v-autocomplete>
      </v-card-title>
    </v-card>
    <v-alert type="info" class="" v-if="!currentViewedEmployee">
      {{ t('employeeSelect') }}
    </v-alert>

    <v-form @submit.prevent="submit" ref="form" >
      <v-card flat v-if="currentViewedEmployee" class="overflow-visible" :border="true">
        <v-btn icon dark small color="orange-darken-2 add-timeoff" style="z-index: ;" v-if="!loadingSubmit && !loadingTimeoffItems" @click="addTimeoff">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-row wrap class="timeoff-item" v-for="(item, index) in timeoffItems" :key="index">
          <v-col sm="1" class="single-word">
            van
          </v-col>
          <!-- Date start -->
          <v-col  sm="2" >
            <Datepicker
              id="date_start"
              :placeholder="t('dateStart')"
              :model-value="item.datetime_start.date"
              v-on:update:value-datetime="item.datetime_start.date = $event"
              :maxDate="item.datetime_end.date ? item.datetime_end.date : null"
              @keyup.tab="focusNextInput('date_start')"
            />
          </v-col>
          <!-- Time start -->
          <v-col  sm="2" >
            <Timepicker
              id="time_start"
              :placeholder="t('timeStart')"
              :model-value="item.datetime_start.time"
              v-on:update:value-datetime="item.datetime_start.time = $event"
              @keyup.tab="focusNextInput('time_start')"
            />
          </v-col>
          <v-col sm="1" class="single-word">
            tot
          </v-col>

          <!-- Date end -->
          <v-col  sm="2" >
            <Datepicker
              id="date_end"
              :placeholder="t('dateEnd')"
              :model-value="item.datetime_end.date"
              v-on:update:value-datetime="item.datetime_end.date = $event"
              :minDate="item.datetime_start.date"
              @keyup.tab="focusNextInput('date_end')"
            />
          </v-col>

          <!-- Time end -->
          <v-col  sm="2" >
            <Timepicker
              id="time_end"
              :placeholder="t('timeEnd')"
              :model-value="item.datetime_end.time"
              v-on:update:value-datetime="item.datetime_end.time = $event"
            />
          </v-col>

          <v-col  sm="2"  class="button-wrapper">
            <v-btn v-on:click="deleteTimeoff(index)" icon flat color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>

          <!-- Reason -->
          <v-col sm="5" offset-sm="1">
            <v-text-field
              :label="t('reason')"
              v-model="item.reason"
              rows="1"
              multiLine
              autoGrow
              variant="underlined"
            ></v-text-field>
          </v-col>
          
        </v-row>
        <v-card-actions class="align-center justify-center">
          <div class="loading-wrapper" v-if="loadingTimeoffItems">
            <v-progress-circular indeterminate :size="70" :width="7" color="orange-darken-2"></v-progress-circular>
          </div>
          <v-btn color="white" class="bg-orange-darken-2" type="submit" v-if="!loadingSubmit && !loadingTimeoffItems">
            {{ t('save') }}
          </v-btn>
          <v-progress-circular indeterminate :size="30" :width="3" color="orange-darken-2" v-if="loadingSubmit"></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import { datetimeSplit, dateAndTimeToDatetime } from '@/helpers'
import { fetchEmployees, fetchTimeoff, saveTimeoff } from '@/api/employees'
import Datepicker from '@/components/UI/Datepicker.vue'
import Timepicker from '@/components/UI/Timepicker.vue'
import Say from '@/utils/Say'
import { onBeforeMount, defineComponent, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import store from '@/store'
import { focusNextInput } from '@/utils/functions'
import sayErrorResponse from '@/mixins/sayErrorResponse'

const route = useRoute()
const {t} = useI18n()
const router = useRouter()

defineComponent({
  Datepicker,
  Timepicker
})
const currentViewedEmployee = ref(parseInt(route.params.employeeId as string) || null as any)
const timeoffItems = ref([] as any[])
const loadingTimeoffItems = ref(false)
const loadingSubmit = ref(false)
const temporaryData = ref({
  employee: {
    search: null as any,
    loading: false,
    list: [] as any[]
  }
})
watch(currentViewedEmployee, () => {
  getData()
  timeoffItems.value = []
})
onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('leaveAndHolidays'))
  store.commit('app.setBreadcrumbs', [
    { text: t('employee', 2), routeName: 'Employees' },
    { text: t('leaveAndHolidays') }
  ])
})
onMounted(() => {
  getData()
  getEmployees(currentViewedEmployee.value)
})

const getData = () => {
  if (currentViewedEmployee.value) {
    loadingTimeoffItems.value = true
    fetchTimeoff(currentViewedEmployee.value).then((res) => {
      timeoffItems.value = res.data.map((item: any) => {
        item.datetime_start = datetimeSplit(item.datetime_start)
        item.datetime_end = datetimeSplit(item.datetime_end)
        return item
      })
      loadingTimeoffItems.value = false
    }).catch((err) => {
      loadingTimeoffItems.value = false
      sayErrorResponse(err)
    })
  }
}
const getEmployees = (value: any) => {
  temporaryData.value.employee.loading = true
  // We have to send null as string, otherwise axios won't send the value
  fetchEmployees({
    name: value,
    company: 'null',
    rowsPerPage: -1
  }).then((res) => {
    temporaryData.value.employee.loading = false
    temporaryData.value.employee.list = res.data.data
  }).catch((err) => {
    temporaryData.value.employee.loading = false
    sayErrorResponse(err)
  })
}
const triggerEmployeeChange = () => {
  router.replace({
    name: 'EmployeesTimeoff',
    params: {
      employeeId: currentViewedEmployee.value
    }
  })
}
const deleteTimeoff = (deleteIndex: any) => {
  let confirmAction = confirm(t('employeeTimeoffDeleteConfirm'))
  if (confirmAction) {
    timeoffItems.value = timeoffItems.value.filter((item: any, index: any) => {
      return index !== deleteIndex
    })
  }
}
const addTimeoff = () => {
  // Add new timeoff at beginning of list
  timeoffItems.value.unshift({
    datetime_start: {
      date: null,
      time: null
    },
    datetime_end: {
      date: null,
      time: null
    }
  })
}
const submit = () => {
  loadingSubmit.value = true
  let items = timeoffItems.value.map((item: any) => {
    return {
      datetime_start: dateAndTimeToDatetime(item.datetime_start.date, item.datetime_start.time),
      datetime_end: dateAndTimeToDatetime(item.datetime_end.date, item.datetime_end.time),
      reason: item.reason,
      id: item.id
    }
  })
  saveTimeoff(currentViewedEmployee.value, items).then((res) => {
    loadingSubmit.value = false
    if (res.data.addedIds) {
      for (let i = 0; i < res.data.addedIds.length; i++) {
        timeoffItems.value[i].id = res.data.addedIds[i]
      }
    }
    Say('success', res.data.message)
  }).catch((err) => {
    loadingSubmit.value = false
    sayErrorResponse(err)
  })
}
</script>

<style lang="scss">
  .card, .alert {
    margin-bottom: 10px;
  }
  .timeoff-item{
    align-items: center;
    padding: 15px 0;
    &:nth-of-type(odd) {
      background: #f5f5f5;
    }
    .single-word {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
    }
    .button-wrapper {
      text-align: center;
    }
  }
  .add-timeoff {
    position: absolute;
    top: -15px;
    left: -15px;
  }
</style>

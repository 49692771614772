<template>
  <v-card flat>
    <v-card-title>

      <!-- First header row -->
      <v-row wrap class="align-center">
        <v-col sm="3"  class="week-selector">
          <div class="btn-wrapper left">
            <v-btn outline size="small" icon="mdi-arrow-left" color="primary" :disabled="currentViewedWeek === 1" @click="changeWeek(currentViewedWeek - 1)"></v-btn>
          </div>
          <v-autocomplete
            :items="getWeekNumberSelectOptions()"
            v-model="currentViewedWeek"
            :label="t('week')"
            @input="triggerPeriodChange()"
            variant="underlined"
          ></v-autocomplete>
          <div class="btn-wrapper right">
            <v-btn outline icon="mdi-arrow-right" size="small" color="primary" :disabled="currentViewedWeek === 52" @click="changeWeek(currentViewedWeek + 1)"></v-btn>
          </div>
        </v-col>
        <v-col sm="1" offset-sm="1">
          <v-autocomplete
            :items="getYearSelectOptions()"
            v-model="currentViewedYear"
            :label="t('year')"
            @input="triggerPeriodChange()"
            variant="underlined"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2" offset-sm="1">
          <v-text-field
            disabled
            label="Periode"
            v-model="currentPeriod.description"
            variant="underlined"
          />
        </v-col>
        <v-col sm="2" class="checkbox-wrapper">
          <v-checkbox
            v-model="weekFrozen"
            :label="weekFrozen ? 'Invoer NIET meer mogelijk' : 'Invoer mogelijk'"
            @change="toggleWeekFrozen"
          />
        </v-col>
        <v-col sm="2" class="text-end">
          <v-btn variant="outlined" color="grey" disabled class="mr-2">
            PDF
          </v-btn>
          <v-btn variant="outlined" color="orange-darken-2" @click="triggerDownload('xlsx')">
            XLSX
          </v-btn>
        </v-col>
      </v-row>
      <!-- Second header row -->
      <v-row wrap>
        <v-col sm="3" >
          <v-text-field
            append-icon="search"
            :label="t('search')"
            single-line
            hide-details
            v-model="search"
            variant="underlined"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-progress-circular indeterminate color="primary" v-show="loading"></v-progress-circular>
      <v-table class="table-overview" cellspacing="0" cellpadding="0" v-if="!loading">
        <thead>
          <tr>
            <th>W{{ currentViewedWeek }}</th>
            <th colspan="7"></th>
            <th>pauze</th>
            <th>uren</th>
            <th>overuren</th>
            <th>verlof</th>
            <th>piket?</th>
            <th>vrij?</th>
            <th>ziek?</th>
            <th>bijz. verlof?</th>
            <th>feestdag?</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in searchedItems" :key="index">
          <tr v-if="!isExpanded(item)">
            <td>
              {{ item.name }}
              <v-btn class="ml-2" color="primary" flat size="x-small" icon @click="triggerEmployeeInfoModal(item)">
                <v-icon>mdi-information-variant</v-icon>
              </v-btn>
            </td>
            <td colspan="7">{{ employeesSummary[index].daysWorked }} dagen gewerkt</td>
            <td>{{ toFixed(employeesSummary[index].pause)  }}</td>
            <td>{{ toFixed(employeesSummary[index].regular)  }}</td>
            <td>{{ toFixed(employeesSummary[index].overtime)  }}</td>
            <td>{{ toFixed(employeesSummary[index].freeSickSpecial)  }}</td>
            <td>
              <span v-if="employeesSummary[index].isPiket">&#10003;</span>
            </td>
            <td>
              <span v-if="employeesSummary[index].isFree">&#10003;</span>
            </td>
            <td>
              <span v-if="employeesSummary[index].isSick">&#10003;</span>
            </td>
            <td>
              <span v-if="employeesSummary[index].isSpecial">&#10003;</span>
            </td>
            <td>
              <span v-if="employeesSummary[index].isHoliday">&#10003;</span>
            </td>
            <td>
              <v-btn icon="mdi-chevron-down" size="small" flat color="grey" v-if="!isExpanded(item)" @click="triggerExpandItem(item)"></v-btn>
            </td>
          </tr>
          <tr v-else class="expanded">
            <td colspan="5">{{ item.name }}</td>
            <td class="time">begin</td>
            <td class="time">eind</td>
            <td>omschrijving</td>
            <td>pauze</td>
            <td>uren</td>
            <td>overuren</td>
            <td>verlof</td>
            <td>piket?</td>
            <td>vrij?</td>
            <td>ziek?</td>
            <td>bijz. verlof?</td>
            <td>feestdag?</td>
            <td>
              <v-btn icon="mdi-chevron-up" size="small" flat color="grey" v-if="isExpanded(item)" @click="triggerExpandItem(item)"></v-btn>
            </td>
          </tr>
          <template v-for="(day, dayIndex) in currentPeriod.days" v-if="isExpanded(item)" :key="dayIndex">
            <tr :class="`expanded-data ${isOnHoliday(item, dayIndex) ? 'holiday' : ''}`">
              <td :rowspan="item.days[dayIndex].length + 1"></td>
              <td :rowspan="item.days[dayIndex].length + 1" class="align-top day-of-month">
                {{ day }}
              </td>
              <td :rowspan="item.days[dayIndex].length + 1" class="align-top day-of-week">
                {{ currentPeriod.daysOfWeek[dayIndex] }}
              </td>
              <td :rowspan="item.days[dayIndex].length + 1" class="entry-create">
                <v-btn size="small" icon flat color="green" class="btn-add" @click="createEntry(item.days[dayIndex])">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td v-if="item.days[dayIndex].length === 0" colspan="14"></td>
            </tr>
            <tr class="expanded-data" v-for="(entry, entryIndex) in item.days[dayIndex]" :key="`e${dayIndex+''+entryIndex}`">
              <td>
                <v-btn size="small" icon flat color="red" class="btn-remove" @click="removeEntry(item.days[dayIndex], entryIndex)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
              <td class="time">
                <v-text-field
                  v-model="entry.time_start"
                  mask="time"
                  hide-details
                />
              </td>
              <td class="time">
                <v-text-field
                  v-model="entry.time_end"
                  mask="time"
                  hide-details
                />
              </td>
              <td>
                <v-text-field
                  v-model="entry.description"
                  hide-details
                  :placeholder="t('description')"
                />
              </td>
              <td class="time">
                <v-text-field
                  v-model="entry.pause"
                  hide-details
                  return-masked-value
                  mask="#.#"
                  :disabled="entry.entry_type !== 'WORK'"
                />
              </td>
              <td class="calculated">
                {{ toFixed(calculateForEntry('regular', entry, dayIndex))  }}
              </td>
              <td class="calculated">
                {{ toFixed(calculateForEntry('overtime', entry, dayIndex))  }}
              </td>
              <td class="calculated">
                {{ toFixed(calculateForEntry('freeSickSpecial', entry, dayIndex))  }}
              </td>
              <td>
                <v-checkbox
                  hide-details
                  v-model="entry.is_piket"
                  @change="onPiketChange(entry)"
                />
              </td>
              <td>
                <v-checkbox
                  hide-details
                  v-model="entry.entry_type"
                  value="FREE"
                  @change="onEntryTypeChange(entry)"
                />
              </td>
              <td>
                <v-checkbox
                  hide-details
                  v-model="entry.entry_type"
                  value="SICK"
                  @change="onEntryTypeChange(entry)"
                />
              </td>
              <td>
                <v-checkbox
                  hide-details
                  v-model="entry.entry_type"
                  value="SPECIAL"
                  @change="onEntryTypeChange(entry)"
                />
              </td>
              <td>
                <v-checkbox
                  hide-details
                  v-model="entry.is_holiday"
                />
              </td>
              <td></td>
            </tr>
          </template>
          <tr class="expanded-data" v-if="isExpanded(item)">
            <td colspan="9" class="timeoff">
              <p><strong>Verlof en vakanties deze week</strong></p>
              <p v-if="item.timeoff.length === 0">Geen</p>
              <ul v-else>
                <li v-for="(timeoff, toIndex) in item.timeoff" :key="toIndex">
                  {{ shortDateFormat(timeoff.datetime_start)  }} t/m {{ shortDateFormat(timeoff.datetime_end)  }}
                </li>
              </ul>
            </td>
            <td colspan="9" class="buttons">
              <v-btn color="white" v-if="expandedItemChanged && !expanded.loading" @click="resetExpandedItem">
                Wijzigingen ongedaan
              </v-btn>
              <v-btn color="primary" v-if="expandedItemChanged && !expanded.loading" @click="saveEmployeeWeek(item)">
                Wijzigingen opslaan
              </v-btn>
              <v-btn color="grey" disabled v-if="!expandedItemChanged && !expanded.loading">
                Geen wijzigingen
              </v-btn>
              <v-progress-circular indeterminate color="primary" v-show="expanded.loading" v-if="expanded.loading"></v-progress-circular>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>

    <!-- Contact add dialog -->
    <v-dialog v-model="employeeInfoModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h2>{{ employeeInfo.name }}</h2>
        </v-card-title>
        <v-card-text>
          <p>
            {{ t('phone') }}: <strong>{{ employeeInfo.phone }}</strong>
          </p>
          <p>
            {{ t('address') }}:<br />
            <strong v-html="nl2br(employeeInfo.address, null)"></strong>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click.stop="employeeInfo = {}; employeeInfoModal = false;">
            {{ t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script setup lang="ts">
import { isEqual, range } from 'lodash-es'
import moment from 'moment'
import { fetchWeek, saveEmployeeHours, fetchWeekStatus, setWeekStatus, generateWeekExport } from '@/api/employees'
import { getDaysOfWeek, getWeekNumberSelectOptions, getYearSelectOptions } from '@/helpers'
import PreventNavigation from '@/utils/PreventNavigation'
import Say from '@/utils/Say'
import { onBeforeMount, onMounted, computed, ref, watch } from 'vue'
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import store from '@/store'
import { shortDateFormat, toFixed, nl2br } from '@/utils/filters'
import sayErrorResponse from '@/mixins/sayErrorResponse'
 
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

// TODO; should use VITE_APP_I18N_LOCALE
moment.locale('nl')

const currentViewedYear = ref(route.params.year ? parseInt(route.params.year as string) : moment().year())
const currentViewedWeek = ref(route.params.week ? parseInt(route.params.week as string) : moment().week() - 1)
const search = ref('')
const loading = ref(false)
const items = ref([] as any)
const employeeInfoModal = ref(false)
const employeeInfo = ref({} as Record<string, undefined>)
const weekFrozen = ref(false)
const initialState = ref([] as any)
const expanded = ref({
  id: null as any,
  loading: false,
  initial: {} as any
})

const currentPeriod = computed(() => {
  let monday = moment().year(currentViewedYear.value).week(currentViewedWeek.value).startOf('week')
  let sunday = moment().year(currentViewedYear.value).week(currentViewedWeek.value).endOf('week')
  return {
    description: monday.format('D MMM') + ' - ' + sunday.format('D MMM'),
    daysOfWeek: range(1, 8).map((day) => {
      return moment().year(currentViewedYear.value).week(currentViewedWeek.value).day(day).format('dd')
    }),
    days: range(1, 8).map((day) => {
      return moment().year(currentViewedYear.value).week(currentViewedWeek.value).day(day).format('D MMM')
    }),
    daysIso: range(1, 8).map((day) => {
      return moment().year(currentViewedYear.value).week(currentViewedWeek.value).day(day).format('YYYY-MM-DD')
    })
  }
})
const employeesSummary = computed(() => {
  return searchedItems.value.map((employee: any) => {
    return calculateEmployeeSummary(employee.days)
  })
})
const expandedItemChanged = computed(() => {
  let item = items.value.filter((item: any) => {
    return item.id === expanded.value.initial.id
  })[0]
  return !isEqual(expanded.value.initial, item)
})
const searchedItems = computed(() => {
  return items.value.filter((item: any) => {
    return item.name.toLowerCase().search(search.value.toLowerCase()) !== -1
  })
})

watch(currentViewedYear, () => {
  getWeekOverview()
})
watch(currentViewedWeek, () => {
  getWeekOverview()
})

onBeforeMount(() => {
  getDaysOfWeek().map((day) => {
    let dayShortName = t(day).substring(0, 2)
    return dayShortName[0].toUpperCase() + dayShortName[1]
  })
  store.commit('app.setPageTitle', t('weekOverview'))
  store.commit('app.setBreadcrumbs', [
    { text: t('employee', 2), routeName: 'Employees' },
    { text: t('weekOverview') }
  ])
})
onMounted(() => {
  // Fetch the data
  getWeekOverview()
})
// Bind app route navigation confirmation
onBeforeRouteUpdate((to, from, next) => {
  PreventNavigation.inVueRouter(initialState.value, items.value, next)
})
onBeforeRouteLeave((to, from, next) => {
  PreventNavigation.inVueRouter(initialState.value, items.value, next)
})

const changeWeek = (weekNr: any) => {
  currentViewedWeek.value = weekNr
  triggerPeriodChange()
}
const clearExpanded = () => {
  expanded.value = {
    loading: false,
    id: null,
    initial: {}
  }
}
const getWeekOverview = () => {
  loading.value = true
  fetchWeekStatus(currentViewedYear.value, currentViewedWeek.value)
    .then((res) => {
      weekFrozen.value = res.data.frozen
    })
  fetchWeek(currentViewedYear.value, currentViewedWeek.value)
    .then((res) => {
      items.value = res.data.map((item: any) => {
        item.days = Object.values(item.days)
          .map((day: any) => {
            return day.map((entry: any) => {
              entry.pause = parseFloat(entry.pause).toFixed(1)
              return entry
            })
          })
        return item
      })
      /**
       * Old value, but it is not working with the prevent navigation:
       * JSON.parse(JSON.stringify(items)).
       * New value is not checking the differences, cause is an array of proxy object.
       * ToDo: find a value to pass that can work with prevent navigation
       */
      initialState.value = items.value
      loading.value = false
    })
}
const triggerPeriodChange = () => {
  router.push({
    name: 'EmployeesWeekOverview',
    params: {
      year: currentViewedYear.value,
      week: currentViewedWeek.value
    }
  })
}
const triggerExpandItem = (item: any) => {
  // Check if the expanded item has been changed and make them confirm navigating away
  if (expanded.value.id !== null && expandedItemChanged.value) {
    const confirmAction = confirm('Are you sure? Your changes have not been saved yet.')
    if (confirmAction) {
      setExpandItem(item)
    }
  } else {
    setExpandItem(item)
  }
}
const setExpandItem = (item: any) => {
  let clonedItem = JSON.parse(JSON.stringify(item))
  if (item.id === expanded.value.id) {
    clearExpanded()
  } else {
    expanded.value.id = item.id
    expanded.value.initial = clonedItem
  }
}
const resetExpandedItem = () => {
  let expandedItemId = expanded.value.id
  items.value = items.value.map((item: any) => {
    if (item.id === expandedItemId) {
      return JSON.parse(JSON.stringify(expanded.value.initial))
    }
    return item
  })
}
const isExpanded = (item: any) => {
  return item.id === expanded.value.id
}
const triggerEmployeeInfoModal = (employee: any) => {
  employeeInfo.value = employee
  employeeInfoModal.value = true
}
const onPiketChange = (entry: any) => {
  // If marking this is a piket, automatically move its type to WORK
  if (entry.is_piket) {
    entry.entry_type = 'WORK'
  }
}
const onEntryTypeChange = (entry: any) => {
  if (entry.entry_type === null) {
    entry.entry_type = 'WORK'
  } else {
    // If not switching to WORK, make piket false
    entry.is_piket = false
  }
}
const createEntry = (day: any) => {
  day.push({
    entry_type: 'WORK',
    time_start: '08:00',
    time_end: '16:00',
    pause: '0.0'
  })
}
const removeEntry = (day: any, entryIndex: any) => {
  day.splice(entryIndex, 1)
}
const calculateEmployeeSummary = (days: any) => {
  let daysWorked = 0
  let pause = 0
  let regular = 0
  let overtime = 0
  let freeSickSpecial = 0
  let isPiket = false
  let isFree = false
  let isSick = false
  let isSpecial = false
  let isHoliday = false
  for (let dayIndex in days) {
    for (let entry of days[dayIndex]) {
      // Sums
      if (entry.entry_type === 'WORK') {
        regular += calculateForEntry('regular', entry, dayIndex)
        overtime += calculateForEntry('overtime', entry, dayIndex)
        // pause += entry.pause
        daysWorked++
      } else {
        freeSickSpecial += calculateForEntry('freeSickSpecial', entry, dayIndex)
      }
      // Flags
      if (entry.entry_type === 'SPECIAL') {
        isSpecial = true
      }
      if (entry.entry_type === 'FREE') {
        isFree = true
      }
      if (entry.entry_type === 'SICK') {
        isSick = true
      }
      if (entry.is_piket) {
        isPiket = true
      }
      if (entry.is_holiday) {
        isHoliday = true
      }
    }
  }
  return { daysWorked, pause, regular, overtime, freeSickSpecial, isPiket, isFree, isSick, isSpecial, isHoliday }
}
const calculateForEntry = (what: any, entry: any, dayIndex: any) => {
  const isWeekend = dayIndex === 5 || dayIndex === 6
  const isHoliday = entry.is_holiday
  // The Date formatting in the start and end is weird like that (HHmm instead of HH:mm)
  // Because we can't use the return-masked-value attribute of the vuetify text field
  // It would return HH:mm correctly if there's a value in there, but otherwise it updates the model to false
  // Which in turn tells us there have been changes made to our item, when in fact there haven't been made any
  //
  // There's also a random date added in there otherwise the duration can't be calculated since moment
  // can't assume the hours belong to the same day.
  const start = entry.time_start ? moment('2018-01-01 ' + entry.time_start + ':00', 'YYYY-MM-DD HHmm') : null
  const end = entry.time_end ? moment('2018-01-01 ' + entry.time_end + ':00', 'YYYY-MM-DD HHmm') : null
  const duration = start && end ? moment.duration(end.diff(start)).asHours() : null as any

  switch (what) {
    case 'regular':
      if (entry.entry_type === 'WORK' && !isWeekend && !isHoliday) {
        return duration - entry.pause < 8 ? duration - entry.pause : 8
      }
      break
    case 'overtime':
      if (entry.entry_type === 'WORK') {
        if (isWeekend || isHoliday) {
          return duration - entry.pause
        } else if (duration - entry.pause > 8) {
          return duration - entry.pause - 8
        }
      }
      break
    case 'freeSickSpecial':
      if (entry.entry_type === 'SPECIAL' || entry.entry_type === 'SICK' || entry.entry_type === 'FREE') {
        return isNaN(duration) ? 8 : duration
      }
      break
  }

  return 0
}
const isOnHoliday = (employee: any, dayIndex: any) => {
  let isHoliday = false
  let timespans = employee.timeoff.map((timeoff: any) => {
    return {
      start: timeoff.datetime_start,
      end: timeoff.datetime_end
    }
  })
  for (let timespan of timespans) {
    if (moment(currentPeriod.value.daysIso[dayIndex]).isBetween(timespan.start, timespan.end, 'day', '[]')) {
      isHoliday = true
    }
  }
  return isHoliday
}
const toggleWeekFrozen = (value: any) => {
  setWeekStatus(currentViewedYear.value, currentViewedWeek.value, {
    frozen: value
  })
}
const saveEmployeeWeek = (item: any) => {
  let days = item.days.map((day: any, dayIndex: number) => {
    let entries = []
    for (let entry of day) {
      entries.push({
        id: entry.id,
        entry_type: entry.entry_type,
        date_iso: currentPeriod.value.daysIso[dayIndex],
        time_start: entry.time_start ? moment(entry.time_start, 'HHmm').format('HH:mm') : null,
        time_end: entry.time_end ? moment(entry.time_end, 'HHmm').format('HH:mm') : null,
        pause: entry.pause,
        description: entry.description,
        flag_vda_piket: entry.is_piket,
        flag_holiday: entry.is_holiday
      })
    }
    return {
      iso: currentPeriod.value.daysIso[dayIndex],
      entries
    }
  })
  expanded.value.loading = true
  saveEmployeeHours(item.id, days)
    .then((res) => {
      Say('success', 'Success!')
      expanded.value.initial = JSON.parse(JSON.stringify(item))
      expanded.value.loading = false
    })
    .catch((err) => {
      sayErrorResponse(err)
      expanded.value.loading = false
    })
}
const triggerDownload = (type: any) => {
  generateWeekExport(currentViewedYear.value, currentViewedWeek.value, type)
    .then((res) => {
      const downloadURL = process.env.BASE_API_URL + res.data.filepath
      window.open(downloadURL, '_blank', 'noopener')
    })
    .catch((err) => {
      sayErrorResponse(err)
    })
}

</script>

<style lang="scss">
@import "./WeekOverview.scss"
</style>

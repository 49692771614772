<template>
  <v-card>
    <v-card-title>
      <h2>{{ ucfirst(t('addLocation')) }}</h2>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="6">

          <v-row>
            <v-col md="12">
              <v-select :label="$t('type', 1)" v-model="type" :items="types" item-title="label" item-value="type" />
            </v-col>
            <v-col md="12" class="search-location-wrapper">
              <v-text-field :label="$t('search')" v-model="searchLocation"
                @keyup="handleSearchInput()" ref="searchLocationInput"/>
              <div v-if="autocompleteResults || geocodeResultsAsAutocomplete" id="autocompleteResults">
                <div v-if="autocompleteResults" v-for="result in autocompleteResults" class="autocompleteResult"
                  @click="getGeocodeLocation(result.placePrediction.text.text, 'address', true, true, null, false)">
                  {{ result.placePrediction.text.text }}
                </div>
                <div v-if="geocodeResultsAsAutocomplete" v-for="result in geocodeResultsAsAutocomplete" class="autocompleteResult"
                  @click="setGeocodeLocation(result)">
                  {{ buildLocationString(result) }}
                </div>
              </div>
            </v-col>
            <v-col md="12" id="map-wrapper">
              <div id="map"></div>
            </v-col>
          </v-row>

        </v-col>
        <v-col md="6">

          <v-row>
            <v-col v-if="similarLocations && similarLocations.length > 0" md="12">
              <h3 id="locations-form-subtitle">{{ t('similarExistingLocationsOfThisClient', 1) }}</h3>
              <p>{{ t('clickOnExistingLocationToLinkItToWorkOrder', 1) }}</p>
              <div id="similarLocations">
                <div v-for="result in similarLocations">
                  <div class="similarLocation" @click="linkExistingLocationToWorkOrder(result)">
                    {{ result.location }}
                  </div>
                  <div class="similarLocationsWrapper">
                    <div class="similarLocationsInner">
                      <div v-if="result.work_orders && result.work_orders.length > 0" v-for="work_order in result.work_orders" class="similarLocationsWorkOrders">
                        <router-link :to="{ name: 'WorkOrdersView', params: { id: work_order.id } }" target="_blank">
                          <v-icon>mdi-car-estate</v-icon> {{ work_order.id }} <v-icon class="openInNew">mdi-open-in-new</v-icon>
                        </router-link>
                      </div>
                      <div v-else class="similarLocationsNoWorkOrder">
                        <v-icon>mdi-car-estate</v-icon> <i>{{ t('notLinkedToWorkOrder') }}</i>
                      </div>
                      <div class="clear"></div>
                    </div>
                    <div class="similarLocationsFade"></div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col md="12">
              <h3 id="locations-form-subtitle">{{ t('locationDataThatWillBeSaved', 1) }}</h3>
            </v-col>
            <v-col md="12" v-if="type === 'intersection'">
              <v-text-field :label="$t('intersection', 1)" v-model="intersection" />
            </v-col>
            <v-col md="12" v-if="type === 'address' || type === 'custom'">
              <v-text-field :label="$t('name', 1)" v-model="customName" />
            </v-col>
            <v-col md="8" v-if="type === 'address' || type === 'custom'">
              <v-text-field :label="$t('streetOrRoad', 1)" v-model="street"/>
            </v-col>
            <v-col md="4" v-if="type === 'address' || type === 'custom'">
              <v-text-field :label="$t('streetNumber', 1)" v-model="streetNumber" />
            </v-col>
            <v-col md="5">
              <v-text-field :label="$t('postcode', 1)" v-model="postcode" />
            </v-col>
            <v-col md="7">
              <v-text-field :label="$t('city', 1)" v-model="city" />
            </v-col>
            <v-col md="12">
              <v-text-field :label="$t('additionalExplanation', 1)" v-model="description" />
            </v-col>
            <v-col md="6">
              <v-text-field :label="$t('latitude', 1)" v-model="latitude" disabled />
            </v-col>
            <v-col md="6">
              <v-text-field :label="$t('longitude', 1)" v-model="longitude" disabled />
            </v-col>
            <!-- <v-col md="12">
              <v-icon>mdi-information-variant</v-icon> {{ $t('useTabToContinue') }}
            </v-col> -->
          </v-row>

        </v-col>
      </v-row>
      <hr class="divider"/>
    </v-card-text>
    <v-card-actions>
      <v-progress-circular indeterminate color="orange-darken-2" v-show="loading"></v-progress-circular>
      <div v-if="!loading">
        <v-btn color="orange-darken-2" depressed @click.stop="validateLocation()">
          {{ $t('add') }}
        </v-btn>
        <v-btn color="primary" flat @click.stop="$emit('close')">
          {{ $t('cancel') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import Say from '@/utils/Say'
import { createLocation } from '@/api/locations'
import { autocompleteLocation, geocodeLocation, googlePlaceDetails } from '@/api/locations'
import { onMounted, ref } from 'vue';
import { ucfirst } from '@/utils/filters';
import emitArray from '@/utils/emit';
import { useI18n } from 'vue-i18n';
import sayErrorResponse from '@/mixins/sayErrorResponse';

import { useEventBus } from '@vueuse/core'
import { Loader } from "@googlemaps/js-api-loader"
import { debounce } from 'lodash';

const createdBus = useEventBus('created');

const emit = defineEmits(emitArray.value)
const { t } = useI18n()
const props: any = defineProps({
  projectId: {
    type: Number,
    required: false,
    default: null
  },
  clientId: {
    type: Number,
    required: false,
    default: null
  },
  onInput: {
    type: Function,
    required: true
  },
})

const types = [
  {
    type: 'address',
    label: t('address')
  },
  // {
  //   type: 'street',
  //   label: t('Street')
  // },
  {
    type: 'intersection',
    label: t('intersection')
  },
  {
    type: 'custom',
    label: t('custom')
  }
]

const loading = ref(false)
const searchLocation = ref(null as String | null)
const autocompleteResults = ref(null as any)
const geocodeResultsAsAutocomplete = ref(null as any)
const type = ref(types[0].type as any)
const customName = ref(null as String | null)
const intersection = ref(null as any)
const street = ref(null as any)
const streetNumber = ref(null as any)
const postcode = ref(null as any)
const city = ref(null as any)
const description = ref(null as any)
const latitude = ref(null as any)
const longitude = ref(null as any)
const similarLocations = ref(null as any)
const declinedToChangeTypeToIntersection = ref(false)

let mapMarkers = Array()

onMounted(() => {
  // location = null
  loading.value = false
  googleMapsInit()
  document.body.addEventListener('click', handleClickOutside)
})

const handleClickOutside = (event: MouseEvent) => {
  const autocompleteResultsDiv = document.getElementById('autocompleteResults')
  // Check if the click target is outside the autocompleteResultsDiv
  if (autocompleteResultsDiv && !autocompleteResultsDiv.contains(event.target as Node)) {
    // Set autocompleteResults to null
    autocompleteResults.value = null
    geocodeResultsAsAutocomplete.value = null
  }
}

const saveLocation = async () => {
  loading.value = true
  try {

    if(type.value === 'intersection') {
      customName.value = null
      street.value = null
      streetNumber.value = null
    } else if(type.value === 'address' || type.value === 'custom') {
      intersection.value = null
    }

    const response = await createLocation({
      type: type.value,
      name: customName.value,
      intersection: intersection.value,
      street: street.value,
      street_number: streetNumber.value,
      postcode: postcode.value,
      city: city.value,
      description: description.value,
      latitude: latitude.value,
      longitude: longitude.value,
      project_id: props.projectId,
      client_id: props.clientId
    })

    loading.value = false
    Say('success', 'Success!')
    emit('created', response.data)
    createdBus.emit({ type: 'location', data: response.data })
    emit('close')
  } catch (err: any) {
    loading.value = false
    sayErrorResponse(err)
  }
}

const linkExistingLocationToWorkOrder = (location: any) => {
    createdBus.emit({ type: 'location', data: location })
    emit('close')
}

const validateLocation = () => {
  type.value = type.value.trim()
  customName.value = customName.value?.trim() || null
  intersection.value = intersection.value?.trim() || null
  street.value = street.value?.trim() || null
  streetNumber.value = streetNumber.value?.trim() || null
  postcode.value = postcode.value?.trim() || null
  city.value = city.value?.trim() || null
  description.value = description.value?.trim() || null

  if (type.value === 'intersection') {
    if(!intersection.value || !intersection.value.match(/^.*&.*$/)) {
      Say('error', t('pleaseFillInAValidIntersection'))
      return
    }
  } else if (type.value === 'address') {
    if(!street.value || street.value === '') {
      Say('error', t('pleaseFillInAStreet'))
      return
    }

    if (!city.value || city.value === '') {
      Say('error', t('pleaseFillInCity'))
      return
    }

  } else if (type.value === 'custom') {
    if(
      ( !customName.value || customName.value === '') &&
      ( !street.value || street.value === '')
    ) {
      Say('error', t('pleaseFillInANameOrStreet'))
      return
    }
  }

  if (postcode.value && postcode.value !== '') {
    if(!postcode.value.match(/^\d{4}(?:\s?[a-zA-Z]{2})?$/)){
      Say('error', t('pleaseFillInValidPostcode'))
      return
    }
    
    if(postcode.value.indexOf(' ') === -1) {
      postcode.value = postcode.value.slice(0, 4) + ' ' + postcode.value.slice(4).toUpperCase()
    }
  }

  if (!latitude.value || latitude.value === '' || !longitude.value || longitude.value === '') {
    Say('error', t('pleaseFillInLatitudeAndLongitude'))
    return
  }

  if(!props.clientId && !customName.value) {
    Say('error', t('pleaseFillInName'))
    return
  }

  saveLocation()
}

const getAutocompleteLocation = async () => {

  if (searchLocation.value) {
    if(
      type.value !== 'intersection' && (
        searchLocation.value.match(/^.*&.*$/) || 
        searchLocation.value.match(/^.*\/.*$/) || 
        searchLocation.value.match(/^.*\sen\s.*$/) || 
        searchLocation.value.match(/^.*\sand\s.*$/)
      )
      ) {
      if(!declinedToChangeTypeToIntersection.value) {
        if(confirm(t('changeTypeToIntersection'))) {
          type.value = 'intersection'
        } else {
          declinedToChangeTypeToIntersection.value = true
        }
      }
    }

    if (type.value === 'address' || type.value === 'custom') {
      try {

        // Call API to autocomplete location
        const response = await autocompleteLocation({
          address: searchLocation.value + ', Nederland'
        });

        autocompleteResults.value = response.data.suggestions;
      } catch (err: any) {
        sayErrorResponse(err)
      }
    } else if (type.value === 'intersection') {
      getGeocodeLocation(searchLocation.value, 'address', false, true, null, true)
    }
  }
};

// Define a debounced version of the getAutocompleteLocation function
const debouncedGetAutocompleteLocation = debounce(getAutocompleteLocation, 500);

// Replace the @keyup event handler with the debounced function
const handleSearchInput = () => {
  debouncedGetAutocompleteLocation();
}

const getGeocodeLocation = async (dataToGeocode: any, dataType:any, setTypeToAddress: any, refreshLatLong: any, placeId: any, asAutocomplete: any) => {

  if(setTypeToAddress === true || placeId) {
      // set type to address
      type.value = 'address'
  }


  if(!placeId) {
    searchLocation.value = dataToGeocode
  }
  if(!asAutocomplete) {
    autocompleteResults.value = null
    geocodeResultsAsAutocomplete.value = null
    similarLocations.value = null
  }

  if (dataToGeocode) {
    try {

      let response = null

      if(placeId) {
        response = await googlePlaceDetails({
            place_id: placeId,
            client_id: props.clientId,
            format: true
        });
      } else {
        response = await geocodeLocation({
          dataToGeocode: dataToGeocode,
            client_id: props.clientId,
          dataType: dataType,
          format: true
        });
      }

      if(asAutocomplete) {
        if(response.data.intersection) {
          geocodeResultsAsAutocomplete.value = []
          geocodeResultsAsAutocomplete.value.push(response.data)
        }
        return
      }

      if (type.value === 'address' || type.value === 'custom') {
        street.value = response.data.street
        streetNumber.value = response.data.street_number
        postcode.value = response.data.postcode
        city.value = response.data.city
      } else if (type.value === 'intersection') {
        intersection.value = response.data.intersection
        postcode.value = response.data.postcode
        city.value = response.data.city
      }

      if(type.value !== 'intersection') {
        if(!street.value || street.value === '') {
          type.value = 'custom'
        } else {
          type.value = 'address'
        }
      }

      customName.value = ''

      const addressParts = getAddressParts(dataToGeocode)

      if(placeId) {
        if(response.data.name) {
          customName.value = response.data.name
        }
      } else if (addressParts.length > 3) {
        customName.value = addressParts.slice(0, addressParts.length - 3).join(', ')
      } else if (addressParts.length === 3) {
        if(!addressParts[0].startsWith(street.value + '')) {
          customName.value = addressParts[0]
        }
      }

      similarLocations.value = response.data.similar_locations

      if(!refreshLatLong) {
        return;
      }
      latitude.value = response.data.latitude
      longitude.value = response.data.longitude

      setMapMarker({ lat: response.data.latitude, lng: response.data.longitude })

      // Center map to the new location
      map.setCenter({ lat: response.data.latitude, lng: response.data.longitude })

      // Set map zoom to 15
      map.setZoom(15)

    } catch (err: any) {
      sayErrorResponse(err)
    }
  }
};

const setGeocodeLocation = (location: any) => {
  similarLocations.value = location.similar_locations
  if(location.intersection) {
    intersection.value = location.intersection
    postcode.value = location.postcode
    city.value = location.city
  } else if(location.type === 'address' || location.type === 'custom') {
    customName.value = location.name
    street.value = location.street
    streetNumber.value = location.street_number
    postcode.value = location.postcode
    city.value = location.city
  }

  latitude.value = location.latitude
  longitude.value = location.longitude

  setMapMarker({ lat: location.latitude, lng: location.longitude })

  // Center map to the new location
  map.setCenter({ lat: location.latitude, lng: location.longitude })

  // Set map zoom to 15
  map.setZoom(15)

  autocompleteResults.value = null
  geocodeResultsAsAutocomplete.value = null
}

const buildLocationString = (location: any) => {
  if(location.intersection) {
    return location.intersection + ', ' + location.postcode + ' ' + location.city
  } else if(location.type === 'address' || location.type === 'custom') {
    return location.street + ' ' + location.street_number + ', ' + location.postcode + ' ' + location.city
  }
}


// function to get first part of the address
const getAddressParts = (address: any) => {
  return address.split(',')
}

const language = () => {
  switch (import.meta.env.VITE_APP_I18N_LOCALE) {
    case 'en':
      return 'en-GB'
    case 'nl':
      return 'nl-NL'
    default:
      return 'en-GB'
  }
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  language: language(),
});

let map: any = null

const googleMapsInit = async () => {

  const GoogleMaps = await loader.importLibrary('maps')

  map = new GoogleMaps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 51.6340706, lng: 5.2135066 },
    zoom: 7,
    mapId: import.meta.env.VITE_GOOGLE_MAPS_MAP_ID
  });

  // Add click event listener to the map
  map.addListener('click', (event: any) => {

    getGeocodeLocation(event.latLng.lat() + ',' + event.latLng.lng(), 'latlng', false, false, event.placeId, false)

    // Round the latitude and longitude to 7 decimals
    latitude.value = event.latLng.lat().toFixed(7)
    longitude.value = event.latLng.lng().toFixed(7)

    // Call the setMapMarker function with the clicked coordinates
    setMapMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    })
  });
}

// function to get first part of the address
const setMapMarker = async (latLng: any) => {
  const GoogleMapsMarker = await loader.importLibrary('marker')

  // Remove the previous markers if they exist
  if (mapMarkers) {
    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].setMap(null);
      mapMarkers = []
    }
  }

  // Create a new marker using GoogleMapsMarker
  const marker = new GoogleMapsMarker.AdvancedMarkerElement({
    position: latLng,
    map,
  });

  // Store a reference to the new marker in the mapMarkers array
  mapMarkers.push(marker)
}
</script>
<style>
#map-wrapper {
  margin-top: -46px;
  margin-bottom: 30px;
}
#map {
  height: 425px;
}
#locations-form-subtitle {
  margin-bottom: 5px;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.search-location-wrapper {
  position: relative;
  margin-top: -20px;
}
#autocompleteResults, #similarLocations {
  background-color: #fff;
  width: 95%;
}
#autocompleteResults {
  margin-top: -22px;
  position: absolute;
  z-index: 5;
}
#similarLocations {
  margin-top: 20px;
}
.autocompleteResult, .similarLocation {
  border: 1px solid #f0f0f0;
  padding: 10px;
  cursor: pointer;
}
.autocompleteResult:hover,
.autocompleteResult:focus,
.similarLocation:hover,
.similarLocation:focus {
  background-color: #f0f0f0;
}
.similarLocation {
  border-color: rgba(0, 0, 0, 0.2);
  display: block;
  clear: both;
}
.similarLocationsWrapper {
  position: relative;
}
.similarLocationsInner {
  max-height: 80px;
  padding-bottom: 10px;
  overflow: scroll;
}
.similarLocationsFade {
  position: absolute;
  border-bottom: 5px solid #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
.similarLocationsWrapper .clear {
  clear: both;
}
.similarLocationsNoWorkOrder {
  float: left;
  margin-bottom: 0;
  margin-left: 10px;
  padding: 5px;
  color: rgba(0, 0, 0, 0.8);
}
.similarLocationsWorkOrders {
  float: left;
  margin-bottom: 0;
  margin-left: 10px;
  padding: 5px;
  color: rgba(0, 0, 0, 0.8);
}
.similarLocationsNoWorkOrder .v-icon,
.similarLocationsWorkOrders .v-icon {
  opacity: 0.6;
}
.similarLocationsWorkOrders .v-icon.openInNew {
  font-size: 0.85em;
}
.similarLocationsWorkOrders a {
  color: #000;
  text-decoration: none;
}
.similarLocationsWorkOrders a:hover,
.similarLocationsWorkOrders a:focus {
  opacity: 0.8;
}
.divider {
  margin-bottom: -20px;
}
</style>
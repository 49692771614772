import { fetchClients, fetchClient, createClient, updateClient, deleteClient } from '@/api/clients'
import { Commit } from 'vuex'
import { ClientsState } from '../state'
import { ClientsData } from '../types'

let emptyClientObject = {
  contacts: [],
  locations: [],
  id: null
}

const clientsStore = {
  state: {
    list: [],
    listTotal: 0,
    listLoading: false,
    edit: Object.assign({ }, emptyClientObject),
    create: Object.assign({ }, emptyClientObject)
  },
  getters: {
    'clientsList': (state: ClientsState) => {
      return state.list
    },
    'clientsCreate': (state: ClientsState) => {
      return state.create
    },
    'clientsEdit': (state: ClientsState) => {
      return state.edit
    },
    'clientsListTotalCount': (state: ClientsState) => {
      return state.listTotal
    },
    'clientsListLoading': (state: ClientsState) => {
      return state.listLoading
    }
  },
  mutations: {
    'clients.setListTo': (state: ClientsState, payload: any) => {
      state.list = payload
    },
    'clients.setListTotalCount': (state: ClientsState, payload: any) => {
      state.listTotal = payload
    },
    'clients.setListLoadingTo': (state: ClientsState, payload: any) => {
      state.listLoading = payload
    },
    'clients.setEditTo': (state: ClientsState, payload: any) => {
      state.edit = payload
    },
    'clients.editUpdateInput': (state: ClientsState, payload: any) => {
      (state.edit as any)[payload.name] = payload.value
    },
    'clients.createUpdateInput': (state: ClientsState, payload: any) => {
      (state.create as any)[payload.name] = payload.value
    },
    'clients.edit': (state: ClientsState, payload: any) => {
      state.list = state.list.map((client: any) => {
        return client.id === payload.id ? payload.clientData : client
      })
    },
    'clients.deleteById': (state: ClientsState, payload: any) => {
      state.list = state.list.filter((client: any) => {
        return String(client.id) !== String(payload.id)
      })
    },
    'clients.createClear': (state: ClientsState) => {
      state.create = Object.assign({}, emptyClientObject)
    },
    'clients.editClear': (state: ClientsState) => {
      state.edit = Object.assign({}, emptyClientObject)
    },
    'clients.addToList': (state: ClientsState, payload: any) => {
      state.list.push(payload)
    }
  },
  actions: {
    'clients.fetch': ({ commit }: {commit: Commit}, pagination: any) => {
      commit('clients.setListLoadingTo', true)
      return fetchClients(pagination).then((res) => {
        let clients = res.data.data
        commit('clients.setListTo', clients)
        commit('clients.setListTotalCount', res.data.total)
        commit('clients.setListLoadingTo', false)
      })
    },
    'clients.fetchSingleForEdit': ({ commit }: {commit: Commit}, id: any) => {
      return fetchClient(id).then((res) => {
        let client = res.data
        commit('clients.setEditTo', client)
      })
    },
    'clients.create': ({ commit, state }: { commit: Commit, state: ClientsState}) => {
      return new Promise((resolve, reject) => {
        createClient(state.create).then((res) => {
          let client = Object.assign(state.create, { id: res.data.id })
          commit('clients.addToList', client)
          commit('clients.createClear')
          resolve(client.id)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'clients.edit': ({ commit, state }: { commit: Commit, state: ClientsState}) => {
      return new Promise(function (resolve, reject) {
        updateClient(state.edit.id, state.edit).then(() => {
          commit('clients.edit', { id: state.edit.id, clientData: state.edit })
          resolve(state.edit)
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    },
    'clients.delete': ({ commit } : { commit: Commit}, id: any) => {
      return new Promise((resolve, reject) => {
        deleteClient(id).then((res) => {
          commit('clients.deleteById', { id: id })
          resolve('clients.deleteById')
        }).catch((err) => {
          reject(err.response.data)
        })
      })
    }
  }
}

export default clientsStore

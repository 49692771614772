import router from '@/router'
import { SearchState } from '../state'
import { Commit } from 'vuex'
import { fetchSearchResults } from '@/api/search'
import i18n from '@/i18n'

const t = (string: string, plural: number = null as any) => {
    let finalString = string
    if(plural) {
      return i18n.global.t(finalString, Number(plural))
    }
    return i18n.global.t(finalString)
}

const search = {
  state: {
    results: [],
    resultsTotal: 0,
    resultsLoading: false,
    type: '',
    headers: [],
  },
  getters: {
    'searchResults': (state: SearchState) => {
      return state.results
    },
    'searchResultsTotal': (state: SearchState) => {
      return state.resultsTotal
    },
    'searchLoading': (state: SearchState) => {
      return state.resultsLoading
    },
    'searchType': (state: SearchState) => {
      return state.type
    },
    'searchHeaders': (state: SearchState) => {
      return state.headers
    },
  },
  mutations: {
    'search.setSearchTo': (state: SearchState, results: Array<any>) => {
      state.results = results
    },
    'search.setSearchTotal': (state: SearchState, tot: number) => {
      state.resultsTotal = tot
    },
    'search.setSearchLoadingTo': (state: SearchState, payload: any) => {
      state.resultsLoading = payload
    },
    'search.setType': (state: SearchState, type: string) => {
      state.type = type
    },
    'search.setHeaders': (state: SearchState, headers: Array<any>) => {
      state.headers = headers
    },
  },
  actions: {
    'search.fetch': ({ commit }: {commit: Commit}, pagination: any) => {
      commit('search.setSearchLoadingTo', true)
      return fetchSearchResults(pagination).then((res) => {
        let search = res.data

        if(Object.keys(search)[0] === 'client') {
          commit('search.setSearchTo', search.client)
          commit('search.setSearchTotal', res.data.total)
          commit('search.setSearchLoadingTo', false)
          commit('search.setType', 'client')
          commit('search.setHeaders', [
            {width: 100, title: t('name'), key: 'name', sortable: true},
            {width: 100, title: t('postcode'), key: 'postcode', sortable: false},
            {width: 100, title: t('city'), key: 'city', sortable: false},
            {width: 100, title: t('address'), key: 'address', sortable: false},
            {width: 100, title: '', key: 'actions', sortable: false},
          ])
        } else if (Object.keys(search)[0] === 'job') {
          commit('search.setSearchTo', search.job)
          commit('search.setSearchTotal', res.data.total)
          commit('search.setSearchLoadingTo', false)
          commit('search.setType', 'job')
          commit('search.setHeaders', [
            {width: 100, title: t('job', 2), key: 'job_name', sortable: true},
            {width: 80, title: t('name'), key: 'project_name', sortable: false},
            {width: 200, title: t('instructions'), key: 'instructions', sortable: false},
            {width: 100, title: t('location'), key: 'location', sortable: false},
            {width: 100, title: t('client'), key: 'name', sortable: false},
            {width: 100, title: t('status'), key: 'status', sortable: true}
          ])
        } else if (Object.keys(search)[0] === 'workOrder') {
          commit('search.setSearchTo', search.workOrder)
          commit('search.setSearchTotal', res.data.total)
          commit('search.setSearchLoadingTo', false)
          commit('search.setType', 'workOrder')
          commit('search.setHeaders', [
            {width: 100, title: t('workOrder', 2), key: 'standard_name_prefix', sortable: true},
            {width: 80, title: t('name'), key: 'project_name', sortable: false},
            {width: 200, title: t('instructions'), key: 'instructions', sortable: false},
            {width: 100, title: t('location'), key: 'location', sortable: false},
            {width: 100, title: t('client'), key: 'name', sortable: false},
            {width: 100, title: t('status'), key: 'status', sortable: true}
          ])
        } else if (Object.keys(search)[0] === 'location') {
          commit('search.setSearchTo', search.location)
          commit('search.setSearchTotal', res.data.total)
          commit('search.setSearchLoadingTo', false)
          commit('search.setType', 'location')
          commit('search.setHeaders', [
            {width: 100, title: t('city'), key: 'city', sortable: true},
            {width: 100, title: t('postcode'), key: 'postcode', sortable: false},
            {width: 100, title: t('street'), key: 'street', sortable: false},
            {width: 100, title: t('client'), key: 'name', sortable: false},
            {width: 100, title: '', key: 'actions', sortable: false},
          ])
        }
      })
    },
  }
}

export default search

<template>
  <v-form v-on:submit.prevent="submit()" v-model="valid" ref="form">
    <v-card flat>
      <v-card-text>
        <v-text-field
          variant="underlined"
          :label="$t('name')"
          v-model="user.name"
          :rules="rules.name"
          @keyup="onInput('name', $event.target.value)"
          v-if="!hideName"
        ></v-text-field>
        <v-text-field
          variant="underlined"
          :label="$t('email')"
          v-model="user.email"
          :rules="rules.email"
          @keyup="onInput('email', $event.target.value)"
        ></v-text-field>
        <v-text-field
          variant="underlined"
          :label="$t('username')"
          v-model="user.username"
          :rules="rules.username"
          @keyup="onInput('username', $event.target.value)"
        ></v-text-field>
        <v-text-field
          variant="underlined"
          :label="$t('password')"
          v-model="user.password"
          :rules="rules.password"
          :type="!passwordVisible ? 'password' : 'text'"
          min="8"
          :hint="$t('passwordHint')"
          persistent-hint
          @keyup="onInput('password', $event.target.value)"
        >
          <template v-slot:append >
            <v-icon @click="passwordVisible = !passwordVisible">{{!passwordVisible ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
          </template>
        </v-text-field>
        <v-text-field
          variant="underlined"
          label="Pincode"
          v-model="user.pincode"
          :rules="rules.pincode"
          :type="!pincodeVisible ? 'password' : 'text'"
          :hint="$t('pincodeHint')"
          persistent-hint
          @keyup="onInput('pincode', $event.target.value)"
        >
          <template v-slot:append >
            <v-icon @click="pincodeVisible = !pincodeVisible">{{!pincodeVisible ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
          </template>
        </v-text-field>

        <v-combobox
          v-model="user.branches"
          multiple
          label="Vestiging(en)"
          variant="underlined"
          :item-props="item => item.id === user.default_branch_id ? { 'disabled': true } : {}"
          :items="branchStore.getters.branches"
          item-title="city"
          item-value="id"
          :return-object="false"
        >
        </v-combobox>

        <v-combobox
          v-model="user.default_branch_id"
          label="Standaard vestiging"
          variant="underlined"
          :items="branchStore.getters.branches"
          item-title="city"
          item-value="id"
          :return-object="false"
          :rules="rules.required"
        ></v-combobox>
        <v-divider/>
        <v-checkbox
          :label="`${role.name}`"
          v-model="user.roles"
          :value="role.id"
          hide-details
          :key="role.id"
          v-for="role in store.getters.rolesList"
          @change="onInput('roles', user.roles)"
        />
      </v-card-text>
      <v-card-actions v-if="!hideSubmit">
        <v-btn color="orange-darken-2" depressed type="submit" v-show="!loading" :disabled="!valid">{{ buttonText }}</v-btn>
        <v-progress-circular indeterminate color="orange-darken-1" v-show="loading"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script setup lang="ts">
import store from '@/store';
import { default as defaultRules } from '@/validations/users'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { branchStore } from '@/store/branches'
import { Branch } from '@/store/types';
import useBranchSelector from '@/composable/useBranchSelector';
import { useRoute } from 'vue-router';

const route = useRoute()
const { branchSelected } = useBranchSelector()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  user: {
    type: Object,
    required: true
  },
  submit: {
    type: Function,
    required: false,
    default: () => { }
  },
  onInput: {
    type: Function,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    required: false,
    default: 'Save'
  },
  customRules: {
    type: Object,
    default: () => { }
  },
  hideName: {
    type: Boolean,
    default: false
  },
  hideSubmit: {
    type: Boolean,
    default: false
  }
})

const valid = ref(false)
const passwordVisible = ref(false)
const pincodeVisible = ref(false)

const rules = computed(() => {
  return Object.assign(defaultRules, props.customRules ? props.customRules : {})
})

onBeforeMount(async() => {
  if (store.getters.rolesList.length === 0) {
    await store.dispatch('roles.fetch')
  }
  await branchStore.dispatch('fetchBranches')

  if (!route.params.id) {
    // Add inital value of default branch id
    props.user.default_branch_id = branchSelected.value
  }
})

watch(() => props.user.default_branch_id, (value) => {
  if (value) {
    if(!props.user.branches.includes(props.user.default_branch_id)){
      props.user.branches.push(props.user.default_branch_id)
    }
  }
}, {immediate: true, deep: true})

</script>

<style scoped>
hr {
  margin: 30px 0;
}
</style>

import { useI18n } from "vue-i18n"
import { ref } from "vue"

export const datatableOptions = (includeAll = true) => {
  const { t } = useI18n()

  let listPerPageItems = [{title: '10', value: 10}, {title: '25', value: 25}, {title: '50', value: 50}, {title: '100', value: 100}]
  if (includeAll) listPerPageItems = [...listPerPageItems, { title: t('all'), value: -1}]

  const [rowsPerPageItems, rowsPerPageText, pagination] = [
    ref(listPerPageItems),
    ref(t('rowsPerPage')),
    ref({
      rowsPerPage: 25,
      descending: null as any,
      page: 1,
      sortBy: null as any,
    })
  ];

  return {
    rowsPerPageItems,
    rowsPerPageText,
    pagination,
  }
}

export default datatableOptions
